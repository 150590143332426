<template>
    
    <div>
        
    <div class="grid grid-cols-6 w-full max-w-7xl px-4 mx-auto py-4">
        <div class="col-span-3">
            <img src="@/assets/images/homepage/lokaler-haendler.png" class="top-0 md:top-16 relative">
        </div>
        <div class="col-span-6 md:col-span-3 p-8 md:p-0 flex flex-col justify-center">
            <h3 class="mb-2 md:mb-3 text-1xl leading-6 font-medium text-gray-400">OHNE EIGENES E-COMMERCE</h3>
            <h2 class="mb-2 md:mb-3 text-3xl leading-8 font-bold text-gray-900">Offline-Kunden online gewinnen.</h2>
            
        </div>
       
    </div>
    
    
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="py-36 bg-white">
      <div class="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <dl class="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <dt>
              <div class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-400 text-white">
                <!-- Heroicon name: outline/globe-alt -->
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
              </div>
              <p class="mt-5 text-lg leading-6 font-medium text-gray-900">Schnell & sofort</p>
            </dt>
            <dd class="mt-2 text-base text-gray-500">
              In nur 5 Minuten ist die Einrichtung abgeschlossen. Neukunden können direkt gewonnen werden.
            </dd>
          </div>
    
          <div>
            <dt>
              <div class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-400 text-white">
                <!-- Heroicon name: outline/scale -->
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                 </svg>
              </div>
              <p class="mt-5 text-lg leading-6 font-medium text-gray-900">Spielend einfach</p>
            </dt>
            <dd class="mt-2 text-base text-gray-500">
              Es sind keine IT- oder Online-Marketing-Kenntnisse notwendig, um sofort loszulegen.
            </dd>
          </div>
    
          <div>
            <dt>
              <div class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-400 text-white">
                <!-- Heroicon name: outline/lightning-bolt -->
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5" />
                </svg>
              </div>
              <p class="mt-5 text-lg leading-6 font-medium text-gray-900">Fair & transparent</p>
            </dt>
            <dd class="mt-2 text-base text-gray-500">
              Kosten entstehen nur bei Werbekontakt. In Echtzeit werden Ausgaben dargestellt.
            </dd>
          </div>
        </dl>
      </div>
    </div>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    <div class="bg-white overflow-hidden">
          <div class="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <div class="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
            <div class="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
              <div>
                <h2 class="text-base text-indigo-600 font-semibold tracking-wide uppercase">Ein unaufhaltsamer Trend</h2>
              </div>
            </div>
            <div class="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
              <div class="relative lg:row-start-1 lg:col-start-2">
                <svg class="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
                  <defs>
                    <pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                      <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
                </svg>
                <div class="relative text-base mx-auto max-w-prose lg:max-w-none">
                  <figure>
                    <div class="aspect-w-12 aspect-h-7 lg:aspect-none">
                      <svg id="undraw_Investing_re_bov7_1_" data-name="undraw_Investing_re_bov7 (1)" xmlns="http://www.w3.org/2000/svg" width="468.241" height="343.718" viewBox="0 0 468.241 343.718">
                            <path id="Pfad_130" data-name="Pfad 130" d="M951.371,672.407l.533-.986c-7.15-3.867-16.048-8.679-20.7-16.576-4.582-7.785-4.684-18.356-.268-27.589C934.7,619.4,941.6,612.359,950.9,606.9c1.835-1.077,3.761-2.1,5.624-3.085,4.5-2.386,9.159-4.854,13-8.355,5.287-4.822,9.288-12.914,6.307-20.121a13.375,13.375,0,0,0-11.592-8.111l-.063,1.12a12.255,12.255,0,0,1,10.623,7.427c2.771,6.7-1.031,14.3-6.029,18.857-3.734,3.405-8.326,5.839-12.767,8.192-1.873.993-3.811,2.02-5.667,3.109-9.5,5.574-16.553,12.781-20.408,20.841-4.575,9.565-4.455,20.54.313,28.642C935.048,663.58,944.1,668.475,951.371,672.407Z" transform="translate(-508.795 -328.689)" fill="#f1f1f1"/>
                            <ellipse id="Ellipse_41" data-name="Ellipse 41" cx="9.811" cy="4.765" rx="9.811" ry="4.765" transform="translate(438.528 234.915)" fill="#f1f1f1"/>
                            <ellipse id="Ellipse_42" data-name="Ellipse 42" cx="9.811" cy="4.765" rx="9.811" ry="4.765" transform="translate(430.679 266.31)" fill="#f1f1f1"/>
                            <ellipse id="Ellipse_43" data-name="Ellipse 43" cx="9.811" cy="4.765" rx="9.811" ry="4.765" transform="translate(448.619 271.356)" fill="#f1f1f1"/>
                            <ellipse id="Ellipse_44" data-name="Ellipse 44" cx="9.811" cy="4.765" rx="9.811" ry="4.765" transform="translate(420.588 297.705)" fill="#f1f1f1"/>
                            <ellipse id="Ellipse_45" data-name="Ellipse 45" cx="9.811" cy="4.765" rx="9.811" ry="4.765" transform="translate(400.966 297.705)" fill="#f1f1f1"/>
                            <path id="Pfad_131" data-name="Pfad 131" d="M288.583,393.394H253.771c-1.963,0-3.553-.385-3.556-.86V364.8c0-.474,1.593-.859,3.556-.859h34.812c1.963,0,3.553.385,3.556.859v27.73C292.136,393.009,290.546,393.393,288.583,393.394Z" transform="translate(-211.617 -147.008)" fill="#fb923c"/>
                            <path id="Pfad_132" data-name="Pfad 132" d="M401.82,339.718H367.009c-1.963,0-3.553-.542-3.556-1.21V283.965c0-.668,1.593-1.21,3.556-1.21H401.82c1.963,0,3.553.542,3.556,1.21v54.542C405.374,339.175,403.783,339.717,401.82,339.718Z" transform="translate(-261.371 -93.332)" fill="#fb923c"/>
                            <path id="Pfad_133" data-name="Pfad 133" d="M515.058,451.782H480.246c-1.963,0-3.553-1.148-3.556-2.564v-82.71c0-1.415,1.593-2.562,3.556-2.564h34.812c1.963,0,3.553,1.148,3.556,2.564v82.71C518.611,450.634,517.021,451.78,515.058,451.782Z" transform="translate(-311.125 -205.397)" fill="#fb923c"/>
                            <path id="Pfad_134" data-name="Pfad 134" d="M628.3,380.644H593.484c-1.918.029-3.508-1.035-3.556-2.38V253.086c.048-1.345,1.638-2.409,3.556-2.38H628.3c1.918-.029,3.508,1.035,3.556,2.38V378.263C631.8,379.608,630.214,380.673,628.3,380.644Z" transform="translate(-360.878 -134.258)" fill="#fb923c"/>
                            <path id="Pfad_135" data-name="Pfad 135" d="M741.533,410.658H706.721a3.559,3.559,0,0,1-3.556-3.555V196.575a3.559,3.559,0,0,1,3.556-3.555h34.812a3.56,3.56,0,0,1,3.556,3.555V407.1A3.56,3.56,0,0,1,741.533,410.658Z" transform="translate(-410.632 -164.272)" fill="#fb923c"/>
                            <circle id="Ellipse_46" data-name="Ellipse 46" cx="7.187" cy="7.187" r="7.187" transform="translate(52.373 95.825)" fill="#3f3d56"/>
                            <circle id="Ellipse_47" data-name="Ellipse 47" cx="7.187" cy="7.187" r="7.187" transform="translate(115.857 49.11)" fill="#3f3d56"/>
                            <circle id="Ellipse_48" data-name="Ellipse 48" cx="7.187" cy="7.187" r="7.187" transform="translate(179.341 95.825)" fill="#3f3d56"/>
                            <circle id="Ellipse_49" data-name="Ellipse 49" cx="7.187" cy="7.187" r="7.187" transform="translate(242.824 26.352)" fill="#3f3d56"/>
                            <circle id="Ellipse_50" data-name="Ellipse 50" cx="7.187" cy="7.187" r="7.187" transform="translate(306.308 0)" fill="#3f3d56"/>
                            <path id="Pfad_136" data-name="Pfad 136" d="M553.06,582.728H181.927a.561.561,0,0,1,0-1.121H553.06a.561.561,0,0,1,0,1.121Z" transform="translate(-181.366 -335.008)" fill="#cbcbcb"/>
                            <path id="Pfad_137" data-name="Pfad 137" d="M866.569,753.728H684.927a.561.561,0,1,1,0-1.122H866.569a.561.561,0,0,1,0,1.122Z" transform="translate(-402.372 -410.141)" fill="#cbcbcb"/>
                            <path id="Pfad_138" data-name="Pfad 138" d="M795.313,552.055a4.446,4.446,0,0,0-.58-6.792l2.2-9.917-5.7-2.809-2.916,14.049a4.47,4.47,0,0,0,6.992,5.47Z" transform="translate(-447.762 -313.448)" fill="#ffb7b7"/>
                            <path id="Pfad_139" data-name="Pfad 139" d="M906.313,552.736a4.446,4.446,0,0,0-1.1-6.727l1.43-10.058-5.9-2.36-1.82,14.232a4.47,4.47,0,0,0,7.394,4.913Z" transform="translate(-496.43 -313.911)" fill="#ffb7b7"/>
                            <path id="Pfad_140" data-name="Pfad 140" d="M645.14,584.507h-5.813l-2.765-22.419h8.579Z" transform="translate(-279.69 -246.967)" fill="#ffb7b7"/>
                            <path id="Pfad_141" data-name="Pfad 141" d="M821.184,747.967H802.442v-.237a7.3,7.3,0,0,1,7.294-7.295h11.448Z" transform="translate(-454.252 -404.793)" fill="#2f2e41"/>
                            <path id="Pfad_142" data-name="Pfad 142" d="M679.813,584.507H674l-2.765-22.419h8.579Z" transform="translate(-294.924 -246.967)" fill="#ffb7b7"/>
                            <path id="Pfad_143" data-name="Pfad 143" d="M855.857,747.967H837.115v-.237a7.3,7.3,0,0,1,7.294-7.295h11.448Z" transform="translate(-469.486 -404.793)" fill="#2f2e41"/>
                            <path id="Pfad_144" data-name="Pfad 144" d="M886.143,405.6l6.267-2.262s2.661.565,2.661,7.779,7.065,74.5,7.065,74.5l-12.364,3.255-6.989-63.834Z" transform="translate(-489.552 -256.681)" fill="#2f2e41"/>
                            <path id="Pfad_145" data-name="Pfad 145" d="M801.536,405.6l-6.268-2.262s-2.66.565-2.66,7.779-7.065,74.5-7.065,74.5l12.364,3.255,6.989-63.834Z" transform="translate(-446.827 -256.681)" fill="#2f2e41"/>
                            <path id="Pfad_146" data-name="Pfad 146" d="M812.871,538.225l-1.713,50.541,3.793,55.908,9.773.226,1.422-52.152,8.06-26.076-.813,76.857,11.066-.132,6.854-52.877s9.051-43.761,4.032-52.295l-23.116-7.687Z" transform="translate(-458.081 -312.57)" fill="#2f2e41"/>
                            <path id="Pfad_147" data-name="Pfad 147" d="M814.113,531.325l-.558,12.158s7.8,3.881,21.508,1.517,22.821-.806,22.821-.806l-1.053-15.5Z" transform="translate(-459.134 -311.76)" fill="#e5e5e5"/>
                            <path id="Pfad_148" data-name="Pfad 148" d="M802.446,394.225l18.026-7.9,1.075-4.137,17.536.508.758,4.226,17.271,7.122-7.609,26.46.78,18.614,9.489,33.925s-8,6.8-27.233,3.873l-3.318-6.686-2.03,7.07s-16.862,6.2-21.535-1.872l3.387-58.331Z" transform="translate(-454.254 -247.389)" fill="#2f2e41"/>
                            <circle id="Ellipse_51" data-name="Ellipse 51" cx="11.645" cy="11.645" r="11.645" transform="translate(364.258 107.625)" fill="#ffb7b7"/>
                            <path id="Pfad_149" data-name="Pfad 149" d="M855.156,335.967a7.086,7.086,0,0,0-1.177-2.629,14.2,14.2,0,0,0-2.121-6.118,6.422,6.422,0,0,0-1.9-2.193,5.609,5.609,0,0,0-2.989-.745c-.19,0-.531.054-.838.068-.2-.061-.4-.115-.594-.166-.116-.258-.346-1.011-.41-.96l-.867.689c-.367-.064-.735-.113-1.105-.153l-.4-1.153a7.927,7.927,0,0,0-.573,1.077q-.553-.027-1.106-.019l-.737-.708a1.04,1.04,0,0,1-.813.8c-.339.035-.677.08-1.014.134h-.082c-1.816-.045-3.769-.127-5.287.87a5.215,5.215,0,0,0-1.683,1.91c-.186.126-.372.252-.553.386a6.047,6.047,0,0,0-1.557,1.544,6.552,6.552,0,0,0-.689,1.67,16.006,16.006,0,0,0-.793,5.468,8.118,8.118,0,0,0,1.995,5.065c.779.837,1.769,1.454,2.549,2.29,1.605,1.721,1.906,4.12,3.279,5.941a10.388,10.388,0,0,0,6.432,3.556,10.744,10.744,0,0,0,8-2,4.951,4.951,0,0,0,1.363-1.429c.808-1.4.465-3.154.766-4.741C852.809,341.448,855.647,338.953,855.156,335.967Z" transform="translate(-465.417 -221.21)" fill="#2f2e41"/>
                            <path id="Pfad_150" data-name="Pfad 150" d="M105.03,108.422l-.669-.9L168.9,59.476l63.278,47.2L296.32,37.032l.115-.047,62.252-25.693.428,1.037L296.978,37.974l-64.662,70.2L168.9,60.874Z" transform="translate(-45.854 -4.961)" fill="#3f3d56"/>
                          </svg>
                          
                    </div>
                  </figure>
                </div>
              </div>
              <div class="mt-8 lg:mt-0">
                <div class="text-base max-w-prose mx-auto lg:max-w-none">
                  <p class="text-lg text-gray-500">
                     Der Produktsuche im Internet wird immer mehr Bedeutung zuteil, sodass die <strong>Neukundengewinnung</strong> abseits der Online-Medien immer schwieriger wird.</p>
                </div>
                
              </div>
            </div>
          </div>
          
          
          
          
          
          
          <div class="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
              <div class="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
              <div class="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
                <div>
                  <h2 class="text-base text-indigo-600 font-semibold tracking-wide uppercase">Kunden informieren sich online</h2>
                </div>
              </div>
              <div class="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
                <div class="relative lg:row-start-1 lg:col-start-2">
                  <svg class="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
                    <defs>
                      <pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                      </pattern>
                    </defs>
                    <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
                  </svg>
                  <div class="relative text-base mx-auto max-w-prose lg:max-w-none">
                    <figure>
                      <div class="aspect-w-12 aspect-h-7 lg:aspect-none">
                        <svg xmlns="http://www.w3.org/2000/svg" width="386.232" height="390.945" viewBox="0 0 386.232 390.945">
                              <g id="undraw_browsing_urt9" transform="translate(0)">
                                <ellipse id="Ellipse_52" data-name="Ellipse 52" cx="50.542" cy="8.21" rx="50.542" ry="8.21" transform="translate(33.623 374.524)" fill="#e6e6e6"/>
                                <path id="Pfad_151" data-name="Pfad 151" d="M537.42,346.088l-203.4-42.375V106.154L537.42,0Z" transform="translate(-151.189)" fill="#e6e6e6"/>
                                <path id="Pfad_152" data-name="Pfad 152" d="M536.518,21.3l-203.4,97.352V107.2L536.518,1.227Z" transform="translate(-150.78 -0.555)" fill="#fb923c"/>
                                <path id="Pfad_153" data-name="Pfad 153" d="M594.2,287.243c0,2.635-.887,5.2-1.975,5.721s-1.947-1.169-1.947-3.767.868-5.151,1.947-5.707S594.2,284.609,594.2,287.243Z" transform="translate(-402.485 -179.111)" fill="#e6e6e6"/>
                                <path id="Pfad_154" data-name="Pfad 154" d="M608.633,280.141c0,2.562-.887,5.063-1.975,5.584s-1.948-1.116-1.948-3.643.869-5.019,1.948-5.57S608.633,277.58,608.633,280.141Z" transform="translate(-409.017 -175.952)" fill="#e6e6e6"/>
                                <path id="Pfad_155" data-name="Pfad 155" d="M623.064,272.926c0,2.562-.887,5.063-1.975,5.584s-1.948-1.116-1.948-3.643.869-5.019,1.948-5.57S623.064,270.364,623.064,272.926Z" transform="translate(-415.548 -172.686)" fill="#e6e6e6"/>
                                <path id="Pfad_156" data-name="Pfad 156" d="M505.452,238.268l-125.4,28.688V230.369l125.4-44.332Z" transform="translate(-172.026 -84.207)" opacity="0.2"/>
                                <path id="Pfad_157" data-name="Pfad 157" d="M500.906,233.722l-125.4,28.688V225.823l125.4-44.332Z" transform="translate(-169.968 -82.149)" fill="#fff"/>
                                <path id="Pfad_158" data-name="Pfad 158" d="M657.074,373.134c0,5.894-2.23,11.243-4.937,11.936-2.661.681-4.785-3.406-4.785-9.119s2.124-11.031,4.785-11.892C654.844,363.183,657.074,367.24,657.074,373.134Z" transform="translate(-428.318 -215.574)" opacity="0.2"/>
                                <path id="Pfad_159" data-name="Pfad 159" d="M654.346,370.407c0,5.894-2.23,11.243-4.937,11.936-2.661.681-4.785-3.406-4.785-9.119s2.124-11.031,4.785-11.892C652.117,360.456,654.346,364.512,654.346,370.407Z" transform="translate(-427.083 -214.34)" fill="#fb923c"/>
                                <path id="Pfad_160" data-name="Pfad 160" d="M499.777,240.907,439.547,258.3v-6.156l60.23-18.521Z" transform="translate(-198.954 -105.745)" fill="#e6e6e6"/>
                                <path id="Pfad_161" data-name="Pfad 161" d="M494.935,273.288l-35.546,8.972V275.9l35.546-9.639Z" transform="translate(-207.935 -120.52)" fill="#e6e6e6"/>
                                <path id="Pfad_162" data-name="Pfad 162" d="M505.459,433.76l-125.4-10.511V349.833l125.4-20.935Z" transform="translate(-172.029 -148.87)" opacity="0.2"/>
                                <path id="Pfad_163" data-name="Pfad 163" d="M500,429.214,374.607,418.7V345.287L500,324.352Z" transform="translate(-169.56 -146.813)" fill="#fff"/>
                                <path id="Pfad_164" data-name="Pfad 164" d="M407.65,395.087l-5.869.547v-6.415l5.869-.67Z" transform="translate(-181.86 -175.87)" opacity="0.2"/>
                                <path id="Pfad_165" data-name="Pfad 165" d="M405.832,393.269l-5.869.547V387.4l5.869-.669Z" transform="translate(-181.037 -175.047)" fill="#fb923c"/>
                                <path id="Pfad_166" data-name="Pfad 166" d="M491.612,379.432l-70.1,6.561v-5.971l70.1-7.876Z" transform="translate(-190.789 -168.446)" fill="#e6e6e6"/>
                                <path id="Pfad_167" data-name="Pfad 167" d="M491.612,422.89l-70.1,2.269v-5.97l70.1-3.584Z" transform="translate(-190.789 -188.117)" fill="#e6e6e6"/>
                                <path id="Pfad_168" data-name="Pfad 168" d="M491.612,466.772l-70.1-2.131V458.67l70.1.817Z" transform="translate(-190.789 -207.61)" fill="#e6e6e6"/>
                                <path id="Pfad_169" data-name="Pfad 169" d="M132.269,323.815H118.852v-6.571h11.226l2.464,1.369Z" transform="translate(-53.796 -143.595)" fill="#575a89"/>
                                <path id="Pfad_170" data-name="Pfad 170" d="M100.843,658.544l.548,9.858,8.214-1.643-1.369-9.857Z" transform="translate(-45.645 -297.336)" fill="#ffb9b9"/>
                                <path id="Pfad_171" data-name="Pfad 171" d="M418.5,741.531s.274,8.214-.548,10.4,2.19,3.286,2.19,3.286h6.571l3.833.548v-4.107s-4.381-6.845-4.381-10.679S418.5,741.531,418.5,741.531Z" transform="translate(-324.418 -385.525)" fill="#ffb9b9"/>
                                <path id="Pfad_172" data-name="Pfad 172" d="M347.818,766.535s5.476-2.587,7.393-1.02,9.036,14.984,6.3,15.805c0,0-1.917,3.286-8.488,1.1s-6.571-4.655-6.571-4.655-1.917-1.643-1.917-2.19.821-5.476.821-5.476.608-4.124,1.536-3.842Z" transform="translate(-291.251 -397.112)" fill="#2f2e41"/>
                                <path id="Pfad_173" data-name="Pfad 173" d="M417.319,759.99s-.821-.822-1.369,0-3.56,11.226-.821,11.774,6.845,2.464,7.393,1.917a7.5,7.5,0,0,0,1.1-1.917s4.381,1.917,5.75,3.56,17.8,5.2,17.25,0-8.762-9.583-8.762-9.583l-9.036-6.024s-3.559.274-4.381,1.917S417.866,763,417.319,759.99Z" transform="translate(-322.692 -394.674)" fill="#2f2e41"/>
                                <path id="Pfad_174" data-name="Pfad 174" d="M271.407,522.549s-3.012,3.833-2.464,4.107,12.6,3.012,19.167,1.369,20.262-1.369,21.083-3.286S320.145,499,320.145,499l-3.286-18.071-3.56-4.655-8.214-7.119-16.428,30.667Z" transform="translate(-257.006 -263.198)" fill="#575a89"/>
                                <path id="Pfad_175" data-name="Pfad 175" d="M271.407,522.549s-3.012,3.833-2.464,4.107,12.6,3.012,19.167,1.369,20.262-1.369,21.083-3.286S320.145,499,320.145,499l-3.286-18.071-3.56-4.655-8.214-7.119-16.428,30.667Z" transform="translate(-257.006 -263.198)" opacity="0.1"/>
                                <path id="Pfad_176" data-name="Pfad 176" d="M335.62,549.941s-3.833,13.691,0,32.583.274,23,1.1,23.822,1.1.547,1.1,1.369.821,4.655,0,6.024-.821,44.9,1.643,46,9.583,3.833,10.952,0,.274-31.488.821-32.857.548-4.655.548-5.476,1.643-10.131,1.917-12.6,1.1-7.393,1.1-7.393-.548-19.441,1.1-25.191,2.19-6.3,2.19-6.3l13.143,34.226s-1.1,3.559,0,4.655-.274.822.821,2.738,2.19,2.464,2.19,3.56-1.1,1.1-.548,1.917a22.4,22.4,0,0,1,1.369,3.833l-1.369,3.286A102.141,102.141,0,0,0,375.6,635.1c1.1,4.107,1.1,20.81,2.464,21.083s12.048,3.286,12.048-3.012-.821-33.679-.821-33.679-1.643-4.107-1.643-5.2-.274-1.643.274-2.191a1.732,1.732,0,0,0,.274-2.464c-.821-.822-.548-1.917-.548-3.012a23.964,23.964,0,0,0-.548-4.381c-.274-1.1-5.476-35.322-5.476-35.322s1.1-4.107,0-4.381-1.1-2.464-1.1-3.56,2.738-14.786-.548-14.786S335.62,549.941,335.62,549.941Z" transform="translate(-286.446 -297.162)" fill="#2f2e41"/>
                                <path id="Pfad_177" data-name="Pfad 177" d="M260.222,524.682l-3.833,1.917s-13.69,7.393-7.667,11.5,12.048-5.75,12.048-5.75l5.75-4.381Z" transform="translate(-247.191 -288.331)" fill="#ffb9b9"/>
                                <path id="Pfad_178" data-name="Pfad 178" d="M360.263,386.806s-1.1,12.6-6.845,15.333-1.917,23.274-1.917,23.274l7.94,7.667,13.964-4.107,1.643-9.31,1.369-9.583-3.286-4.929s-4.107-1.1-2.464-12.048S360.263,386.806,360.263,386.806Z" transform="translate(-293.839 -225.479)" fill="#ffb9b9"/>
                                <path id="Pfad_179" data-name="Pfad 179" d="M343.2,421.371s6.3,20.81,10.679,20.81,6.845-4.929,7.393-10.952,4.107-12.6,4.107-12.6l8.488,7.667s6.845,60.512,6.3,62.155-21.9,3.56-30.393,6.024-16.976.548-16.976-.821c0-1.219,4.344-11.778,1.038-19.489a32.118,32.118,0,0,1-2.425-8.623c-.512-4.059-.987-8.972-.53-10.5.821-2.738,5.2-31.762,5.2-31.762Z" transform="translate(-284.985 -240.33)" fill="#fb923c"/>
                                <path id="Pfad_180" data-name="Pfad 180" d="M319.982,409.81s-4.91,2.626-7.1,3.722-15.607,4.381-15.881,6.024,1.917,24.643.821,27.107-12.048,24.369-12.048,24.369l-.548,4.655s-18.072,21.631-15.881,24.1,10.4-7.667,10.4-7.667,18.345-29.3,18.893-30.393,11.774-21.357,13.417-21.9,5.476-23.548,6.024-24.643S320.784,409.374,319.982,409.81Z" transform="translate(-257.135 -236.323)" fill="#575a89"/>
                                <path id="Pfad_181" data-name="Pfad 181" d="M297.611,427.137h-1.643s-6.024,23-7.667,26.012-1.917,7.94-2.19,8.762-1.369.548-1.643,2.464-3.012,4.381-5.2,5.476-12.048,10.4-11.774,10.679,3.286,9.857,5.2,9.857,9.583-9.31,10.4-9.31,4.381-3.286,4.929-3.559,14.512-26.012,13.964-32.31S297.611,427.137,297.611,427.137Z" transform="translate(-256.377 -244.179)" fill="#575a89"/>
                                <path id="Pfad_182" data-name="Pfad 182" d="M441.691,556.734l3.373,2.645s11.936,9.981,5.212,12.8-10.654-8.043-10.654-8.043l-4.758-5.442Z" transform="translate(-332.138 -302.839)" fill="#ffb9b9"/>
                                <path id="Pfad_183" data-name="Pfad 183" d="M386.941,410.426a34.443,34.443,0,0,1,5.263,3.123c1.643,1.369,14.238,4.929,14.238,6.024s-1.369,18.071-1.369,18.071l1.917,30.119s2.738,33.952,3.286,34.774,1.369,5.2,0,5.2-8.214-7.667-7.94-11.5,2.19-12.6.274-14.786-10.952-38.881-10.952-41.072-3.833-24.1-3.56-24.643S386.941,410.426,386.941,410.426Z" transform="translate(-310.447 -236.615)" fill="#575a89"/>
                                <path id="Pfad_184" data-name="Pfad 184" d="M414.613,426.637l4.107.274,10.131,41.345s.821,5.2,1.1,5.75,4.381,24.369,2.738,25.191-7.119,3.833-7.393,1.917-3.012-12.6-5.75-15.06-5.476-44.9-5.476-44.9Z" transform="translate(-322.724 -243.953)" fill="#575a89"/>
                                <circle id="Ellipse_53" data-name="Ellipse 53" cx="11.226" cy="11.226" r="11.226" transform="translate(64.234 146.267)" fill="#ffb9b9"/>
                                <path id="Pfad_185" data-name="Pfad 185" d="M361.368,367.85a1.966,1.966,0,0,1,2.314.777,10.957,10.957,0,0,1,15.926-7.4v-1.141a9.481,9.481,0,0,0-9.481-9.481h-2.279a17.812,17.812,0,0,0-17.812,17.812v3.179a15.97,15.97,0,0,0,8.746,14.244l10.251-5.428a10.958,10.958,0,0,1-5.488-8.141,2.247,2.247,0,0,1-2.634-1.411C360.289,369.645,360.493,368.3,361.368,367.85Z" transform="translate(-293.742 -209.536)" fill="#2f2e41"/>
                              </g>
                            </svg>
                            
                            
                      </div>
                    </figure>
                  </div>
                </div>
                <div class="mt-8 lg:mt-0">
                  <div class="text-base max-w-prose mx-auto lg:max-w-none">
                    <p class="text-lg text-gray-500">
                        Hinter einer Produktsuche im Internet steht oftmals nicht unbedingt die Intention, auch online zu bestellen, sondern sich <strong>im Voraus zu informieren</strong>. Trotzdem landen solche Interessenten in Onlineshops, denn diese werben aktiv und optimiert auf Suchmaschinen.</p>
                  </div>
                  
                </div>
              </div>
            </div>
            
            
            
            
            
            
            
            
            
            
            
            
            
            <div class="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                  <div class="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
                  <div class="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
                    <div>
                      <h2 class="text-base text-indigo-600 font-semibold tracking-wide uppercase">Was können Lokale Händler tun?</h2>
                    </div>
                  </div>
                  <div class="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
                    <div class="relative lg:row-start-1 lg:col-start-2">
                      <svg class="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
                        <defs>
                          <pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                          </pattern>
                        </defs>
                        <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
                      </svg>
                      <div class="relative text-base mx-auto max-w-prose lg:max-w-none">
                        <figure>
                          <div class="aspect-w-12 aspect-h-7 lg:aspect-none">
                            <svg xmlns="http://www.w3.org/2000/svg" width="401.108" height="288.347" viewBox="0 0 401.108 288.347">
                                  <g id="undraw_Wishlist_re_m7tv_1_" data-name="undraw_Wishlist_re_m7tv (1)" transform="translate(0)">
                                    <path id="Pfad_248" data-name="Pfad 248" d="M743.2,476.226H502.535a9.373,9.373,0,0,1-9.362-9.362v-269.3a9.373,9.373,0,0,1,9.362-9.362H743.2a9.373,9.373,0,0,1,9.362,9.362v269.3a9.373,9.373,0,0,1-9.362,9.362ZM502.535,189.306a8.27,8.27,0,0,0-8.261,8.261v269.3a8.27,8.27,0,0,0,8.261,8.261H743.2a8.27,8.27,0,0,0,8.261-8.261v-269.3a8.27,8.27,0,0,0-8.261-8.261Z" transform="translate(-351.45 -188.205)" fill="#3f3d56"/>
                                    <rect id="Rechteck_112" data-name="Rechteck 112" width="258.283" height="1.101" transform="translate(142.274 22.904)" fill="#3f3d56"/>
                                    <circle id="Ellipse_73" data-name="Ellipse 73" cx="4.406" cy="4.406" r="4.406" transform="translate(150.535 7.71)" fill="#fb923c"/>
                                    <circle id="Ellipse_74" data-name="Ellipse 74" cx="4.406" cy="4.406" r="4.406" transform="translate(164.303 7.71)" fill="#fb923c"/>
                                    <circle id="Ellipse_75" data-name="Ellipse 75" cx="4.406" cy="4.406" r="4.406" transform="translate(178.07 7.71)" fill="#fb923c"/>
                                    <path id="Pfad_249" data-name="Pfad 249" d="M738.654,291.523H566.833a7.159,7.159,0,1,1,0-14.319H738.654a7.159,7.159,0,1,1,0,14.319ZM566.833,278.306a6.058,6.058,0,1,0,0,12.116H738.654a6.058,6.058,0,1,0,0-12.116Z" transform="translate(-381.327 -228.191)" fill="#3f3d56"/>
                                    <path id="Pfad_250" data-name="Pfad 250" d="M740.134,398.607h-78.2a4.406,4.406,0,1,1,0-8.811h78.2a4.406,4.406,0,1,1,0,8.811Z" transform="translate(-425.292 -278.777)" fill="#ccc"/>
                                    <path id="Pfad_251" data-name="Pfad 251" d="M785.293,425.607H661.933a4.406,4.406,0,1,1,0-8.811H785.293a4.406,4.406,0,1,1,0,8.811Z" transform="translate(-425.292 -290.908)" fill="#ccc"/>
                                    <path id="Pfad_252" data-name="Pfad 252" d="M588.731,413.628H565.051a5.238,5.238,0,0,1-5.232-5.232V393.527a5.238,5.238,0,0,1,5.232-5.232h23.681a5.238,5.238,0,0,1,5.232,5.232V408.4A5.238,5.238,0,0,1,588.731,413.628ZM565.051,389.4a4.135,4.135,0,0,0-4.13,4.13V408.4a4.135,4.135,0,0,0,4.13,4.13h23.681a4.135,4.135,0,0,0,4.13-4.13V393.527a4.135,4.135,0,0,0-4.13-4.13Z" transform="translate(-381.393 -278.103)" fill="#ccc"/>
                                    <path id="Pfad_253" data-name="Pfad 253" d="M740.134,493.607h-78.2a4.406,4.406,0,1,1,0-8.811h78.2a4.406,4.406,0,1,1,0,8.811Z" transform="translate(-425.292 -321.46)" fill="#ccc"/>
                                    <path id="Pfad_254" data-name="Pfad 254" d="M785.293,520.607H661.933a4.406,4.406,0,1,1,0-8.811H785.293a4.406,4.406,0,1,1,0,8.811Z" transform="translate(-425.292 -333.591)" fill="#ccc"/>
                                    <path id="Pfad_255" data-name="Pfad 255" d="M588.731,508.628H565.051a5.238,5.238,0,0,1-5.232-5.232V488.527a5.238,5.238,0,0,1,5.232-5.232h23.681a5.238,5.238,0,0,1,5.232,5.232V503.4A5.238,5.238,0,0,1,588.731,508.628ZM565.051,484.4a4.135,4.135,0,0,0-4.13,4.13V503.4a4.135,4.135,0,0,0,4.13,4.13h23.681a4.135,4.135,0,0,0,4.13-4.13V488.527a4.135,4.135,0,0,0-4.13-4.13Z" transform="translate(-381.393 -320.786)" fill="#ccc"/>
                                    <path id="Pfad_256" data-name="Pfad 256" d="M740.134,588.607h-78.2a4.406,4.406,0,1,1,0-8.811h78.2a4.406,4.406,0,1,1,0,8.811Z" transform="translate(-425.292 -364.142)" fill="#f2f2f2"/>
                                    <path id="Pfad_257" data-name="Pfad 257" d="M785.293,615.607H661.933a4.406,4.406,0,1,1,0-8.811H785.293a4.406,4.406,0,1,1,0,8.811Z" transform="translate(-425.292 -376.273)" fill="#f2f2f2"/>
                                    <path id="Pfad_258" data-name="Pfad 258" d="M588.731,603.628H565.051a5.237,5.237,0,0,1-5.232-5.232V583.527a5.238,5.238,0,0,1,5.232-5.232h23.681a5.238,5.238,0,0,1,5.232,5.232V598.4A5.237,5.237,0,0,1,588.731,603.628Z" transform="translate(-381.393 -363.468)" fill="#f2f2f2"/>
                                    <path id="Pfad_261" data-name="Pfad 261" d="M385.57,484.385H361.889a5.238,5.238,0,0,1-5.232-5.232V464.284a5.238,5.238,0,0,1,5.232-5.232H385.57a5.238,5.238,0,0,1,5.232,5.232v14.869A5.238,5.238,0,0,1,385.57,484.385Zm-23.681-24.231a4.135,4.135,0,0,0-4.13,4.13v14.869a4.135,4.135,0,0,0,4.13,4.13H385.57a4.135,4.135,0,0,0,4.13-4.13V464.284a4.135,4.135,0,0,0-4.13-4.13Z" transform="translate(-290.115 -309.894)" fill="#3f3d56"/>
                                    <path id="Pfad_263" data-name="Pfad 263" d="M122.38,491.078l6.125-1.457L125.8,465.3l-9.04,2.151Z" transform="translate(-52.459 -209.055)" fill="#ffb8b8"/>
                                    <path id="Pfad_264" data-name="Pfad 264" d="M0,0H19.787V7.645H7.645A7.645,7.645,0,0,1,0,0Z" transform="matrix(-0.973, 0.231, -0.231, -0.973, 89.211, 283.445)" fill="#2f2e41"/>
                                    <path id="Pfad_265" data-name="Pfad 265" d="M341.022,631.318l-13.3-45.591,16.623-4.123,9.752,48.284a2.478,2.478,0,0,1-2.355,2.968l-8.265.245H343.4a2.479,2.479,0,0,1-2.378-1.784Z" transform="translate(-277.113 -364.955)" fill="#2f2e41"/>
                                    <path id="Pfad_266" data-name="Pfad 266" d="M58.088,492.572h6.3l2.994-24.284H58.086Z" transform="translate(-26.097 -210.396)" fill="#ffb8b8"/>
                                    <path id="Pfad_267" data-name="Pfad 267" d="M0,0H19.787V7.645H7.645A7.645,7.645,0,0,1,0,0Z" transform="translate(50.429 288.02) rotate(179.995)" fill="#2f2e41"/>
                                    <path id="Pfad_268" data-name="Pfad 268" d="M286.626,642.837a2.431,2.431,0,0,1-.74-1.723l-5.736-45.48a2.482,2.482,0,0,1,2.46-2.491l12.8-.093h.018a2.485,2.485,0,0,1,2.478,2.44l.681,44.8a2.476,2.476,0,0,1-2.421,2.515l-7.772.739h-.036A2.46,2.46,0,0,1,286.626,642.837Z" transform="translate(-255.74 -370.098)" fill="#2f2e41"/>
                                    <circle id="Ellipse_76" data-name="Ellipse 76" cx="13.526" cy="13.526" r="13.526" transform="translate(20.103 95.156)" fill="#ffb8b8"/>
                                    <path id="Pfad_269" data-name="Pfad 269" d="M258.383,514.168c-8.8-6.876-8.707-14.9-5.7-25.613.846-3.018,1.759-6.078,2.726-9.317,4.491-15.048,9.581-32.1,7.649-46.187l.273-.037-.273.037A13.873,13.873,0,0,1,287.8,422.48c2.693,3.349,3.979,15.126,4.36,16.814a147.008,147.008,0,0,1,3.562,19.882c.9,8.819,11.621,35.185,15.039,39.607a10.463,10.463,0,0,1,2.025,8.334,9.806,9.806,0,0,1-4.656,6.683,55.983,55.983,0,0,1-18.012,7.088,44.154,44.154,0,0,1-8.386.807C273,521.694,264.653,519.067,258.383,514.168Z" transform="translate(-242.574 -291.008)" fill="#fb923c"/>
                                    <path id="Pfad_270" data-name="Pfad 270" d="M347.859,494.747a5.164,5.164,0,0,0-7.737-1.687l-10.249-5.849-5.073,5.362,14.59,8.056a5.192,5.192,0,0,0,8.469-5.883Z" transform="translate(-275.801 -322.545)" fill="#ffb8b8"/>
                                    <path id="Pfad_271" data-name="Pfad 271" d="M315.47,473.1l-18.37-5.771a12.645,12.645,0,0,1-8.816-11.47l-.79-17.849a7.474,7.474,0,1,1,14.937-.1l-.505,16.422,19.551,7.973a2.478,2.478,0,0,1,1.089,3.723l-4.327,6.137a2.472,2.472,0,0,1-2.768.936Z" transform="translate(-259.036 -296.932)" fill="#2f2e41"/>
                                    <path id="Pfad_272" data-name="Pfad 272" d="M257.169,384.816a66.406,66.406,0,0,0,.573-11.265c-.006-1.009-.012-2.017-.006-3.023.029-4.678.319-9.635,2.231-14.2,2.081-4.967,5.935-8.554,10.31-9.595,4.932-1.175,10.283,1.422,11.895,5.7a8.265,8.265,0,1,1,5.038,15.4l-.207.018-1.612-4.188c-.009.073-.018.148-.027.222a3.02,3.02,0,0,1-.926,2.285h0a5.719,5.719,0,0,0-1.684,4.938,18.614,18.614,0,0,0,.9,3.239,17.776,17.776,0,0,1,.959,3.568,6.093,6.093,0,0,1-1.141,4.413,3.407,3.407,0,0,1-2.334,1.339l-18.167,1.879-.812-6.068L257,385.844Z" transform="translate(-245.339 -259.303)" fill="#2f2e41"/>
                                    <path id="Pfad_273" data-name="Pfad 273" d="M342.114,710.9H236.378a.551.551,0,1,1,0-1.1H342.114a.551.551,0,1,1,0,1.1Z" transform="translate(-235.827 -422.55)" fill="#ccc"/>
                                    <g id="Gruppe_16" data-name="Gruppe 16" transform="translate(188.408 115.877)">
                                      <path id="Pfad_274" data-name="Pfad 274" d="M417.25,350.607H421.3V348.58h-6.082a6.082,6.082,0,0,0-6.082,6.082v6.082h2.027v-6.082a4.055,4.055,0,0,1,4.055-4.055Z" transform="translate(-409.14 -348.58)" fill="#fb923c"/>
                                      <path id="Pfad_275" data-name="Pfad 275" d="M445.222,378.58H439.14v2.027h6.082a4.055,4.055,0,0,1-4.055,4.055H439.14v2.027h2.027a6.082,6.082,0,0,0,6.082-6.082V378.58Z" transform="translate(-433.058 -372.498)" fill="#fed7aa"/>
                                    </g>
                                    <g id="Gruppe_197" data-name="Gruppe 197" transform="translate(76.408 154.877)">
                                      <path id="Pfad_274-2" data-name="Pfad 274" d="M417.25,350.607H421.3V348.58h-6.082a6.082,6.082,0,0,0-6.082,6.082v6.082h2.027v-6.082a4.055,4.055,0,0,1,4.055-4.055Z" transform="translate(-409.14 -348.58)" fill="#fb923c"/>
                                      <path id="Pfad_275-2" data-name="Pfad 275" d="M445.222,378.58H439.14v2.027h6.082a4.055,4.055,0,0,1-4.055,4.055H439.14v2.027h2.027a6.082,6.082,0,0,0,6.082-6.082V378.58Z" transform="translate(-433.058 -372.498)" fill="#fed7aa"/>
                                    </g>
                                  </g>
                                </svg>
                                
                                
                                
                          </div>
                        </figure>
                      </div>
                    </div>
                    <div class="mt-8 lg:mt-0">
                      <div class="text-base max-w-prose mx-auto lg:max-w-none">
                        <p class="text-lg text-gray-500">
                            Mit Getfrom zurück ins Spiel: Getfrom baut die Werbeanzeige des lokalen Händlers in Onlineshops ein. Diese profitieren wiederum davon, Kunden die eh nie online kaufen wollten <strong>erfolgreich an lokale Händler weiterzuleiten</strong>.</p>
                      </div>
                      
                    </div>
                  </div>
                </div>
                
                
                
                
                
                
                
                
           
           
           
           
           
           
           
           
           
           
           
           
           <div class="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
               <div class="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
               <div class="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
                 <div>
                   <h2 class="text-base text-indigo-600 font-semibold tracking-wide uppercase">Win. Win. Win.
</h2>
                 </div>
               </div>
               <div class="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
                 <div class="relative lg:row-start-1 lg:col-start-2">
                   <svg class="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
                     <defs>
                       <pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                         <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                       </pattern>
                     </defs>
                     <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
                   </svg>
                   <div class="relative text-base mx-auto max-w-prose lg:max-w-none">
                     <figure>
                       <div class="aspect-w-12 aspect-h-7 lg:aspect-none">
                         <svg id="undraw_shopping_app_flsj_1_" data-name="undraw_shopping_app_flsj (1)" xmlns="http://www.w3.org/2000/svg" width="463.3" height="386.631" viewBox="0 0 463.3 386.631">
                               <path id="Pfad_202" data-name="Pfad 202" d="M346.9,170.456h-2.1V112.829a33.353,33.353,0,0,0-33.353-33.353H189.353A33.353,33.353,0,0,0,156,112.829V428.976a33.353,33.353,0,0,0,33.353,33.353H311.444A33.353,33.353,0,0,0,344.8,428.976v-217.5h2.1Z" transform="translate(-156 -79.476)" fill="#e6e6e6"/>
                               <path id="Pfad_203" data-name="Pfad 203" d="M321.226,96.107H305.289a11.834,11.834,0,0,1-10.956,16.3H224.389a11.834,11.834,0,0,1-10.956-16.3H198.547a24.908,24.908,0,0,0-24.908,24.908V436.7a24.908,24.908,0,0,0,24.908,24.908H321.226A24.908,24.908,0,0,0,346.134,436.7V121.015a24.908,24.908,0,0,0-24.908-24.908Z" transform="translate(-164.436 -87.43)" fill="#fff"/>
                               <path id="Pfad_204" data-name="Pfad 204" d="M262.068,219.111h-7.325a.971.971,0,0,0-.971.971V223.3a.971.971,0,0,0,.971.971h1.214v4.817h4.9v-4.817h1.214a.971.971,0,0,0,.971-.971v-3.215A.971.971,0,0,0,262.068,219.111Z" transform="translate(-202.761 -146.258)" fill="#e6e6e6"/>
                               <path id="Pfad_205" data-name="Pfad 205" d="M263.223,253.041a37.658,37.658,0,0,1-6.551-22.327.794.794,0,0,0-.62-.793v-1.389h-7.736v1.37h-.107a.794.794,0,0,0-.793.795q0,.029,0,.058a34.367,34.367,0,0,1-6.174,22.856,2.188,2.188,0,0,0-.4,1.326l.846,26.166a2.265,2.265,0,0,0,2.246,2.186h17.5a2.266,2.266,0,0,0,2.247-2.225l.326-25.4A4.584,4.584,0,0,0,263.223,253.041Z" transform="translate(-196.58 -150.764)" fill="#e6e6e6"/>
                               <path id="Pfad_206" data-name="Pfad 206" d="M263.031,292.166H247.8a1.564,1.564,0,0,0-1.545,1.8l2.3,14.96h13.216l2.8-14.909a1.564,1.564,0,0,0-1.537-1.852Z" transform="translate(-199.157 -181.198)" fill="#fff"/>
                               <path id="Pfad_207" data-name="Pfad 207" d="M418.352,369.345h-7.325a.971.971,0,0,0-.971.971v3.215a.971.971,0,0,0,.971.971h1.214v4.817h4.9V374.5h1.214a.971.971,0,0,0,.971-.971v-3.215A.971.971,0,0,0,418.352,369.345Z" transform="translate(-277.506 -218.11)" fill="#e6e6e6"/>
                               <path id="Pfad_208" data-name="Pfad 208" d="M419.507,403.275a37.657,37.657,0,0,1-6.551-22.327.794.794,0,0,0-.62-.793v-1.389H404.6v1.37h-.107a.794.794,0,0,0-.793.795c0,.019,0,.039,0,.058a34.368,34.368,0,0,1-6.174,22.856,2.188,2.188,0,0,0-.4,1.326l.846,26.166a2.265,2.265,0,0,0,2.246,2.186h17.5a2.266,2.266,0,0,0,2.247-2.225l.326-25.4A4.585,4.585,0,0,0,419.507,403.275Z" transform="translate(-271.325 -222.616)" fill="#e6e6e6"/>
                               <path id="Pfad_209" data-name="Pfad 209" d="M419.315,442.4h-15.23a1.564,1.564,0,0,0-1.545,1.8l2.3,14.96h13.216l2.8-14.909a1.564,1.564,0,0,0-1.537-1.852Z" transform="translate(-273.902 -253.05)" fill="#fff"/>
                               <path id="Pfad_210" data-name="Pfad 210" d="M246.328,581.733h-3.474a.461.461,0,0,0-.461.461v1.525a.461.461,0,0,0,.461.461h.576v2.285h2.323v-2.285h.576a.461.461,0,0,0,.461-.461v-1.525a.461.461,0,0,0-.461-.461Z" transform="translate(-197.319 -319.688)" fill="#e6e6e6"/>
                               <path id="Pfad_211" data-name="Pfad 211" d="M246.876,597.827a17.863,17.863,0,0,1-3.108-10.591.377.377,0,0,0-.294-.376V586.2H239.8v.65h-.051a.377.377,0,0,0-.376.377c0,.009,0,.018,0,.027a16.3,16.3,0,0,1-2.929,10.842,1.038,1.038,0,0,0-.188.629l.4,12.412a1.074,1.074,0,0,0,1.065,1.037h8.3a1.075,1.075,0,0,0,1.066-1.055l.155-12.051A2.175,2.175,0,0,0,246.876,597.827Z" transform="translate(-194.387 -321.825)" fill="#e6e6e6"/>
                               <rect id="Rechteck_102" data-name="Rechteck 102" width="7.919" height="3.96" transform="translate(43.375 280.565)" fill="#fff"/>
                               <path id="Pfad_212" data-name="Pfad 212" d="M277.328,581.733h-3.474a.461.461,0,0,0-.461.461v1.525a.461.461,0,0,0,.461.461h.576v2.285h2.323v-2.285h.576a.461.461,0,0,0,.461-.461v-1.525A.461.461,0,0,0,277.328,581.733Z" transform="translate(-212.145 -319.688)" fill="#e6e6e6"/>
                               <path id="Pfad_213" data-name="Pfad 213" d="M277.876,597.827a17.863,17.863,0,0,1-3.108-10.591.377.377,0,0,0-.294-.376V586.2H270.8v.65h-.051a.377.377,0,0,0-.376.377c0,.009,0,.018,0,.027a16.3,16.3,0,0,1-2.928,10.842,1.038,1.038,0,0,0-.188.629l.4,12.412a1.074,1.074,0,0,0,1.065,1.037h8.3a1.075,1.075,0,0,0,1.066-1.055l.155-12.051A2.175,2.175,0,0,0,277.876,597.827Z" transform="translate(-209.213 -321.825)" fill="#e6e6e6"/>
                               <rect id="Rechteck_103" data-name="Rechteck 103" width="7.919" height="3.96" transform="translate(59.549 280.565)" fill="#fff"/>
                               <path id="Pfad_214" data-name="Pfad 214" d="M308.328,581.733h-3.474a.461.461,0,0,0-.461.461v1.525a.461.461,0,0,0,.461.461h.576v2.285h2.323v-2.285h.576a.461.461,0,0,0,.461-.461v-1.525A.461.461,0,0,0,308.328,581.733Z" transform="translate(-226.971 -319.688)" fill="#e6e6e6"/>
                               <path id="Pfad_215" data-name="Pfad 215" d="M308.876,597.827a17.862,17.862,0,0,1-3.108-10.591.377.377,0,0,0-.294-.376V586.2H301.8v.65h-.051a.377.377,0,0,0-.376.377c0,.009,0,.018,0,.027a16.3,16.3,0,0,1-2.929,10.842,1.038,1.038,0,0,0-.188.629l.4,12.412a1.074,1.074,0,0,0,1.065,1.037h8.3a1.075,1.075,0,0,0,1.066-1.055l.155-12.051A2.175,2.175,0,0,0,308.876,597.827Z" transform="translate(-224.039 -321.825)" fill="#e6e6e6"/>
                               <rect id="Rechteck_104" data-name="Rechteck 104" width="7.919" height="3.96" transform="translate(75.723 280.565)" fill="#fff"/>
                               <path id="Pfad_216" data-name="Pfad 216" d="M393.963,523.613h-7.325a.971.971,0,0,0-.971.971V527.8a.971.971,0,0,0,.971.971h1.214v4.817h4.9V528.77h1.214a.971.971,0,0,0,.971-.971v-3.215A.971.971,0,0,0,393.963,523.613Z" transform="translate(-265.841 -291.891)" fill="#e6e6e6"/>
                               <path id="Pfad_217" data-name="Pfad 217" d="M395.118,557.543a37.657,37.657,0,0,1-6.551-22.327.794.794,0,0,0-.62-.793v-1.389h-7.736v1.37H380.1a.794.794,0,0,0-.793.795c0,.019,0,.039,0,.058a34.366,34.366,0,0,1-6.174,22.856,2.187,2.187,0,0,0-.4,1.326l.846,26.166a2.265,2.265,0,0,0,2.246,2.186h17.5a2.266,2.266,0,0,0,2.247-2.225l.326-25.4A4.585,4.585,0,0,0,395.118,557.543Z" transform="translate(-259.66 -296.397)" fill="#e6e6e6"/>
                               <path id="Pfad_218" data-name="Pfad 218" d="M394.925,596.668H379.7a1.564,1.564,0,0,0-1.545,1.8l2.3,14.96h13.216l2.8-14.91a1.564,1.564,0,0,0-1.537-1.852Z" transform="translate(-262.238 -326.831)" fill="#fff"/>
                               <path id="Pfad_219" data-name="Pfad 219" d="M369.207,286.893H325.544c3.053-16.5,2.35-36.434,0-57.866h43.663C366.919,250.46,366.235,270.389,369.207,286.893Z" transform="translate(-237.087 -151.001)" fill="#e6e6e6"/>
                               <circle id="Ellipse_60" data-name="Ellipse 60" cx="10.521" cy="10.521" r="10.521" transform="translate(100.03 94.86)" fill="#fff"/>
                               <path id="Pfad_220" data-name="Pfad 220" d="M292.577,434.1H248.914c3.053-16.5,2.35-36.434,0-57.866h43.663C290.289,397.669,289.606,417.6,292.577,434.1Z" transform="translate(-200.438 -221.406)" fill="#e6e6e6"/>
                               <circle id="Ellipse_61" data-name="Ellipse 61" cx="10.521" cy="10.521" r="10.521" transform="translate(60.05 171.664)" fill="#fff"/>
                               <path id="Pfad_221" data-name="Pfad 221" d="M416.6,293.951H372.933c3.053-16.5,2.35-36.434,0-57.866H416.6C414.308,257.518,413.625,277.447,416.6,293.951Z" transform="translate(-259.752 -154.376)" fill="#e6e6e6"/>
                               <circle id="Ellipse_62" data-name="Ellipse 62" cx="10.521" cy="10.521" r="10.521" transform="translate(124.755 98.542)" fill="#fff"/>
                               <path id="Pfad_222" data-name="Pfad 222" d="M416.6,288.91H372.933c3.053-16.5,2.35-36.434,0-57.866H416.6C414.308,252.476,413.625,272.406,416.6,288.91Z" transform="translate(-259.752 -151.965)" fill="#e6e6e6"/>
                               <circle id="Ellipse_63" data-name="Ellipse 63" cx="10.521" cy="10.521" r="10.521" transform="translate(124.755 95.912)" fill="#fff"/>
                               <path id="Pfad_223" data-name="Pfad 223" d="M416.6,293.951H372.933c3.053-16.5,2.35-36.434,0-57.866H416.6C414.308,257.518,413.625,277.447,416.6,293.951Z" transform="translate(-259.752 -154.376)" fill="#e6e6e6"/>
                               <circle id="Ellipse_64" data-name="Ellipse 64" cx="10.521" cy="10.521" r="10.521" transform="translate(124.755 98.542)" fill="#fff"/>
                               <rect id="Rechteck_105" data-name="Rechteck 105" width="143.088" height="15.782" transform="translate(23.752 132.736)" fill="#e6e6e6"/>
                               <rect id="Rechteck_106" data-name="Rechteck 106" width="143.088" height="15.782" transform="translate(23.752 211.119)" fill="#e6e6e6"/>
                               <rect id="Rechteck_107" data-name="Rechteck 107" width="143.088" height="15.782" transform="translate(23.752 289.501)" fill="#e6e6e6"/>
                               <path id="Pfad_224" data-name="Pfad 224" d="M920.177,690.655,884.53,665.442c12.023-11.712,22.957-28.388,33.415-47.244l35.647,25.213C939.348,659.588,927.281,675.465,920.177,690.655Z" transform="translate(-504.43 -337.128)" fill="#fb923c"/>
                               <circle id="Ellipse_65" data-name="Ellipse 65" cx="10.521" cy="10.521" r="10.521" transform="translate(405.236 305.641)" fill="#fff"/>
                               <path id="Pfad_225" data-name="Pfad 225" d="M619.2,536.554h-3.913a.519.519,0,0,0-.519.519v1.718a.519.519,0,0,0,.519.519h.649v2.573h2.616v-2.573h.648a.519.519,0,0,0,.519-.519v-1.718A.519.519,0,0,0,619.2,536.554Z" transform="translate(-375.413 -298.08)" fill="#e6e6e6"/>
                               <path id="Pfad_226" data-name="Pfad 226" d="M619.817,554.68a20.117,20.117,0,0,1-3.5-11.927.424.424,0,0,0-.331-.423v-.742h-4.133v.732H611.8a.424.424,0,0,0-.424.425c0,.01,0,.021,0,.031a18.358,18.358,0,0,1-3.3,12.21,1.169,1.169,0,0,0-.212.708l.452,13.978a1.21,1.21,0,0,0,1.2,1.168h9.347a1.211,1.211,0,0,0,1.2-1.189l.174-13.571A2.449,2.449,0,0,0,619.817,554.68Z" transform="translate(-372.111 -300.487)" fill="#fb923c"/>
                               <path id="Pfad_227" data-name="Pfad 227" d="M619.647,537.627a1.119,1.119,0,0,1-2.239,0" transform="translate(-376.675 -298.593)" opacity="0.2" style="isolation: isolate"/>
                               <rect id="Rechteck_108" data-name="Rechteck 108" width="7.891" height="5.261" transform="translate(237.995 256.886)" fill="#fff"/>
                               <path id="Pfad_228" data-name="Pfad 228" d="M853,641.218h-7.325a.971.971,0,0,0-.971.971V645.4a.971.971,0,0,0,.971.971h1.214v4.817h4.9v-4.817H853a.971.971,0,0,0,.971-.971v-3.215A.971.971,0,0,0,853,641.218Z" transform="translate(-485.382 -348.137)" fill="#e6e6e6"/>
                               <path id="Pfad_229" data-name="Pfad 229" d="M854.153,675.148a37.657,37.657,0,0,1-6.551-22.327.794.794,0,0,0-.62-.793v-1.389h-7.736v1.37h-.106a.794.794,0,0,0-.793.795c0,.019,0,.039,0,.058a34.367,34.367,0,0,1-6.174,22.856,2.187,2.187,0,0,0-.4,1.326l.846,26.166a2.265,2.265,0,0,0,2.246,2.186h17.5a2.266,2.266,0,0,0,2.247-2.225l.326-25.4A4.584,4.584,0,0,0,854.153,675.148Z" transform="translate(-479.201 -352.643)" fill="#fb923c"/>
                               <path id="Pfad_230" data-name="Pfad 230" d="M853.834,643.226a2.1,2.1,0,0,1-4.19,0" transform="translate(-487.746 -349.098)" opacity="0.2" style="isolation: isolate"/>
                               <path id="Pfad_231" data-name="Pfad 231" d="M853.961,714.273H838.73a1.564,1.564,0,0,0-1.546,1.8l2.3,14.96H852.7l2.8-14.91a1.563,1.563,0,0,0-1.537-1.852Z" transform="translate(-481.778 -383.077)" fill="#fff"/>
                               <path id="Pfad_232" data-name="Pfad 232" d="M780.521,703.951H736.858c3.053-16.5,2.35-36.434,0-57.867h43.663C778.233,667.517,777.55,687.446,780.521,703.951Z" transform="translate(-433.804 -350.465)" fill="#fb923c"/>
                               <circle id="Ellipse_66" data-name="Ellipse 66" cx="10.521" cy="10.521" r="10.521" transform="translate(314.627 312.453)" fill="#fff"/>
                               <path id="Pfad_233" data-name="Pfad 233" d="M322.243,308.045l9.8-.891,6.238-15.148-11.138-6.238Z" transform="translate(-154.117 -136.673)" fill="#ffb8b8"/>
                               <path id="Pfad_234" data-name="Pfad 234" d="M630.254,479.875h0a5.527,5.527,0,0,0-4.8-7.574l-15.219-.908-2.241,7.451,15.667,4.3A5.527,5.527,0,0,0,630.254,479.875Z" transform="translate(-372.173 -266.916)" fill="#ffb8b8"/>
                               <path id="Pfad_235" data-name="Pfad 235" d="M550.621,446.342l-.167-13.982-33.8-5.394L490.606,397.1a8.524,8.524,0,0,0-11.11-1.519h0a8.524,8.524,0,0,0-1.485,13l21.485,26.44Z" transform="translate(-308.881 -229.986)" fill="#575a89"/>
                               <path id="Pfad_236" data-name="Pfad 236" d="M463.726,657.6h8.911l22.1-99.93,22.9,30.87L531,655.368h8.465l-1.782-71.733L507.834,507H478.428c-8.036,14.543-14.34,29.535-7.129,45Z" transform="translate(-303.174 -283.947)" fill="#2f2e41"/>
                               <path id="Pfad_237" data-name="Pfad 237" d="M582.107,797.834a2.326,2.326,0,0,0,1.813,2.984l21.805,3.87a3.588,3.588,0,0,0,4.07-2.459h0a3.566,3.566,0,0,0-1.744-4.24,33.34,33.34,0,0,1-12.025-10.455c-2.4,2.232-5.075,2.022-7.919.377l-3.017.377Z" transform="translate(-359.742 -418.115)" fill="#2f2e41"/>
                               <path id="Pfad_238" data-name="Pfad 238" d="M455.721,797.834a2.326,2.326,0,0,0,1.813,2.984l21.805,3.87a3.588,3.588,0,0,0,4.07-2.459h0a3.566,3.566,0,0,0-1.744-4.24,33.339,33.339,0,0,1-12.025-10.455c-2.4,2.232-5.075,2.022-7.919.377l-3.017.377Z" transform="translate(-299.295 -418.115)" fill="#2f2e41"/>
                               <circle id="Ellipse_67" data-name="Ellipse 67" cx="12.475" cy="12.475" r="12.475" transform="translate(171.913 135.952)" fill="#ffb8b8"/>
                               <path id="Pfad_239" data-name="Pfad 239" d="M478.926,453.982l32.525-.891L501.9,423.028c5.936-9.516-.038-19.022-8.493-28.527l-6.683-4.455-10.693-.891h0a26.251,26.251,0,0,0-7.069,18.287A117.807,117.807,0,0,0,478.926,453.982Z" transform="translate(-305.677 -227.584)" fill="#575a89"/>
                               <path id="Pfad_240" data-name="Pfad 240" d="M612.9,537.824h0a5.527,5.527,0,0,0-1.434-8.852l-13.635-6.821-4.992,5.969,12.711,10.119A5.527,5.527,0,0,0,612.9,537.824Z" transform="translate(-364.926 -291.192)" fill="#ffb8b8"/>
                               <path id="Pfad_241" data-name="Pfad 241" d="M551.864,477.3l5.346-12.921-28.957-18.257-12.193-37.7a8.524,8.524,0,0,0-9.617-5.767h0a8.524,8.524,0,0,0-6.48,11.37l9.351,32.76Z" transform="translate(-320.248 -233.976)" fill="#575a89"/>
                               <path id="Pfad_242" data-name="Pfad 242" d="M470.13,340.944c0,4.854,3.152,12.372,7.129,15.594,2.675,2.168,6.211,2.077,7.574-.891a35.311,35.311,0,0,1,14.7-14.7c11.31-6-4.011-16.268-14.7-14.7C476.8,327.418,470.13,332.824,470.13,340.944Z" transform="translate(-306.237 -197.418)" fill="#2f2e41"/>
                               <circle id="Ellipse_68" data-name="Ellipse 68" cx="8.911" cy="8.911" r="8.911" transform="translate(162.556 119.021)" fill="#2f2e41"/>
                               <path id="Pfad_243" data-name="Pfad 243" d="M496.733,310.534a8.9,8.9,0,0,0-8.243-8.877c.221-.016.443-.034.668-.034a8.911,8.911,0,0,1,0,17.822c-.226,0-.447-.017-.668-.034A8.9,8.9,0,0,0,496.733,310.534Z" transform="translate(-315.018 -185.721)" fill="#2f2e41"/>
                               <ellipse id="Ellipse_69" data-name="Ellipse 69" cx="2.005" cy="2.673" rx="2.005" ry="2.673" transform="translate(182.606 147.536)" fill="#ffb8b8"/>
                               <path id="Pfad_244" data-name="Pfad 244" d="M660.687,551.843H521.972L492.6,412.982H467.529v-6.231h30.119l29.374,138.86H660.687Z" transform="translate(-223.603 -194.535)" fill="#3f3d56"/>
                               <circle id="Ellipse_70" data-name="Ellipse 70" cx="13.352" cy="13.352" r="13.352" transform="translate(305.345 355.972)" fill="#3f3d56"/>
                               <circle id="Ellipse_71" data-name="Ellipse 71" cx="13.352" cy="13.352" r="13.352" transform="translate(405.04 355.972)" fill="#3f3d56"/>
                               <path id="Pfad_245" data-name="Pfad 245" d="M843.358,650.823H708.4L684.584,533.145H865.7a6.4,6.4,0,0,1,6.321,7.391L849.678,645.418A6.367,6.367,0,0,1,843.358,650.823Zm-133.5-1.78h133.5a4.594,4.594,0,0,0,4.561-3.9L870.267,540.26a4.618,4.618,0,0,0-4.562-5.335H686.745Z" transform="translate(-408.803 -296.45)" fill="#3f3d56"/>
                               <path id="Pfad_246" data-name="Pfad 246" d="M610.218,571.654,594.623,455.387l1.756-.29,15.595,116.267Z" transform="translate(-284.387 -217.657)" fill="#3f3d56"/>
                               <path id="Pfad_247" data-name="Pfad 247" d="M789.1,570.763l-1.758-.285L802.482,455.1l1.758.285Z" transform="translate(-376.558 -217.659)" fill="#3f3d56"/>
                               <rect id="Rechteck_109" data-name="Rechteck 109" width="1.78" height="116.266" transform="translate(369.448 237.585)" fill="#3f3d56"/>
                               <rect id="Rechteck_110" data-name="Rechteck 110" width="1.78" height="172.327" transform="translate(284.87 270.518) rotate(-89.861)" fill="#3f3d56"/>
                               <rect id="Rechteck_111" data-name="Rechteck 111" width="151.396" height="1.78" transform="translate(293.651 324.768) rotate(-0.392)" fill="#3f3d56"/>
                               <ellipse id="Ellipse_72" data-name="Ellipse 72" cx="9.791" cy="5.341" rx="9.791" ry="5.341" transform="translate(236.805 209.101)" fill="#3f3d56"/>
                             </svg>
                             
                             
                       </div>
                     </figure>
                   </div>
                 </div>
                 <div class="mt-8 lg:mt-0">
                   <div class="text-base max-w-prose mx-auto lg:max-w-none">
                     <p class="text-lg text-gray-500">
                         Mit Getfrom wird der lokale Handel an der richtigen Stelle, zur richtigen Zeit, dem Kunden ins Bewusstsein gerufen. <strong>Somit wachsen der Onlinehandel und der lokale Handel an der richtigen Stelle zusammen</strong>. Davon profitieren der Onlinehandel,
                         der lokale Handel, aber vor allem auch die Kunden.</p>
                   </div>
                   
                 </div>
               </div>
             </div> 
            
            
            
          
          
          
          
        </div>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="bg-gray-100">
      <div class="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8 lg:py-20">
        <div class="max-w-4xl mx-auto text-center">
          <h2 class="text-3xl font-extrabold text-gray sm:text-4xl">
            Zahlen. Fakten. Vorteile.
          </h2>
          <p class="mt-3 text-xl text-gray-600 sm:mt-4">
            Erreiche mehr als jemals zuvor mit Getfrom.
          </p>
        </div>
        <dl class="mt-10 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8">
          <div class="flex flex-col">
            <dt class="order-2 mt-2 text-lg leading-6 font-medium text-gray-600">
              Onlineshop Besucher
            </dt>
            <dd class="order-1 text-5xl font-extrabold text-gray">
              10 Mio.
            </dd>
          </div>
          <div class="flex flex-col mt-10 sm:mt-0">
            <dt class="order-2 mt-2 text-lg leading-6 font-medium text-gray-600">
              Alle Bundesländer
            </dt>
            <dd class="order-1 text-5xl font-extrabold text-gray">
              16
            </dd>
          </div>
          <div class="flex flex-col mt-10 sm:mt-0">
            <dt class="order-2 mt-2 text-lg leading-6 font-medium text-gray-600">
              Produkte
            </dt>
            <dd class="order-1 text-5xl font-extrabold text-gray">
              200k
            </dd>
          </div>
        </dl>
      </div>
    </div>
    
    
    
    <p class="text-xs px-16 py-8 text-gray-400 text-center">Getfrom powered by: Benz GmbH & Co. KG Baustoffe, Auwiesen 4, 74924 Neckarbischofsheim, Deutschland, Telefon: 07263 / 60 541-30, E-Mail: help@getfrom.com, USt-IdNr.: DE144334468, eingetragen im Handelsregister des Amtsgerichtes Mannheim, Handelsregisternummer HRA 340361, vertreten durch die persönlich haftende Gesellschafterin, Benz Baustoffe Verwaltungs GmbH, diese vertreten durch den Geschäftsführer Roland Benz, Nina Schieck, Christian Schieck, Auwiesen 4, 74924 Neckarbischofsheim, eingetragen im Handelsregister des Amtsgerichtes Mannheim, Handelsregisternummer HRB 341191</p>
    
    
    
    
    
</div>
    
    


    
    
   
    
    
    
</template>

<script>
export default {
name: "Index"
}
</script>

<style scoped>

</style>