<template>
    <div class="mt-10 flex-grow flex flex-col">
        <nav class="flex-1 space-y-1">
            <div class="pr-3 pb-4">
                <template v-if="isDealer">
                    <!-- Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
                    <NavItem v-if="userHasPermission('visits.view')" to="/customers"
                             label="Kunden">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"/>
                    </NavItem>

                    <NavItem v-if="userHasPermission('products.view')" to="/products"
                             label="Produkte">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"/>
                    </NavItem>

                    <NavItem v-if="userHasPermission('branches.view')" to="/branches"
                             label="Filialen">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"/>
                    </NavItem>

                    <NavItem v-if="userHasPermission('team.edit')" to="/team" label="Team">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"/>
                    </NavItem>
                </template>
                <template v-if="isWebshop">
                    <NavItem v-if="userHasPermission('provision.view')" to="/ecommerce/provision" label="Provision">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"/>
                    </NavItem>
                </template>
                <template v-if="isSU">
                    <NavItem to="/su/dashboard" label="Dashboard">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
                        </svg>
                    </NavItem>

                    <NavItem to="/su/accounts" label="Accounts">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4" />
                        </svg>
                    </NavItem>
                </template>
            </div>
            <div class="pr-3 pt-4">
                <NavItem v-if="isDealer" to="/faq" label="Hilfe">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </NavItem>

                <NavItem v-if="isWebshop" to="/ecommerce/integration" label="Integration">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"/>
                </NavItem>

                <NavItem v-if="isSuLoggedInAsUser" @click="backToSu" label="Accounts">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                    </svg>
                </NavItem>

                <NavItem @click="logout" label="Ausloggen">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"/>
                </NavItem>
            </div>
        </nav>
        <div class="flex-1 flex flex-col flex-grow justify-end">
            <router-link class="bg-gray-100 group text-lg flex items-center pl-5 pr-3 py-4 text-sm font-medium box-border border-l-6 border-transparent"
                         exact-active-class="text-orange-900 bg-orange-100 border-orange-400"
                         :to="accountLink"
                         v-slot="{isActive}"
            >
                <profile-icon v-model="user.name" background-color="bg-transparent" size="w-8 h-8 text-sm"
                              :class="{'text-orange-800 border-orange-800': isActive}"
                              class="text-gray-600 border-2 border-gray-600 mr-4" />
                {{user.name}}
            </router-link>
        </div>
    </div>

</template>

<script>
import {mapGetters} from "vuex";
import {AUTH_LOGOUT} from "@/store/auth";
import NavItem from "./NavItem";
import ProfileIcon from "../general/ProfileIcon";
import {CONTEXT_BACK_TO_SU} from "@/store/context";

export default {
    name: "SidebarNav",
    components: {NavItem, ProfileIcon},
    computed: {
        ...mapGetters([
            'user',
            'userHasPermission',
            'isDealer',
            'isWebshop',
            'isSU',
            'actingAs',
            'isSuLoggedInAsUser'
        ]),
        accountLink(){
            if(this.isWebshop) {
                return '/ecommerce/account'
            }else if(this.isDealer){
                return '/account'
            }else if(this.isSU){
                return '/su/account'
            }

            return '/login'
        }
    },
    methods: {
        logout() {
            this.$store.dispatch(AUTH_LOGOUT)
                .then(() => {
                    this.$router.push({name: 'Login'})
                })
        },
        backToSu(){
            this.$store.dispatch(CONTEXT_BACK_TO_SU).then(res => {
                this.$router.replace({name: 'Accounts'})
            })
        }
    },
}
</script>

<style scoped>

</style>