<template>
    <div>
        <default-header v-slot="{title}">
            <div class="flex-grow flex w-100">
                <div class="flex-grow">
                    <h2 class="text-xl font-medium leading-7 sm:text-xl sm:truncate">
                        {{ title }}
                    </h2>
                </div>
                <div class="flex-grow-0">
                    <div class="flex flex-column">
                        <button-contact />
                    </div>
                </div>
            </div>
        </default-header>
        <div class="mx-auto py-6 px-4">
            <div class="mx-auto divide-y-2 divide-gray-200">
                <dl class="mt-8 space-y-8 divide-y divide-gray-200">
                    <collapsible class="pt-8" button-text="Wie lade ich neue Produkte hoch?">
                        Auf der Seite "Produkte" kannst du über den Button in der rechten oberen Ecke einzelne EANs hinzufügen oder ganze CSV-Listen hochladen.
                    </collapsible>

                    <collapsible class="pt-8" button-text="Kann ich die selben EANs für alle Filialen auf einmal hochladen?">
                        Nein, da sich Bestand und Sortiment unterscheiden kann.
                    </collapsible>

                    <collapsible class="pt-8" button-text="Werden vorhandene EANs durch neue ersetzt, wenn ich eine Datei hochlade?">
                        Nein. Falls gewünscht, lösche zuerst die aktuelle Liste oder einzelne EANs.
                    </collapsible>

                    <collapsible class="pt-8" button-text="Warum kann ich nur eine Filiale sehen?">
                        Dein Account-Besitzer hat dich nur für eine freigegeben.
                    </collapsible>

                    <collapsible max-height="max-h-24" class="pt-8" button-text="Was wenn ich noch weitere Fragen habe?">
                        Nutze den Kontakt-Button, um deine Frage zu stellen.
                        <button-contact class="block mt-6" />
                    </collapsible>

                </dl>
            </div>
        </div>
    </div>
</template>

<script>
    import DefaultHeader from "../../../components/layout/DefaultHeader";
    import Collapsible from "@/components/general/Collapsible";
    import ButtonPrimary from "@/components/buttons/ButtonPrimary";
    import ButtonContact from "@/components/buttons/ButtonContact";
    export default {
    	name: 'FaqIndex',
        components: {ButtonContact, Collapsible, DefaultHeader, ButtonPrimary},
        data: () => ({
            isOn: false,
            isOn1: false,
        }),

    }
</script>