<template>
    <div v-show="show" class="md:hidden">
        <div class="fixed inset-0 flex z-40">

            <transition
                @before-enter="show=true"
                @after-leave="show=false"
                enter-active-class="transition-opacity ease-linear duration-300"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition-opacity ease-linear duration-300"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div v-if="showSidebar" @click="closeSidebar" key="overlay" class="fixed inset-0" aria-hidden="true">
                    <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
                </div>
            </transition>


            <transition
                enter-active-class="transition ease-in-out duration-300 transform"
                enter-from-class="-translate-x-full"
                enter-to-class="translate-x-0"
                leave-active-class="transition ease-in-out duration-300 transform"
                leave-from-class="translate-x-0"
                leave-to-class="-translate-x-full"
            >
                <div v-if="showSidebar" key="sidebar" class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-0 bg-white">
                    <div class="absolute top-0 right-0 -mr-12 pt-2">
                        <button @click="closeSidebar" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                            <span class="sr-only">Close sidebar</span>
                            <!-- Heroicon name: x -->
                            <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div class="flex items-center flex-shrink-0 pt-4 px-8">
                        <router-link to="/">
                            <img class="h-6 w-auto"
                                 src="@/assets/images/logo.svg">
                        </router-link>
                    </div>
                    <sidebar-nav />
                </div>
            </transition>
            <div class="flex-shrink-0 w-14" aria-hidden="true">
                <!-- Dummy element to force sidebar to shrink to fit close icon -->
            </div>
        </div>
    </div>
</template>

<script>
import SidebarNav from "@/components/layout/SidebarNav";
import {SIDEBAR_SHOW} from "@/store/sidebar";
import {mapGetters} from "vuex";


export default {
    	name: 'SidebarMobile',
        components: {SidebarNav},
        data: () => ({
            show: false
        }),
        computed: {
    	    ...mapGetters([
    	        'showSidebar'
            ]),
        },
        methods: {
    	    closeSidebar(){
    	        if(this.showSidebar) {
                    this.$store.commit(SIDEBAR_SHOW, false)
                }
            }
        },
        watch: {
    	    $route (from, to){
    	        this.closeSidebar()
            }
        }
    }
</script>