<template>
	<div class="flex-grow flex w-100">
		<div class="flex-grow">
			<h2 class="text-xl font-medium leading-7 sm:text-xl sm:truncate">
				Mein Team
			</h2>
		</div>
		<div class="flex-grow-0">
			<div class="flex flex-column">
<!--				<button-primary @click="add=true">-->
<!--					<svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" stroke="none">-->
<!--						<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />-->
<!--					</svg>-->
<!--					Mitglied-->
<!--				</button-primary>-->
			</div>
		</div>


		<slide-over v-model="add"
					title="Mitglied hinzufügen"
					sub-title="Füge deinem Team ein Mitglied hinzu"
					v-slot="{close}"
		>
            <div class="mt-1 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <!-- Heroicon name: solid/mail -->
                    <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg>
                </div>
                <input type="text" name="email" id="email" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" placeholder="E-Mail Adresse">
            </div>
            <div class="mt-10 text-right">
                <button-primary type="submit">
                    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                    Speichern
                </button-primary>
            </div>
		</slide-over>

	</div>
</template>

<script>

	import SlideOver from "../../../components/overlays/SlideOver";
	import ButtonPrimary from "../../../components/buttons/ButtonPrimary";


	export default {
		name: "Header",
		components: {
			SlideOver,
			ButtonPrimary
		},
		data: () => ({
			add: false
		})
	}
</script>

<style scoped>

</style>