<template>
	<div class="flex-1 flex justify-between md:flex md:items-center h-24 mb-4 md:justify-between">
		<div class="flex-1 flex">
			<slot v-bind="{title: header}">
				<h2 class="text-xl font-medium leading-7 sm:text-xl sm:truncate">
					{{header}}
				</h2>
			</slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: "DefaultHeader",
		computed: {
			header(){
				return this.$route.meta?.title || ''
			}
		}
	}
</script>

<style scoped>

</style>