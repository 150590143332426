import store, {LOADING_FINISHED, LOADING_START} from "../../store";
import {ERROR_OPEN, SUCCESS_OPEN} from "../../store/modals";

export const success = function(title, text) {
	store.commit(SUCCESS_OPEN, {title, text})
}

export const error = function(title, text) {
	store.commit(ERROR_OPEN, {title, text})
}



export const startLoading = function() {
	store.commit(LOADING_START)
}

export const stopLoading = function(){
	store.commit(LOADING_FINISHED)
}