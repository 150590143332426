
import {api, dealer} from "../js/helper/api";
import {DEALERS_BRANCH_REQUEST, DEALERS_BRANCH_UPDATE} from "./dealers";
import FileDownload from 'js-file-download'
import {startLoading, stopLoading} from "@/js/helper/modals";


export const BRANCHES_REQUEST = "BRANCHES_REQUEST"
export const BRANCHES_UPDATE = "BRANCHES_UPDATE"
export const BRANCHES_EDIT = "BRANCHES_EDIT"
export const BRANCHES_SELECT = "BRANCHES_SELECTED"
export const BRANCHES_DELETE = "BRANCHES_DELETE"
export const BRANCHES_ADD_FEED = "BRANCHES_ADD_FEED"
export const BRANCHES_SWITCH_FEED_MODE = "BRANCHES_SWITCH_FEED_MODE"
export const BRANCHES_FEED_DELETE = "BRANCHES_FEED_DELETE"
export const BRANCHES_PRODUCTS_UPDATE = "BRANCHES_PRODUCTS_UPDATE"
export const BRANCHES_PRODUCTS_REQUEST = "BRANCHES_PRODUCTS_REQUEST"
export const BRANCHES_PRODUCTS_DELETE = "BRANCHES_PRODUCTS_DELETE"
export const BRANCHES_PRODUCTS_ADD = "BRANCHES_PRODUCTS_ADD"
export const BRANCHES_PRODUCTS_DOWNLOAD = "BRANCHES_PRODUCTS_DOWNLOAD"
export const BRANCHES_PRODUCTS_DOWNLOAD_EXAMPLE = "BRANCHES_PRODUCTS_DOWNLOAD_EXAMPLE"
export const BRANCHES_PRODUCTS_DELETE_ALL = "BRANCHES_PRODUCTS_DELETE_ALL"
export const BRANCHES_LOADED = "BRANCHES_LOADED"
export const BRANCHES_LOADING = "BRANCHES_LOADING"


export default {
	state: {
		branches: [],
		current: {},
		products: [],
		loaded:false,
		loading:false,
	},
	getters: {
		branches: state => state.branches,
		branchesLoaded: state => state.loaded,
		selectedBranch: state => state.current,
		selectedBranchFeedMode: state => state.current.feed_mode,
		isBranchSelected: state => !!Object.keys(state.current).length,
		selectedBranchProducts: state => state.products || [],
		branchesLoading: state => state.loading
	},
	mutations: {
		[BRANCHES_LOADING]: (state, loading) => {
			state.loading = loading
		},
		[BRANCHES_UPDATE]: (state, branches) => {
			state.branches = branches
		},
		[BRANCHES_SELECT]: (state, branch) => {
			state.current = branch
		},
		[BRANCHES_LOADED]: (state) => {
			state.loaded = true
		},
		[BRANCHES_DELETE]: (state, branch) => {
			state.branches = state.branches.filter(oldBranch => oldBranch.id !== branch.id)
		},
		[BRANCHES_PRODUCTS_UPDATE]: (state, products) => {
			state.products = products
		},
		[BRANCHES_PRODUCTS_DELETE]: (state, product) => {
			let index = state.products.indexOf(product)
			if(index >= 0){
				console.log(index);
				state.products.splice(index, 1)
			}
		},
		[BRANCHES_PRODUCTS_DELETE_ALL]: (state) => {
			state.products = []
		},
		[BRANCHES_SWITCH_FEED_MODE]: (state, mode) => {
			state.current.feed_mode = mode
		},
		[BRANCHES_ADD_FEED]: (state, feed) => {
			state.current.feed = feed
			state.branches.find(branch => state.current.id === branch.id).feed = feed
		},
		[BRANCHES_FEED_DELETE]: (state, feed) => {
			state.current.feed = null
			state.branches.find(branch => state.current.id === branch.id).feed = null
		}
	},
	actions: {
		[BRANCHES_REQUEST]: ({commit}) => {
			return new Promise((resolve, reject) => {
				dealer.get('branch').then((res) => {
					commit(BRANCHES_UPDATE, res.data)
					commit(BRANCHES_LOADED)

					resolve(res.data);
				}).catch(err => reject(err))
			})
		},
		[BRANCHES_SELECT]: ({commit, dispatch}, branch) => {
			startLoading()
			return new Promise((resolve, reject) => {
				if(typeof branch != 'undefined' && branch) {
					commit(BRANCHES_SELECT, branch)
					dispatch(BRANCHES_PRODUCTS_REQUEST, branch).then(() => {
						resolve(branch)
						stopLoading()
					}).catch(err => reject(err))
				} else {
					reject();
				}
			})
		},
		[BRANCHES_EDIT]: ({commit, dispatch}, branch) => {
			commit(BRANCHES_LOADING, true)
			return new Promise( (resolve, reject) => {
				let prom
				const config = {headers: {
					'Content-Type': 'multipart/form-data'
				}}

				if(branch.has('id')) {
					//	Symfony Bug, Multipart Form muss als Post gesendet werden, kann aber als PATCH getunnelt werden
					branch.append('_method', 'PATCH')
					prom = dealer.post(`branch/${branch.get('id')}`, branch, config)
				} else {
					prom = dealer.post(`branch`, branch, config)
				}

				prom.then(res => {
					resolve(res)
					commit(BRANCHES_LOADING, false)

					dispatch(BRANCHES_REQUEST)
				}).catch(err => reject(err))
			})
		},
		[BRANCHES_DELETE]: ({commit, dispatch, getters}, branch) => {
			commit(BRANCHES_LOADING, true)
			return new Promise( (resolve, reject) => {
				dealer.delete(`branch/${branch.id}`).then(res => {
					commit(BRANCHES_DELETE, branch)
					dispatch(BRANCHES_SELECT, getters.branches[0] || null).catch(err => {
						//	Falls keine Filiale mehr vorhanden ist
						commit(BRANCHES_PRODUCTS_UPDATE, [])
						commit(BRANCHES_SELECT, {})
						stopLoading()
					})

					resolve(res)
				})
			})
		},
		[BRANCHES_PRODUCTS_REQUEST]: ({commit}, branch) => {
			return new Promise((resolve, reject) => {
				dealer.get(`branch/${branch.id}/product`).then((res) => {
					commit(BRANCHES_PRODUCTS_UPDATE, res.data)

					resolve(res.data)
				}).catch(err => {reject(err)})
			})
		},
		[BRANCHES_PRODUCTS_DELETE]: ({commit, getters}, product) => {
			console.log(BRANCHES_PRODUCTS_DELETE)
			return new Promise((resolve, reject) => {
				dealer.delete(`branch/${getters.selectedBranch.id}/product/${product.id}`).then(res => {
					commit(BRANCHES_PRODUCTS_DELETE, product)

					resolve(res)
				}).catch(err => {reject(err)})
			});
		},
		[BRANCHES_PRODUCTS_ADD]: ({commit, getters}, form) => {
			console.log(BRANCHES_PRODUCTS_ADD)
			return new Promise((resolve, reject) => {
				dealer.post(`branch/${getters.selectedBranch.id}/product`, form, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(res => {
					commit(BRANCHES_PRODUCTS_UPDATE, res.data)

					resolve(res)
				}).catch(err => {reject(err)})
			});
		},
		[BRANCHES_PRODUCTS_DOWNLOAD]: ({getters, commit}) => {
			commit(BRANCHES_LOADING, true)
			return new Promise((resolve, reject) => {
				dealer.get(`branch/${getters.selectedBranch.id}/productlist`, {
					responseType: 'blob'
				}).then((res) => {
					commit(BRANCHES_LOADING, false)
					FileDownload(res.data, 'products.csv')
				})
			})
		},
		[BRANCHES_PRODUCTS_DOWNLOAD_EXAMPLE]: () => {
			return new Promise((resolve, reject) => {
				dealer.get(`productlist/example`, {
					responseType: 'blob'
				}).then((res) => {
					FileDownload(res.data, 'products-example.csv')
				})
			})
		},
		[BRANCHES_PRODUCTS_DELETE_ALL]: ({commit, getters}) => {
			console.log(BRANCHES_PRODUCTS_DELETE_ALL)
			return new Promise((resolve, reject) => {
				dealer.delete(`branch/${getters.selectedBranch.id}/product`).then(res => {
					commit(BRANCHES_PRODUCTS_DELETE_ALL)

					resolve(res)
				}).catch(err => {reject(err)})
			});
		},
		[BRANCHES_SWITCH_FEED_MODE]: ({commit, getters}, mode) => {
			return new Promise((resolve, reject) => {
				dealer.post(`branch/${getters.selectedBranch.id}/feedmode`, {mode}).then(res => {
					commit(BRANCHES_SWITCH_FEED_MODE, mode)

					resolve(res);
				}).catch(err => {reject(err)})
			});
		},
		[BRANCHES_ADD_FEED]: ({commit, getters}, feed) => {
			return new Promise((resolve, reject) => {
				dealer.post(`branch/${getters.selectedBranch.id}/feed`, feed).then(res => {
					commit(BRANCHES_ADD_FEED, res.data)

					resolve(res)
				}).catch(err => reject(err))
			});
		},
		[BRANCHES_FEED_DELETE]: ({commit, getters}, feed) => {
			return new Promise((resolve, reject) => {
				dealer.delete(`branch/${getters.selectedBranch.id}/feed/${feed.id}`).then(res => {
					commit(BRANCHES_FEED_DELETE, feed)

					resolve(res)
				}).catch(err => reject(err))
			})
		}
	}
}