export default {
	methods: {
		formatNumber(number){
			if(!isNaN(number)){
				return Intl.NumberFormat().format(number)
			}

			return number
		}
	}
}