<template>
	<div class="bg-gray-50 flex-col text-center p-24">
		<img :src="src" class="w-1/2 mx-auto" >
		<div class="mt-8 text-xl font-bold">
			<slot name="title">

			</slot>
		</div>
		<div class="text-xl font-normal text-gray-600">
			<slot name="sub-title"></slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: "ImageLandingPage",
		props: {
			src: String
		}
	}
</script>

<style scoped>

</style>