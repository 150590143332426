<template>
    <div>
        <router-link class=""
                     v-if="to"
                     :to="to"
                     :class="{[hoverClass]: !active,  [defaultClass]: true}"
                     :exact-active-class="activeClass"
                     v-slot="{isActive}"
        >
            <svg class="mr-4 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <slot></slot>
            </svg>
            {{label}}
        </router-link>


        <a v-else
           :class="{[activeClass]: active, [hoverClass]: !active, [defaultClass]: true}"
           class=""
        >
            <svg class="mr-4 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <slot></slot>
            </svg>
            {{label}}
        </a>
    </div>
</template>

<script>
    export default {
    	name: 'NavItem',
        data: () => ({
            defaultClass: 'cursor-pointer text-gray-700 group text-lg flex items-center pl-5 pr-3 py-3 text-sm font-medium box-border border-l-6 border-transparent ',
            activeClass: 'text-orange-900 bg-orange-100 border-orange-300 rounded-r-lg',
            hoverClass: 'hover:bg-gray-100 hover:border-gray-400 hover:rounded-r-lg'
        }),
        props: {
            to: String,
            label: {
            	type: String,
                required: true
            }
        },
        computed: {
    	    active(){
    	        return this.$route.path == this.to
            }
        }
    }
</script>

<style>
</style>