
export const CONFIRM_OPEN='CONFIRM_OPEN';
export const CONFIRM_CLOSE='CONFIRM_CLOSE';

export const SUCCESS_OPEN='SUCCESS_OPEN';
export const SUCCESS_CLOSE='SUCCESS_CLOSE';
export const ERROR_OPEN='ERROR_OPEN';
export const ERROR_CLOSE='ERROR_CLOSE';

const modalStore = {
	state: {
		success: {
			show: false,
			title: '',
			text: '',
		},
		error: {
			show: false,
			title: '',
			text: '',
		}
	},
	getters: {
		modalSuccess: state => state.success,
		modalError: state => state.error
	},
	mutations: {
		[SUCCESS_OPEN]: (state, props) => {
			state.success.show = true
			state.success.title = props.title || ''
			state.success.text = props.text || ''
		},
		[SUCCESS_CLOSE]: (state) => {
			state.success = false
		},
		[ERROR_OPEN]: (state, props) => {
			state.error.show = true
			state.error.title = props.title || ''
			state.error.text = props.text || ''
		},
		[ERROR_CLOSE]: (state) => {
			state.error = false
		}
	},
	actions: {
	},
};

export default modalStore