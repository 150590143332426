import axios from "axios";
import {USER_LOGOUT, USER_REQUEST} from "./user";
import {CONTEXT_ACTING_AS, CONTEXT_RESET} from "./context";
import {string} from "yup";

export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";


export default {
	state: {
		token: localStorage.getItem('user-token') || '',
		expires: parseInt(localStorage.getItem('user-token-expires')) || 0,
		status: '',
	},
	getters: {
		isAuthenticated: state => !!state.token,
		tokenExpires: state => state.expires,
		authStatus: state => state.status,
	},
	mutations: {
		[AUTH_REQUEST]: (state) => {
			state.status = 'loading'
		},
		[AUTH_SUCCESS]: (state, {token, expires}) => {
			state.status = 'success'
			state.token = token
			state.expires = expires
		},
		[AUTH_ERROR]: (state) => {
			state.status = 'error'
		},
		[AUTH_LOGOUT]: (state) => {
			state.status = 'logged out'
			state.token = null
		},
	},
	actions: {
		[AUTH_REQUEST]: ({commit, dispatch}, user) => {
			return new Promise((resolve, reject) => { // The Promise used for router redirect in login
				commit(AUTH_REQUEST)

				delete axios.defaults.headers.common['Acting-As']

				axios({url: `/oauth/token`, data: {
						...user,
						grant_type: 'password',
						client_id: process.env.VUE_APP_API_CLIENT_ID || 2,
						client_secret: process.env.VUE_APP_API_CLIENT_SECRET || '',
						scope: ''
					}, method: 'POST' })
					.then(resp => {
						console.log("success");
						const token = resp.data.access_token
						const expires = (new Date()).getTime() + (resp.data.expires_in * 1000)


						localStorage.setItem('user-token', token) // store the token in localstorage
						localStorage.setItem('user-token-expires', expires.toString()) // store the token in localstorage
						commit(AUTH_SUCCESS, {token, expires})

						axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

						// you have your token, now log in your user :)
						resolve()
					}).catch(err => {
						console.log("error");
						commit(AUTH_ERROR, err)
						localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
						localStorage.removeItem('user-token-expires') // if the request fails, remove any possible user token if possible
						reject(err)
					})
			})
		},
		[AUTH_LOGOUT]: ({commit, dispatch}) => {
			return new Promise((resolve, reject) => {
				commit(AUTH_LOGOUT)
				delete axios.defaults.headers.common['Authorization']

				localStorage.removeItem('user-token') // clear your user's token from localstorage
				localStorage.removeItem('user-token-expires') // clear your user's token from localstorage
				commit(CONTEXT_ACTING_AS, null)
				commit(USER_LOGOUT)
				commit(CONTEXT_RESET)

				resolve()
			});
		}
	},
	modules: {
	},
}