<template>
    <div>
        <div class="text-lg">
            <!-- Expand/collapse question button -->
            <button @click="open =!open" class="text-left w-full flex justify-between items-start text-gray-400">
                <slot name="button" v-bind="{buttonText}">
                    <span class="font-medium text-gray-700" role="checkbox"
                          :aria-checked="open"
                          tabindex="1"
                    >{{buttonText}}</span>
                </slot>

                <span class="ml-6 h-7 flex items-center">
                    <!--
                      Heroicon name: outline/chevron-down

                      Open: "-rotate-180", Closed: "rotate-0"
                    -->
                    <svg class="h-6 w-6 transform" :class="open ? '-rotate-180' : 'rotate-0'"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M19 9l-7 7-7-7"/>
                    </svg>
                </span>
            </button>
        </div>

        <transition-height :max-height="maxHeight">
            <div class="pr-12" v-show="open">
                <p class="pt-2 text-base text-gray-500">
                    <slot v-bind="this">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.
                    </slot>
                </p>
            </div>
        </transition-height>
    </div>
</template>

<script>
import TransitionSlideDown from "@/components/transitions/TransitionSlideDown";
import TransitionHeight from "@/components/transitions/TransitionHeight";
export default {
    name: "Collapsible",
    components: {TransitionHeight, TransitionSlideDown},
    props: {
        maxHeight: {default: 'max-h-16'},
        buttonText: {type:String, default: "Toggle me"}
    },
    data: () => ({
        open: false
    })
}
</script>

<style scoped>

</style>