import {dealer, webshop} from "@/js/helper/api";
import {DEALERS_COMPANY_UPDATE} from "@/store/dealers";

export const WEBSHOPS_LOADED='WEBSHOPS_LOADED'
export const WEBSHOPS_CURRENT='WEBSHOPS_CURRENT'
export const WEBSHOPS_COMPANY_UPDATE = 'WEBSHOPS_COMPANY_UPDATE'

export default {
	state: {
		webshops: [],
	},
	getters: {
		currentWebshop: (state, getters) => {
			if(getters.isWebshop) {
				return state.webshops[getters.contextId] || null
			}
			return null
		},
		currentWebshopId: (state, getters) => getters.currentWebshop?.id || null,
		webshopCompany: (state, getters) => getters.currentWebshop?.company,
		webshops: state => state.webshops
	},
	mutations: {
		[WEBSHOPS_LOADED]: (state, webshops) => {
			const indexedwebshops = {};
			for(var shop of webshops){
				indexedwebshops[shop.id] = shop
			}

			state.webshops = indexedwebshops
		},
		[WEBSHOPS_COMPANY_UPDATE]: (state, {company, webshop}) => {
			state.webshops[webshop].company = {
				...state.webshops[webshop].company,
				...company
			}
		}
	},
	actions: {
		[WEBSHOPS_COMPANY_UPDATE]: ({state, getters, commit}, data) => {
			return new Promise((resolve, reject) => {
				webshop.patch(`company/${getters.company.id}`, data).then(res => {
					commit(WEBSHOPS_COMPANY_UPDATE, {company:data, webshop:getters.currentWebshopId})

					resolve(res.data)
				}).catch(err => reject(err))
			})
		}
	}
}