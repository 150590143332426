import {createApp} from 'vue'
import App from './App.vue'
import store, {DOM_LOADED} from './store/index'
import router from './router'
import './js/bootstrap'

import './assets/css/tailwind.css'
import './assets/css/style.css'
import clickOutside from "./js/directives/clickOutside";
import mixinConsole from "@/js/mixin/mixinConsole";
import mixinUri from "@/js/mixin/mixinUri";
import {ErrorMessage as VeeErrorMessage, Field as VeeField, Form as VeeForm} from "vee-validate";
import {registerGermanYupLocale} from '@stephen-r/yup-i18n-de'
import {error} from "@/js/helper/modals";
import mixinFormatter from "@/js/mixin/mixinFormatter";
import moment from "moment";

registerGermanYupLocale()

moment.locale('DE')


const app = createApp(App)

app.component('VeeForm', VeeForm);
app.component('VeeField', VeeField);
app.component('VeeErrorMessage', VeeErrorMessage);


app.use(store)
	.use(router)
	.mount('#app')


app.mixin(mixinUri)
app.mixin(mixinConsole)
app.mixin(mixinFormatter)

app.directive('click-outside', clickOutside);



document.addEventListener('DOMContentLoaded', function(){
	console.log("DOMContentLoaded")
	store.commit(DOM_LOADED)
})

window.onunhandledrejection = function(event) {
	console.log("unerwarteter Fehler: ", event.reason)
	error('Hoppla', 'Es ist ein unbekannter Fehler aufgetreten')
}





