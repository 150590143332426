<template>
	<div>
		<default-header>
			<branches-header></branches-header>
		</default-header>
		<!-- This example requires Tailwind CSS v2.0+ -->
		<div class="flex flex-col">
			<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
					<div class="overflow-hidden">
						<table v-if="branches.length" class="min-w-full ">
							<tbody class="divide-y divide-gray-200">
								<tr v-for="branch in branches" class="pb-5">
									<td class="py-6 whitespace-nowrap">
										<div class="flex items-center">
											<div class="flex-shrink-0 h-10 w-10">
												<profile-icon :src="branchImageUrl(branch)" v-model="branch.city"/>
											</div>
											<div class="ml-4">
												<div class="text-lg font-medium">
													{{branch.city}}
												</div>
												<div class="text-md -mt-1 text-gray-500">
													{{branch.street_address}}
												</div>
											</div>
										</div>
									</td>

									<td class="px-6 py-6 whitespace-nowrap text-gray-500 text-right text-lg font-medium">
										<a v-if="userHasPermission('branches.edit')" class="cursor-pointer" @click="openEditBranch(branch)">bearbeiten</a>
									</td>
								</tr>
							</tbody>
						</table>

						<image-landing-page v-else :src="require('@/assets/images/lokale/filialen/keine_filiale.svg')">
							<template v-slot:title>Es wurde noch keine Filiale angelegt.</template>
							<template v-slot:sub-title>Klicke oben auf den Button, um eine anzulegen.</template>
						</image-landing-page>

					</div>
				</div>
			</div>
		</div>


        <slide-over  v-model="showEdit"
                     title="Filiale bearbeiten"
                     v-slot="{close}"
        >
            <Edit key="branchEdit" v-if="editBranch" @save="close" @delete="close" v-model="editBranch"></Edit>
        </slide-over>

	</div>
</template>

<script>
	import DefaultHeader from "../../../components/layout/DefaultHeader";
	import BranchesHeader from './Header'
	import {mapGetters} from 'vuex'
    import SlideOver from "../../../components/overlays/SlideOver";
    import {BRANCHES_REQUEST} from "../../../store/branches";
	import ImageLandingPage from "../../../components/general/ImageLandingPage";
	import ProfileIcon from "../../../components/general/ProfileIcon";
    import Edit from "@/views/dealer/branches/Edit";


	export default {
		name: 'BranchesIndex',
		components: {Edit, ProfileIcon, ImageLandingPage, SlideOver, DefaultHeader, BranchesHeader},
		data: () => ({
            showEdit: false,
            editBranch: null
        }),
        created() {
            this.$store.dispatch(BRANCHES_REQUEST)
        },
        methods: {
            branchImageUrl(branch) {
                if(branch.image){
                    return this.fileUri(branch.image_128x128)
                }
            },
            openEditBranch(branch){
                this.editBranch = branch
                this.showEdit = true
            }
        },
		computed: {
			...mapGetters([
				'branches',
				'userHasPermission',
			]),

		}
	}


</script>