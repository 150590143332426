<template>
    <div class="md:hidden relative z-10 flex-shrink-0 flex items-center bg-gray-100 h-12 px-4 py-4">
        <img src="@/assets/images/logo.svg" class="h-full">
        <button @click="openSidebar" class="ml-auto focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500 md:hidden">
            <span class="sr-only">Open sidebar</span>
            <!-- Heroicon name: menu-alt-2 -->
            <svg v-if="!showSidebar" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <svg v-else class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M4 6h16M4 12h16M4 18h7"/>
            </svg>

        </button>
<!--        <div class="flex-1 px-4 flex justify-between md:flex md:items-center md:justify-between">-->
<!--            <div class="px-4 flex-1 flex">-->

<!--                <router-view name="header" v-slot="{ Component }">-->
<!--                    <transition-->
<!--                            enter-active-class="ease-in duration-150 delay-150"-->
<!--                            enter-from-class="opacity-0"-->
<!--                            enter-to-class="opacity-100"-->
<!--                            leave-active-class="ease-in duration-150"-->
<!--                            leave-from-class="opacity-100"-->
<!--                            leave-to-class="opacity-0"-->
<!--                    >-->
<!--                        <component :is="Component" />-->
<!--                    </transition>-->
<!--                </router-view>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>

    import {mapGetters} from 'vuex'
    import {SIDEBAR_SHOW} from "@/store/sidebar";

    export default {
    	name: 'Header',
        computed: {
    	    ...mapGetters([
    	        'showSidebar'
            ])
        },
        methods: {
    	    openSidebar(){
    	        console.log("showSidebar")
    	        this.$store.commit(SIDEBAR_SHOW, true)
            }
        }
    }
</script>