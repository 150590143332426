<template>
    <div class="flex flex-col mt-6">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="overflow-hidden border border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col"
                                    class="w-2/3 px-3 py-3 text-left text-sm font-medium text-gray-700 uppercase tracking-wider">
                                    {{ header }}
                                </th>
                                <th scope="col"
                                    class="w-1/3 px-6 py-3 text-left text-sm font-medium text-gray-700 uppercase tracking-wider">
                                    {{col1}}
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr>
                                <td class="px-3 py-4 whitespace-nowrap text-lg text-gray-800">
                                    <div class="flex-shrink-0 inline">
                                        <svg class="h-5 w-5 text-orange-300 inline"
                                             xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                             aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                                                  clip-rule="evenodd"/>
                                        </svg>
                                    </div>
                                    <div class="inline">
                                        Heute
                                    </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-base text-gray-800">
                                    {{ formatNumber(today) }}
                                </td>
                            </tr>
                            <tr>
                                <td class="px-3 py-4 whitespace-nowrap text-lg text-gray-800">
                                    <div class="flex-shrink-0 inline">
                                        <svg class="h-5 w-5 text-orange-300 inline"
                                             xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                             aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                  d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z"
                                                  clip-rule="evenodd"/>
                                        </svg>
                                    </div>
                                    <div class="inline">
                                        Woche
                                    </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-base text-gray-800">
                                    {{ formatNumber(week) }}
                                </td>
                            </tr>
                            <tr>
                                <td class="px-3 py-4 whitespace-nowrap text-lg text-gray-800">
                                    <div class="flex-shrink-0 inline">
                                        <svg class="h-5 w-5 text-orange-300 inline"
                                             xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                             aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                                  clip-rule="evenodd"/>
                                        </svg>
                                    </div>
                                    <div class="inline">
                                        Monat
                                    </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-base text-gray-800">
                                    {{formatNumber(month)}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        col1: {type:String, default: 'KUNDEN'},
        header: {type:String, required: true},
        today: {type:Number, required: true},
        week: {type:Number, required: true},
        month: {type:Number, required: true},
    },
    name: "TableStats"
}
</script>

<style scoped>

</style>