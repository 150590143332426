<template>
	<transition
			enter-active-class="transform ease-out duration-300 transition"
			enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-full"
			enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
			leave-active-class="transform ease-out duration-300 transition"
			leave-from-class="translate-y-0 opacity-100 sm:translate-x-0"
			leave-to-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-full"
	>
		<div v-show="modelValue"
			 class="max-w-sm w-full mb-2 bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
			<div class="p-4">
				<div class="flex items-start">
					<div class="flex-shrink-0">
						<!-- Heroicon name: outline/check-circle -->
						<slot name="icon">
							<svg class="h-6 w-6 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
									d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
							</svg>
						</slot>
					</div>
					<div class="ml-3 w-0 flex-1 pt-0.5">
						<p class="text-sm font-medium text-gray-900">
							{{compTitle}}
						</p>
						<p class="mt-1 text-sm text-gray-500">
							{{compText}}
						</p>
					</div>
					<div class="ml-4 flex-shrink-0 flex">
						<button @click="close"
								class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
							<span class="sr-only">Close</span>
							<!-- Heroicon name: solid/x -->
							<svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
								<path fill-rule="evenodd"
									  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									  clip-rule="evenodd"/>
							</svg>
						</button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	export default {
		name: "NotificationBasic",
		emits: ['update:modelValue', 'close', 'open'],
		props: {
			modelValue: {type: Boolean, required: true},
			title: {type: String},
			text: {type: String},
			titleDefault: {type: String, default: "Erfolgreich"},
			textDefault: {type: String, default: "Aktion erfolgreich ausgeführt"},
			closeDelay: {type: Number, default: 5000}
		},
		methods: {
			close() {
				this.$emit('close')
				this.$emit('update:modelValue', false)
			}
		},
		computed: {
			compTitle(){
				return (!this.title || !this.title.length) ? this.titleDefault : this.title
			},
			compText(){
				return (!this.text || !this.text.length) ? this.textDefault : this.text
			}
		},
		watch: {
			modelValue(newVal, oldVal){
				if(newVal){
					this.$emit('open')
					window.setTimeout(() => {
						this.close()
					}, this.closeDelay)
				}
			}
		}
	}
</script>

<style scoped>

</style>