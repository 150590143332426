<template>
    <div>
        <default-header/>

        <div>
            <table-default entity-name="Account"
                           entity-name-plural="Accounts"
                           search-placeholder="Account suchen"
                           :per-page="10"
                           :columns="columns"
                           :filter-field="['company', 'email']"
                           :rows="accounts">
                <template v-slot:company="{classes, entity, value}">
                    <td :class="classes.td" class="flex items-center">
                        <svg v-if="isAdmin(entity)" class="absolute w-6 h-6 text-orange-300"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clip-rule="evenodd"/>
                        </svg>
                        <span class="ml-8">{{ value || '' }}</span>
                    </td>
                </template>
                <template v-slot:action="{classes, entity}">
                    <td :class="classes.td" class="text-right">
                        <a class="text-gray-500 hover:text-orange-400 cursor-pointer"
                           @click="openEdit(entity)">Bearbeiten</a>
                    </td>
                </template>
            </table-default>
        </div>

        <slide-over title="Account bearbeiten" :sub-title="edit.account?.email" v-model="sidebar">
            <button-secondary v-if="!isSuperuser" class="mb-8" title="Anmelden als" @click="loginAs">
                <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                     fill="currentColor">
                    <path fill-rule="evenodd"
                          d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z"
                          clip-rule="evenodd"/>
                </svg>
            </button-secondary>

            <div class="flex w-100 mb-8 justify-items-center">
                <form-select class="flex-grow mr-2" :options="stateOptions"
                             v-model:selected="edit.state.selected"/>
                <button-save :loading="edit.loading" @click="changeState()"/>
            </div>

            <div class="flex">
                <span @click="confirmDelete = true" class="text-gray-600 underline text-sm cursor-pointer" >Account löschen</span>
                <modal-confirm v-model="confirmDelete"
                               confirm-text="Löschen"
                               title="Account löschen?"
                               @confirm="onConfirmDelete">

                    Möchtest du den Account wirklich löschen?
                </modal-confirm>
            </div>
        </slide-over>

    </div>
</template>

<script>
import {stopLoading, success} from "@/js/helper/modals";
import DefaultHeader from "@/components/layout/DefaultHeader";
import TableDefault from "@/components/tables/TableDefault";
import {ACCOUNTS_DELETE, ACCOUNTS_EDIT, ACCOUNTS_REQUEST} from "@/store/accounts";
import {mapGetters} from "vuex";
import SlideOver from "@/components/overlays/SlideOver";
import ButtonSecondary from "@/components/buttons/ButtonSecondary";
import FormSelect from "@/components/forms/FormSelect";
import ButtonSave from "@/components/buttons/ButtonSave";
import {su} from "@/js/helper/api";
import {CONTEXT_ACTING_AS, CONTEXT_DEALER, CONTEXT_SU, CONTEXT_WEBSHOP} from "@/store/context";
import ModalConfirm from "@/components/modals/ModalConfirm";


const ACTIVE = 'aktiv'
const INACTIVE = 'inaktiv'
const ADMIN = 'admin'

export default {
    name: "Index",
    components: {ModalConfirm, ButtonSave, FormSelect, ButtonSecondary, SlideOver, TableDefault, DefaultHeader},
    data: () => ({
        columns: ['company', 'email', 'action'],
        sidebar: false,
        confirmDelete: false,
        edit: {
            account: null,
            loading: false,
            state: {
                options: [ACTIVE, INACTIVE],
                selected: []
            }
        }
    }),
    created() {
        this.$store.dispatch(ACCOUNTS_REQUEST).finally(() => {
            stopLoading()
        })
    },
    methods: {
        getActiveState() {
            if(this.edit.account?.active) {
                if(this.edit.account?.is_admin) {
                    return ADMIN
                }
                else {
                    return ACTIVE
                }
            }
            else {
                return INACTIVE
            }
        },
        openEdit(entity) {
            this.edit.account = entity
            this.edit.state.selected = [this.getActiveState()]
            this.sidebar = true
        },
        closeEdit() {
            this.sidebar = false
        },
        isAdmin(entity) {
            return entity.active && (entity.context == CONTEXT_WEBSHOP || (entity.context == CONTEXT_DEALER && entity.is_admin))
        },
        changeState() {
            this.edit.loading = true

            const selected = this.edit.state.selected
            let active = selected[ 0 ] === ACTIVE || selected[ 0 ] === ADMIN ? 1 : 0
            let is_admin = selected[ 0 ] === ADMIN ? 1 : 0
            su.patch(`account/${this.edit.account.id}`, {
                active,
                is_admin
            }).then(res => {
                this.$store.commit(ACCOUNTS_EDIT, {
                    id: this.edit.account.id,
                    active,
                    is_admin
                })
                success(null, "Der Account wurde erfolgreich bearbeitet")

                this.edit.loading = false
                this.closeEdit()
            })
        },
        onConfirmDelete() {
            this.$store.dispatch(ACCOUNTS_DELETE, this.edit.account).then(res => {
                success(null, 'Account wurde erfolgreich gelöscht')
                this.closeEdit()
            })
        },
        loginAs(){
            su.post(`account/authenticate/${this.edit.account.id}`).then(res => {
                this.$store.dispatch(CONTEXT_ACTING_AS, this.edit.account.id).then(res => {
                    const name = this.edit.account.name
                    success(null, `Du wurdest als '${name}' angemeldet`)
                    this.$router.replace('/')
                })
            })
        }
    },
    computed: {
        ...mapGetters([
            'accounts'
        ]),
        isSuperuser(){
            return this.edit.account?.context == CONTEXT_SU
        },
        stateOptions() {
            let options = this.edit.state.options
            if(this.edit.account?.context == CONTEXT_DEALER) {
                options = [ADMIN, ...options]
            }

            return options
        }
    }
}
</script>

<style scoped>

</style>