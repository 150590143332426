<template>
	<div class="flex-grow flex w-100">
        <template v-if="!!isBranchSelected">
            <div class="flex-grow">
                <BranchSelect></BranchSelect>
            </div>
            <div class="flex-grow-0">
                <div class="flex flex-column space-x-2">
                    <button-primary v-if="canEdit && !selectedBranchFeedMode" @click="addManual=true">
                        <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" stroke="none">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
                        </svg>
                        Produkte
                    </button-primary>

                    <button-primary v-if="canEdit && selectedBranchFeedMode" @click="addFeed=true">
                        <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" stroke="none">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
                        </svg>
                        Feed
                    </button-primary>

                    <dropdown-button-dots v-if="canEdit">
                        <a @click="switchFeedMode=true" :class="['text-gray-700 cursor-pointer', 'block px-4 py-3 text-sm']">{{toggleFeedModeLabel}}</a>
                    </dropdown-button-dots>
                </div>
            </div>


            <slide-over v-model="addManual"
                        title="Produkte hinzufügen"
                        sub-title="Du hast zwei Möglichkeiten Produkte anzulegen"
                        v-slot="{close}"
            >
                <product-add @added="onAdded(close)" ></product-add>
            </slide-over>


            <slide-over v-model="addFeed"
                        title="Produktdatenfeed"
                        sub-title="Füge die URL zu deiner CSV-Datei ein"
                        v-slot="{close}"
            >
                <feed-add @added="onAdded(close)"></feed-add>
            </slide-over>
        </template>
	</div>

    <modal-confirm v-model="switchFeedMode" :title="`Modus-Wechsel`"
                   @confirm="toggleSwitchMode"
                    confirm-text="Wechseln">
        <div class="text-left">
            Bist du sicher, dass du in einen anderen Modus wechseln möchtest? Alle vorhandenen EANs werden in dieser Filiale gelöscht.
        </div>
    </modal-confirm>
</template>

<script>
	import ProductAdd from "./Add";
	import SlideOver from "../../../components/overlays/SlideOver";
	import BranchSelect from "../../../components/branches/BranchSelect";
    import {
        BRANCHES_PRODUCTS_REQUEST,
        BRANCHES_PRODUCTS_UPDATE, BRANCHES_REQUEST, BRANCHES_SELECT,
        BRANCHES_SWITCH_FEED_MODE
    } from "../../../store/branches";
	import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
    import {mapGetters} from "vuex";
    import ButtonDots from "@/components/buttons/ButtonDots";
    import DropdownButtonDots from "@/components/dropdowns/DropdownButtonDots";
    import {startLoading, stopLoading, success} from "@/js/helper/modals";
    import ModalConfirm from "@/components/modals/ModalConfirm";
    import FeedAdd from "@/views/dealer/products/FeedAdd";
	export default {
		name: "Header",
		components: {
            FeedAdd,
            ModalConfirm,
            DropdownButtonDots, ButtonDots, ButtonPrimary, BranchSelect, ProductAdd, SlideOver},
		data: () => ({
			addManual: false,
            addFeed: false,
            switchFeedMode: false,
		}),
        computed: {
		    ...mapGetters([
		        'userHasPermission',
		        'isBranchSelected',
		        'selectedBranch',
                'selectedBranchFeedMode'
            ]),
		    canEdit(){
		        return this.userHasPermission('products.edit')
            },
            toggleFeedModeLabel(){
		        if(this.selectedBranchFeedMode){
		            return 'In Manuellen-Modus wechseln'
                } else {
		            return 'In Feed-Modus wechseln'
                }
            }
        },
		methods: {
			onAdded(close){
				close()
			},
            toggleSwitchMode(){
                startLoading();
                const oldMode = this.selectedBranchFeedMode
                this.$store.dispatch(BRANCHES_SWITCH_FEED_MODE, oldMode ? 0 : 1).then(() => {
                    this.$store.dispatch(BRANCHES_REQUEST).then(res => {
                        const branch = res.find(branch => branch.id == this.selectedBranch.id)
                        this.$store.dispatch(BRANCHES_SELECT, branch).then(res => {
                            stopLoading()
                            success('Modus-Wechsel', `Du befindest dich nun im ${oldMode ? 'Manuellen-Modus' : 'Feed-Modus'}`)
                        })
                    });
                })
            },

		}
	}
</script>

<style scoped>

</style>