<template>
	<transition
		enter-active-class="ease-out duration-200"
		enter-from-class="opacity-0"
		enter-to-class="opacity-100"
		leave-active-class="ease-in duration-200"
		leave-from-class="opacity-100"
		leave-to-class="opacity-0"
	>
		<div v-show="!!modelValue" class="fixed z-10 inset-0 overflow-y-auto">
			<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
				<div class="fixed inset-0 transition-opacity" aria-hidden="true">
					<div class="absolute inset-0 bg-gray-500 opacity-75"></div>
				</div>

				<!-- This element is to trick the browser into centering the modal contents. -->
				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
				<div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
					<div class="sm:flex sm:items-start">
						<div class="mx-auto absolute flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">

							<svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
								<slot name="icon-path">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
								</slot>
							</svg>
						</div>
						<div class="mt-3 px-6 w-full self-stretch text-center sm:mt-0 sm:ml-4 sm:text-left">
							<h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
								{{title}}
							</h3>
							<div class="mt-2">
								<p class="text-sm text-gray-500">
									<slot v-bind="{modelValue}">
										Are you sure you want to deactivate your account? All of your data will be permanently removed from our servers forever. This action cannot be undone.
									</slot>
								</p>
							</div>
						</div>
					</div>
					<div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
						<button ref="confirm" @click="confirm" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
							{{confirmText}}
						</button>
						<button @click="cancel" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
							{{cancelText}}
						</button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	import {nextTick} from 'vue'

	export default {
		name: "ModalConfirm",
		emits: ['update:modelValue', 'confirm', 'cancel', 'close'],
		props: {
			modelValue: { required: true },
			cancelText: { type: String, default: "Abbrechen" },
			confirmText: { type: String, default: "Bestätigen" },
			title: { type: String, default: "Sind Sie sicher?" },
			autoClose: { type: Boolean, default: true },
		},
		methods: {
			close(){
				this.$emit('close');
				if(this.autoClose){
					this.$emit('update:modelValue', false);
				}
			},
			confirm(){
				this.$emit('confirm');
				this.close()
			},
			cancel(){
				this.$emit('cancel');
				this.close()
			}
		},
		watch: {
			modelValue(newVal, oldVal){
				if(newVal){
					nextTick(() => {
						this.$refs.confirm.focus()
					})
				}
			}
		}
	}
</script>

<style scoped>

</style>