import axios from "axios";
import {AUTH_LOGOUT} from "../store/auth";
import router from "../router/index";
import store from '../store/index'
import {error} from "@/js/helper/modals";
import {ACTING_AS} from "@/store/context";


const logOutAndRedirect = () => {
	if(store.getters.isAuthenticated){
		// if you ever get an unauthorized, logout the user
		store.dispatch(AUTH_LOGOUT)
		if(window.history.state.back) {
			error("Keine Berechtigung", 'Du wurdest abgemeldet')
		}
	}

	// you can also redirect to /login if needed !
	router.replace({name: 'Login'})
}


axios.interceptors.response.use((res) => {
	return res;
}, (err) => {
	return new Promise((resolve, reject) => {
		// console.log('interceptor error', err);
		if (router.currentRoute.value.name != 'Login' && ( err.response?.status === 400 || err.response?.status === 401) ) {
			logOutAndRedirect()
		} else {
			reject(err)
		}
	})
})


const token = localStorage.getItem('user-token')

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL || ''
if (token) {
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}


axios.interceptors.request.use((config) => {

	return new Promise((resolve, reject) => {
		const now = (new Date()).getTime()
		const expires = store.getters.tokenExpires
		if (router.currentRoute.value.name != 'Login' ) {
			if(now < expires) {
				resolve(config)
			} else {
				logOutAndRedirect()
			}
		} else {
			resolve(config)
		}
	})
});



