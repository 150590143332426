<template>
	<button type="button"
			class="disabled:opacity-50 disabled:bg-white relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
	>
        <div v-if="loading" style="border-top-color:transparent" class="mr-2 border-solid animate-spin  rounded-full border-gray-700 border-2 h-4 w-4"></div>
		<slot v-else>
			<svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
			</svg>
        </slot>
        {{title}}
	</button>
</template>

<script>
	export default {
		name: "ButtonSecondary",
        props: {
		    title: {required: true, type: String},
            loading: {default: false}
        }
	}
</script>

<style scoped>

</style>