<template>
	<div>
		<vee-field v-bind="$attrs"
				   class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
		<vee-error-message class="mt-2 text-sm text-red-600"
						   :name="$attrs.name"></vee-error-message>
	</div>
</template>

<script>
	export default {
		name: "VeeInput",
		inheritAttrs: false
	}
</script>

<style scoped>

</style>