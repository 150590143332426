<template>
    <div>
        <vee-form v-slot="{handleSubmit}" :validation-schema="schema">
            <form @submit="handleSubmit($event, submit)">
                <div>
                    <div class="mt-1 relative rounded-md shadow-sm">
                        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 5c7.18 0 13 5.82 13 13M6 11a7 7 0 017 7m-6 0a1 1 0 11-2 0 1 1 0 012 0z" />
                            </svg>
                        </div>
                        <vee-field type="text"
                                   name="url"
                                   class="focus:ring-orange-500 focus:border-orange-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                   placeholder="https://deine-domain.com/feed.csv" />
                    </div>

                    <vee-error-message class="mt-2 text-sm text-red-600"
                                       name="url" />
                </div>

                <button-loading text="Import starten" type="submit" class="float-right mt-4" :loading="loading" >
                    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                </button-loading>

                <!--            <button-primary class="float-right mt-4" type="submit">-->
                <!--                <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"-->
                <!--                     stroke="currentColor">-->
                <!--                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"-->
                <!--                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>-->
                <!--                </svg>-->
                <!--                Import starten-->
                <!--            </button-primary>-->
            </form>

        </vee-form>
    </div>
</template>

<script>
import VeeInput from "@/components/forms/VeeInput";
import ButtonSave from "@/components/buttons/ButtonSave";
import ButtonPrimary from "@/components/buttons/ButtonPrimary";
import * as yup from 'yup';
import {BRANCHES_ADD_FEED, BRANCHES_PRODUCTS_REQUEST} from "@/store/branches";
import ButtonLoading from "@/components/buttons/ButtonLoading";
import {mapGetters} from "vuex";
import {dealer, su} from "@/js/helper/api";
import {success} from "@/js/helper/modals";

export default {
    name: "FeedAdd",
    components: {ButtonLoading, ButtonPrimary, ButtonSave, VeeInput},
    emits: ['added'],
    setup: () => {
        let schema = yup.object().shape({
            url: yup.string().required("Du musst eine URL deines Feeds angeben")
                .url("Deine Eingabe entspricht keiner validen URL")
        })

        return {
            schema
        }
    },
    data: () => ({
        loading: false,
        submittime: null
    }),
    methods: {
        submit(data, {resetForm}){
            this.submittime = (new Date()).getTime()
            this.loading = true
            this.$store.dispatch(BRANCHES_ADD_FEED, data).then(res => {
                this.$emit('added')
                this.loading = false
                resetForm()

                success("Feed-Import gestartet", "Der Feed wurde erfolgreich angelegt und im Anschluss gestartet. Die Seite aktualisiert sich automatisch, sobald der Import abgeschlossen ist")

                const feedRequest = (time) => {
                    window.setTimeout(() => {
                        if(time === this.submittime){
                            dealer.get(`branch/${this.selectedBranch.id}/feed/${res.data.id}`).then(feed => {
                                if(!feed.data.error && !feed.data.imported_at){
                                    feedRequest(time);
                                } else {
                                    success("Feed-Import", "Import erfolgreich abgeschlossen")
                                    this.$store.commit(BRANCHES_ADD_FEED, feed.data)
                                }
                            });
                        }
                    }, 2000)
                }

                feedRequest(this.submittime);
            });
        }
    },
    computed: {
        ...mapGetters([
            'selectedBranch'
        ])
    }
}
</script>

<style scoped>

</style>