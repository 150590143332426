<template>
	<transition
			enter-active-class="ease-in-out duration-500"
			enter-from-class="opacity-0"
			enter-to-class="opacity-100"
			leave-active-class="ease-in-out duration-500"
			leave-from-class="opacity-100"
			leave-to-class="opacity-0"
	>
		<div v-show="!!modelValue" class="z-20 fixed inset-0 overflow-hidden">
			<div class="absolute inset-0 overflow-hidden">
				<div @click.self="close" class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
				<transition
					enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
					enter-from-class="translate-x-full"
					enter-to-class="translate-x-0"
					leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
					leave-from-class="translate-x-0"
					leave-to-class="translate-x-full"
				>
					<section v-show="!!modelValue" class="absolute inset-y-0 right-0 pl-0 max-w-full flex" aria-labelledby="slide-over-heading">
                        <div @click.self="close" class="flex-shrink-0 w-10" aria-hidden="true"><!-- Dummy element to force sidebar to shrink to fit close icon --></div>
                        <div class="relative w-screen max-w-md">
							<div  class="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
								<button @click.prevent="close" class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
									<span class="sr-only">Schließen</span>
									<!-- Heroicon name: outline/x -->
									<svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
									</svg>
								</button>
							</div>
							<div class="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
								<div class="px-4 sm:px-6">
									<h2 id="slide-over-heading" class="text-lg font-medium">
										{{title}}
									</h2>
									<span class="text-gray-600">{{subTitle}}</span>
								</div>
								<div class="mt-6 relative flex-1 px-4 sm:px-6">
									<slot v-bind="{close}"></slot>
								</div>
							</div>

						</div>
					</section>
				</transition>
			</div>

		</div>
	</transition>
</template>

<script>
	export default {
		name: "SlideOver",
		emits: ['update:modelValue', 'close', 'open'],
		props: {
			modelValue: {},
			title: {type: String, required: true},
			subTitle: String
		},
		methods: {
			close(){
				this.$emit('update:modelValue', false)
			}
		},
		watch:{
			modelValue(newValue, oldValue){
				if(newValue){
					this.$emit('open')
				} else if(oldValue){
					this.$emit('close')
				}
			}
		}
	}
</script>

<style scoped>

</style>