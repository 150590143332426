<template>
	<img v-if="!!src"
		 :class="{
			[size]: !!size,
			'w-10 h-10': !size,
		 }"
		 class="rounded-full"
		 :src="src"
		 alt="">
	<div v-else-if="modelValue"
		 :class="{
			[size]: !!size,
			'w-10 h-10': !size,
			[backgroundColor]: !!backgroundColor,
			'bg-gradient-to-b from-gray-400 to-gray-500': !backgroundColor
		 }"
		 class="text-white font-bold uppercase rounded-full flex flex-row justify-center items-center" >
		<span>{{alias()}}</span>
	</div>
</template>

<script>
	export default {
		name: "ProfileIcon",
		emits: ['update:modelValue'],
		props: {
			modelValue: {type: String, required: false},
			src: {required:false},
			backgroundColor: {type:String, required:false},
			size: {type:String, required:false},
		},
		methods: {
			alias(){
				let index = this.modelValue.lastIndexOf(' ');
				index = index >= 0 ? index : 0
				let last = this.modelValue.slice(index+1,index+2)
				let first = this.modelValue.slice(0,1)

				return `${first}${last}`
			}
		}
	}
</script>

<style scoped>

</style>