<template>
    
    <div>
        
    <div class="grid grid-cols-6 w-full max-w-7xl px-4 mx-auto py-4">
        <div class="col-span-3">
            <img src="@/assets/images/homepage/onlineshops.png" class="top-0 md:top-16 relative max-w-md">
        </div>
        <div class="col-span-6 md:col-span-3 p-8 md:p-0 flex flex-col justify-center">
            <h3 class="mb-2 md:mb-3 text-1xl leading-6 font-medium text-gray-400">TEILE DEINER BESUCHER WERDEN OFFLINE KAUFEN</h3>
            <h2 class="mb-2 md:mb-3 text-3xl leading-8 font-bold text-gray-900">Verdiene an Nutzern, die sich nur informieren</h2>
            
        </div>
       
    </div>
    
    
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="py-36 bg-white">
      <div class="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <dl class="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <dt>
              <div class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-400 text-white">
                <!-- Heroicon name: outline/globe-alt -->
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
              </div>
              <p class="mt-5 text-lg leading-6 font-medium text-gray-900">Monetarisierung</p>
            </dt>
            <dd class="mt-2 text-base text-gray-500">
              Setze ungeahntes Potential frei, indem du bisher ungenutzten Traffic nicht weiter verschwendest.
            </dd>
          </div>
    
          <div>
            <dt>
              <div class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-400 text-white">
                <!-- Heroicon name: outline/scale -->
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                 </svg>
              </div>
              <p class="mt-5 text-lg leading-6 font-medium text-gray-900">Einfache Integration</p>
            </dt>
            <dd class="mt-2 text-base text-gray-500">
              Shop-Integration per Tag-Manager. Mehr nicht. Wirklich.
            </dd>
          </div>
    
          <div>
            <dt>
              <div class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-400 text-white">
                <!-- Heroicon name: outline/lightning-bolt -->
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5" />
                </svg>
              </div>
              <p class="mt-5 text-lg leading-6 font-medium text-gray-900">Kundenerlebnis</p>
            </dt>
            <dd class="mt-2 text-base text-gray-500">
              Mehr Zufriedenheit durch zusätzliche Kompetenz.
            </dd>
          </div>
        </dl>
      </div>
    </div>
    
    
    
    
    
    
    
    
    <p class="text-xs px-16 py-8 text-gray-400 text-center">Getfrom powered by: Benz GmbH & Co. KG Baustoffe, Auwiesen 4, 74924 Neckarbischofsheim, Deutschland, Telefon: 07263 / 60 541-30, E-Mail: help@getfrom.com, USt-IdNr.: DE144334468, eingetragen im Handelsregister des Amtsgerichtes Mannheim, Handelsregisternummer HRA 340361, vertreten durch die persönlich haftende Gesellschafterin, Benz Baustoffe Verwaltungs GmbH, diese vertreten durch den Geschäftsführer Roland Benz, Nina Schieck, Christian Schieck, Auwiesen 4, 74924 Neckarbischofsheim, eingetragen im Handelsregister des Amtsgerichtes Mannheim, Handelsregisternummer HRB 341191</p>
    
    
    
</div>
    
    


    
    
   
    
    
    
</template>

<script>
export default {
name: "Index"
}
</script>

<style scoped>

</style>