<template>
    <div class="md:grid md:grid-cols-8 md:gap-6">
        <div class="md:col-span-2">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Profil</h3>
            <p class="mt-1 text-sm text-gray-500">
            </p>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6">
                    <label class="block text-sm font-medium text-gray-700">Name</label>
                    <vee-input v-model="modelValue.name"
                               name="user.name"
                               type="text"
                               autocomplete="given-name" />
                </div>

                <div class="col-span-6">
                    <label  class="block text-sm font-medium text-gray-700">E-Mail</label>
                    <vee-input v-model="modelValue.email"
                               name="user.email"
                               type="email"
                               autocomplete="email"
                               class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                </div>

                <div class="col-span-6">
                    <label  class="block text-sm font-medium text-gray-700">Passwort</label>
                    <vee-input v-model="modelValue.password1"
                               name="user.password1"
                               placeholder="Neues Passwort eingeben"
                               type="password"
                               class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                </div>

                <div class="col-span-6">
                    <label  class="block text-sm font-medium text-gray-700">Passwort wiederholen</label>
                    <vee-input v-model="modelValue.password2"
                               name="user.password2"
                               placeholder="Neues Passwort wiederholen"
                               type="password"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VeeInput from "@/components/forms/VeeInput";

    export default {
        name: "AccountProfile",
        components: {VeeInput},
        emits: ['update:modelValue'],
        props: {
            modelValue: {required:true}
        }
    }
</script>

<style scoped>

</style>