<template>
    <div class="relative">
        <button-dots @click="toggle" v-click-outside="hide" />
        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <div v-if="showMenu" class="origin-top-right absolute right-0 mt-2 w-auto whitespace-nowrap rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <slot>
                    <a :class="['text-gray-700', 'block px-4 py-3 text-sm']">Test</a>
                </slot>
            </div>
        </transition>
    </div>
</template>

<script>
import ButtonDots from "@/components/buttons/ButtonDots";
export default {
    name: "DropdownButtonDots",
    components: {ButtonDots},
    data: () => ({
        showMenu: false
    }),
    methods: {
        show(){
            this.showMenu = true;
        },
        hide(){
            this.showMenu = false;
        },
        toggle(){
            this.showMenu = !this.showMenu;
        }
    }
}
</script>

<style scoped>

</style>