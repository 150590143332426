<template>
    <div v-if="figures">
        <default-header></default-header>
        <div class="relative pb-20">
            <div class="relative max-w-9xl mx-auto">
                <div class=" max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                    <info-image-widget
                        :src="require('@/assets/images/onlineshops/kunden/gesamt.svg')"
                        :primary-text="formatNumber(figures.total.year)"
                        :secondary-text="`KUNDEN SEIT ${ figures.first_date }`"/>

                    <info-image-widget
                        :src="require('@/assets/images/onlineshops/kunden/woche.svg')"
                        :primary-text="formatNumber(figures.avg_week)"
                        secondary-text="DURCHSCHNITT PRO WOCHE"/>

                    <info-image-widget
                        :src="require('@/assets/images/onlineshops/kunden/record.svg')"
                        :primary-text="formatNumber(figures.record.value)"
                        :secondary-text="`REKORD AM ${ figures.record.date }`"/>
                </div>
            </div>

            <table-stats v-if="figuresForCurrentWebshop"
                         col1="Leads"
                         :header="`${currentWebshop.domain}`"
                         :month="figures.webshop[currentWebshop.id].month"
                         :week="figures.webshop[currentWebshop.id].week"
                         :today="figures.webshop[currentWebshop.id].today"/>
        </div>
    </div>
</template>

<script>
import DefaultHeader from "../../../components/layout/DefaultHeader";
import {webshop, dealer} from "@/js/helper/api";
import {mapGetters} from "vuex";
import {stopLoading} from "@/js/helper/modals";
import TableStats from "@/components/tables/TableStats";
import InfoImageWidget from "@/components/general/InfoImageWidget";

export default {
    name: 'CustomersIndex',
    components: {InfoImageWidget, TableStats, DefaultHeader},
    data: () => ({
        figures: null
    }),
    created() {
        this.fetchData()
    },
    computed: {
        ...mapGetters([
            'userHasPermission',
            'currentWebshop'
        ]),
        figuresForCurrentWebshop() {
            return this.currentWebshop && this.figures.webshop[this.currentWebshop.id]
        }
    },
    methods: {
        fetchData() {
            webshop.get('/provision/dashboard').then(res => {
                if(res.data) {
                    this.figures = res.data
                }
            }).finally(() => {
                stopLoading()
            })
        }
    }
}
</script>