<template>
	<div>
		<default-header></default-header>
		<vee-form ref="form" @submit="submit" :validation-schema="schema" v-slot="{handleSubmit}">
			<div class="space-y-12">

                <account-profile v-model="user" />

				<div v-if="userHasPermission('company.edit')" class="md:grid md:grid-cols-8 md:gap-6">
					<div class="md:col-span-2">
						<h3 class="text-lg font-medium leading-6 text-gray-900">Unternehmen</h3>
						<p class="mt-1 text-sm text-gray-500">
						</p>
					</div>
					<div class="mt-5 md:mt-0 md:col-span-2">
						<div class="grid grid-cols-6 gap-6">
							<div class="col-span-6">
								<label class="block text-sm font-medium text-gray-700">Name</label>
								<vee-input v-model="company.name"
										type="text"
										name="company.name"
										autocomplete="company.id" />
							</div>

							<div class="col-span-6">
								<label class="block text-sm font-medium text-gray-700">Straße Nr.</label>
								<vee-input v-model="company.street_address"
										type="text"
										name="company.street_address"
										autocomplete="company.street_address" />
							</div>

							<div class="col-span-2">
								<label class="block text-sm font-medium text-gray-700">PLZ</label>
								<vee-input v-model="company.zip"
										type="text"
										name="company.zip"
										id="company.zip" />
							</div>

							<div class="col-span-4">
								<label class="block text-sm font-medium text-gray-700">Ort</label>
								<vee-input v-model="company.city"
										   type="text"
										   name="company.city"
										   id="company.city" />
							</div>

							<div class="col-span-6">
								<label class="block text-sm font-medium text-gray-700">Ort</label>
								<vee-field name="company.country" v-slot="{field}">
									<form-select v-bind="field" :options="countries" v-model:selected="company.country" >
										<template v-slot:button="{selectedOption}">
											<span class="ml-3 block truncate">{{selectedOption.label}}</span>
										</template>
										<template v-slot:item="{option,isSelected}">
											<span :class="{'font-semibold': isSelected}" class="ml-3 block font-normal truncate">{{option.label}}</span>
										</template>
									</form-select>
								</vee-field>
							</div>
						</div>
					</div>
				</div>

				<div class="md:grid md:grid-cols-8 md:gap-6">
					<div class="col-span-4 text-right">
                        <button-save :loading="submitting" type="submit"></button-save>
					</div>
				</div>
			</div>
		</vee-form>
	</div>
</template>

<script>
	import DefaultHeader from "../../components/layout/DefaultHeader";
	import {mapGetters} from 'vuex'
	import FormSelect from "../../components/forms/FormSelect";
	import ButtonPrimary from "../../components/buttons/ButtonPrimary";

	import * as yup from 'yup'
	import VeeInput from "../../components/forms/VeeInput";
	import {USER_UPDATE} from "../../store/user";
	import {DEALERS_COMPANY_UPDATE} from "../../store/dealers";
	import {success} from "../../js/helper/modals";
    import {WEBSHOPS_COMPANY_UPDATE} from "@/store/webshops";
    import ButtonSave from "@/components/buttons/ButtonSave";
    import AccountProfile from "@/components/account/AccountProfile";

	export default {
		name: "Index",
		components: {AccountProfile, ButtonSave, VeeInput, ButtonPrimary, FormSelect, DefaultHeader},
		data: () => ({
			user: {},
			company: {},
            submitting: false
		}),
		setup(){
			const countries = [
				{iso: 'DE', label: 'Deutschland'}
			];

			const schema = yup.object({
				user: yup.object({
					name: yup.string().required().label('Name'),
					email: yup.string().required().email().label('E-Mail'),
					password1: yup.string().test('password1', 'Das Passwort muss mindestens 8 Zeichen lang sein', function(value){
						if(!!value){
							return yup.string().min(8).isValidSync(value)
						}

						return true
					}),
					password2: yup.string().test('user.password2', 'Die Passwörter müssen übereinstimmen', function(value){
						return value === this.parent.password1
					}),
				}),
				company: yup.object({
					name: yup.string().required().label('Name'),
					street_address: yup.string().required().label('Straße'),
					zip: yup.string().min(4).max(5).required().label('Postleitzahl'),
					city: yup.string().required().label('Ort'),
				})
			})

			return {
				countries,
				schema
			}
		},
		created(){
			this.user = {...this.currentUser, password1:'', password2:''}

			let country = this.countries.filter(value => value.iso == this.currentCompany.country)
			this.company = {...this.currentCompany, country}
		},
		computed: {
			...mapGetters({
				currentUser: 'user',
				userHasPermission: 'userHasPermission',
				currentCompany: 'company'
			}),
		},
		methods: {
			submit(data){
			    this.submitting = true
				data.company.country = this.company.country[0].iso
				if(!data.user.password1.length){
					delete data.user.password1
					delete data.user.password2
				}

                let companyProm
                if(this.$store.getters.isDealer){
                    companyProm = this.$store.dispatch(DEALERS_COMPANY_UPDATE, data.company)
                } else if(this.$store.getters.isWebshop){
                    companyProm = this.$store.dispatch(WEBSHOPS_COMPANY_UPDATE, data.company)
                }

				Promise.all([
					this.$store.dispatch(USER_UPDATE, data.user),
                    companyProm
				]).then(res => {
					success(null, 'Der Account wurde erfolgreich aktualisiert')
                    this.$refs.form.resetForm({
                        values: {
                            company: this.company,
                            user: this.user
                        }
                    })
				}).finally(() => {
                    this.submitting = false
                })
			}
		}
	}
</script>

<style scoped>

</style>