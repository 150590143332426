<template>
    <div>
        <default-header/>

        <div v-if="figures" class="relative pb-0 space-y-10">
            <div class="relative max-w-9xl mx-auto">
                <div class=" max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                    <info-image-widget
                        :src="require('@/assets/images/onlineshops/kunden/gesamt.svg')"
                        :primary-text="formatNumber(figures.leads.total)"
                        secondary-text="Gesamte Leads" />

                    <info-image-widget
                        :src="require('@/assets/images/intern/dashboard/eans.svg')"
                        :primary-text="formatNumber(figures.ean.unique)"
                        :secondary-text="`Unique EANs von ${formatNumber(figures.ean.total)}`" />

                    <info-image-widget
                        :src="require('@/assets/images/intern/dashboard/filialen.svg')"
                        :primary-text="formatNumber(figures.branches.count)"
                        secondary-text="Standorte" />

                </div>
            </div>

            <table-stats-extended
                :headers="['lokaler Händler', 'Filiale', 'Leads', 'Produkte']"
                :data="figures.branches.top3">

                <template v-slot:col-leads="{value, classes}">
                    <td :class="classes.td">{{formatNumber(value)}}</td>
                </template>
                <template v-slot:col-products="{value, classes}">
                    <td :class="classes.td">{{formatNumber(value)}}</td>
                </template>
            </table-stats-extended>

            <table-stats-extended
                :headers="['Onlineshops', 'Leads']"
                :data="figures.webshops.top3">

                <template v-slot:col-leads="{value, classes}">
                    <td :class="classes.td">{{formatNumber(value)}}</td>
                </template>
            </table-stats-extended>

<!--                        &lt;!&ndash; Tabelle Gesamt &ndash;&gt;-->
<!--                        <table-stats v-if="userHasPermission('branches.view-all')" header="Alle Filialen"-->
<!--                                     :month="figures.total.month"-->
<!--                                     :week="figures.total.week"-->
<!--                                     :today="figures.total.today" />-->

<!--                        &lt;!&ndash; Tabelle individuell &ndash;&gt;-->
<!--                        <template v-for="branch in branches">-->
<!--                            <table-stats :header="`${branch.city}, ${branch.street_address}`"-->
<!--                                         :month="figures.branch[branch.id].month"-->
<!--                                         :week="figures.branch[branch.id].week"-->
<!--                                         :today="figures.branch[branch.id].today" />-->
<!--                        </template>-->
        </div>

    </div>
</template>

<script>
import {stopLoading} from "@/js/helper/modals";
import InfoImageWidget from "@/components/general/InfoImageWidget";
import TableStats from "@/components/tables/TableStats";
import DefaultHeader from "@/components/layout/DefaultHeader";
import {api, su} from "@/js/helper/api";
import TableStatsExtended from "@/components/tables/TableStatsExtended";

export default {
    name: "Index",
    components: {TableStatsExtended, DefaultHeader, InfoImageWidget, TableStats},
    data: () => ({
        figures: null
    }),
    created() {
        su.get('dashboard').then(res => {
            this.figures = res.data
        }).finally(() => {
            stopLoading()
        })
    }
}
</script>

<style scoped>

</style>