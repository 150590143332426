<template>
    <transition
        enter-active-class="transition-all transform ease-in-out overflow-y-hidden duration-300"
        enter-from-class="max-h-0 opacity-0"
        :enter-to-class="`opactiy-100 ${maxHeight}`"
        leave-active-class="transition-all transform ease-in-out overflow-y-hidden duration-300"
        :leave-from-class="`opactiy-100 ${maxHeight}`"
        leave-to-class="max-h-0 opacity-0"
    >
        <slot></slot>
    </transition>
</template>

<script>
export default {
    name: "TransitionHeight",
    props: {
        maxHeight: {default: 'max-h-16'}
    }
}
</script>

<style scoped>

</style>