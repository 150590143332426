<template>
    <div>
        <default-header>
            <Header></Header>
        </default-header>


        <table v-if="filteredUsers.length" class="min-w-full divide-gray-200">
            <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="user in filteredUsers" class="pb-5">
                    <td class="py-6 whitespace-nowrap w-1/3">
                        <div class="flex items-center">
                            <div class="h-10 w-10">
                                <profile-icon v-model="user.name"
                                              background-color="bg-gradient-to-b from-orange-200 to-orange-300"/>
                            </div>
                            <div class="ml-4">
                                <div class="text-lg font-medium">
                                    {{ user.name }}
                                </div>
                                <div class="text-md -mt-1 text-gray-500">
                                    {{ user.email }}
                                </div>
                            </div>
                        </div>
                    </td>

                    <td class="w-1/3">
                        <form-select v-model:selected="user.branches" :options="branchesOptions"
                                     @change="onBranchChange($event, user)"
                                     placeholder="Wähle eine Filiale..."
                        >
                            <template v-slot:button="{selectedOption}">
                                <profile-icon v-model="selectedOption.city" :src="branchImageSource(selectedOption)" class="text-xs" size="w-7 h-7"/>
                                <span class="ml-3 block truncate">
                                    {{ branchLabel(selectedOption) }}
                                </span>
                            </template>
                            <template v-slot:item="{option,isSelected}">
                                <profile-icon v-model="option.city" :src="branchImageSource(option)" class="text-xs" size="w-7 h-7"/>
                                <span :class="{'font-semibold': isSelected}"
                                      class="ml-3 block font-normal truncate">
                                    {{ branchLabel(option) }}
                                </span>
                            </template>
                        </form-select>
                    </td>

                    <td class="px-6 py-6 whitespace-nowrap text-gray-500 text-right text-lg font-medium">
                        <a v-if="userHasPermission('team.edit')" @click.prevent="removeUser=user"
                           class="cursor-pointer">entfernen</a>
                    </td>
                </tr>
            </tbody>
        </table>
        <image-landing-page v-else :src="require('@/assets/images/lokale/team/kein_team.svg')">
            <template v-slot:title>Du verwaltest alles alleine.</template>
            <template v-slot:sub-title>Lade Mitglieder ein, um die Arbeit zu teilen.</template>
        </image-landing-page>

        <modal-confirm v-model="removeUser" :title="`${removeUser.name || 'Benutzer'} entfernen`"
                       @confirm="remove(removeUser)">
            Bist du sicher, dass du das Mitglied aus deinem Team entfernen möchtest? Etwaige Zuordnungen der
            Filialen werden dem Admin zugeordnet
        </modal-confirm>

    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import DefaultHeader from "../../../components/layout/DefaultHeader";
import {TEAM_REQUEST, TEAM_USER_REMOVE, TEAM_USER_UPDATE_BRANCH} from "../../../store/team";
import {BRANCHES_REQUEST} from "../../../store/branches";
import SlideOver from "../../../components/overlays/SlideOver";
import Header from "./Header";
import ImageLandingPage from "../../../components/general/ImageLandingPage";
import FormSelect from "../../../components/forms/FormSelect";
import ProfileIcon from "../../../components/general/ProfileIcon";
import ModalConfirm from "../../../components/modals/ModalConfirm";
import {startLoading, stopLoading, success} from "../../../js/helper/modals";


export default {
    name: 'TeamIndex',
    components: {ModalConfirm, FormSelect, ImageLandingPage, Header, SlideOver, DefaultHeader, ProfileIcon},
    data: () => ({
        selectAll: {
            label: 'Alle Filialen',
            id: 'all'
        },
        removeUser: false,
    }),
    created() {
        if(this.teamLoaded && this.branchesLoaded) {
            //  Nur einmal initial laden
            stopLoading()
        }
        else {
            Promise.all([
                this.$store.dispatch(TEAM_REQUEST),
                this.$store.dispatch(BRANCHES_REQUEST)
            ]).finally(() => {
                stopLoading()
            })
        }

    },
    methods: {
        branchLabel(branch) {
            if(branch.label) {
                return branch.label
            }
            else {
                return `${branch.city}, ${branch.street_address}`
            }
        },
        onBranchChange(event, user) {
            let branch = event.selected[ 0 ] || null
            if(branch) {
                this.$store.dispatch(TEAM_USER_UPDATE_BRANCH, {user, branch}).then(() => {
                    success(null, `Die Filialzuordnung von ${user.name} wurde erfolgreich geändert`)
                })
            }
        },
        remove(user) {
            this.$store.dispatch(TEAM_USER_REMOVE, {user}).then(res => {
                success(null, 'Der Benutzer wurde erfolgreich vom Team entfernt')
            });
        },
        branchImageSource(branch){
            return branch.image_128x128 ? this.fileUri(branch.image_128x128) : false
        },
    },
    computed: {
        ...mapGetters([
            'userHasPermission',
            'teamusers',
            'branches',
            'branchesLoaded',
            'teamLoaded'
        ]),
        filteredUsers() {
            //  Falls UserRole  == manager, werden nicht die branches übergeben, sondern 'all'
            let arrUser = []
            for (let user of this.teamusers) {
                if(user.id != this.$store.getters.user?.id) {
                    if(user.branches == this.selectAll.id) {
                        user.branches = [this.selectAll]
                    }

                    arrUser.push(user)
                }
            }

            return arrUser
        },
        branchesOptions() {
            return [this.selectAll, ...this.branches]
        },
    }
}
</script>