<template>
    <a :href="`mailto:${to}?subject=${subject}`">
        <slot>
            <button-primary>
                <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                     viewBox="0 0 24 24" stroke="none">
                    <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                          clip-rule="evenodd"/>
                </svg>
                {{label}}
            </button-primary>
        </slot>
    </a>
</template>

<script>

import ButtonPrimary from "@/components/buttons/ButtonPrimary";

export default {
    name: "ButtonContact",
    components: {ButtonPrimary},
    props: {
        to: {default: 'help@getfrom.com'},
        subject: {default: 'Kontaktanfrage'},
        label: {default: 'Kontakt'}
    }
}
</script>

<style scoped>

</style>