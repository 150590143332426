
export const SIDEBAR_SHOW='SIDEBAR_SHOW';

export default {
	state: {
		show: false
	},
	getters: {
		showSidebar: state => state.show,
	},
	mutations: {
		[SIDEBAR_SHOW]: (state, prop) => {
			state.show = prop
		},
	},
};
