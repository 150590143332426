<template>

    <div class="grid grid-cols-6 w-full max-w-7xl px-4 mx-auto">
        <div class="col-span-3">
            <img src="@/assets/images/login/login.png" class="top-0 md:top-16 relative max-w-md">
        </div>
        <div class="col-span-6 md:col-span-3 p-8 md:p-0 flex flex-col justify-center">
            <h3 class="mb-2 md:mb-3 text-3xl leading-8 font-medium text-gray-600">Login</h3>
            <vee-form class="" @submit="onSubmit" :validation-schema="schema" v-slot="{errors}" >
                <div class="h-6 mb-1 text-red-500">
                    <span v-if="errors">{{errors.email ? errors.email : errors.password}}</span>
                </div>
                <div class="rounded-md shadow-sm -space-y-px">
                    <div>
                        <label for="email-address" class="sr-only">Email address</label>
                        <vee-field id="email-address" name="email" type="email" autocomplete="email"
                               @keypress.enter.prevent
                               required
                               tabindex="1"
                               :class="errors.email ? errorClass : ''"
                               class="rounded-none relative block w-full px-3 py-2 bg-white border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 focus:z-10 sm:text-sm"
                               v-model="username"
                               placeholder="E-Mail" />
                    </div>

                    <div>
                        <label for="password" class="sr-only">Password</label>
                        <vee-field id="password" name="password" type="password"
                               autocomplete="current-password" tabindex="2"
                               required
                               :class="errors.password ? errorClass : ''"
                               v-model="password"
                               class="appearance-none rounded-none relative block w-full px-3 py-2 bg-white border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 focus:z-10 sm:text-sm"
                               placeholder="Passwort" />
                    </div>
                </div>

                <div class="flex flex-col md:flex-row items-center mt-8">
                    <button type="submit" tabindex="3"
                            class="group w-full md:w-auto relative flex items-center justify-center py-1 px-4 border border-transparent text-lg font-medium rounded-md text-white bg-orange-400 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">

                        <div v-if="isSubmitting" style="border-top-color:transparent" class="mr-2 border-solid animate-spin  rounded-full border-white border-2 h-4 w-4"></div>
                        Einloggen
                    </button>
                    <div class="text-sm  mt-2 md:mt-0 md:ml-4 ">
                        <a href="mailto:help@getfrom.com?subject=Passwort%20vergessen" class="font-medium text-lg text-gray-600 hover:text-gray-800">
                            Passwort vergessen?
                        </a>
                    </div>
                </div>
            </vee-form>
        </div>
    </div>


</template>

<script>
import {AUTH_REQUEST} from "../../store/auth";
import {useForm, useFormErrors} from 'vee-validate'
import * as yup from 'yup'

export default {
    name: 'AuthLogin',
    setup() {
        const schema = yup.object({
            email: yup.string().required().email(),
            password: yup.string().required().min(2)
        })

        return {
            schema,
        }
    },
    data() {
        return {
            isSubmitting: false,
            errorClass: 'border-red-500 border-1',
            username: '',
            password: '',
        }
    },
    methods: {
        onSubmit(vaules, actions) {
            this.isSubmitting = true
            const {username, password} = this;

            this.$store.dispatch(AUTH_REQUEST, {username, password}).then(() => {
                this.$router.replace({name: 'Home'})
            }).catch((err) => {
                actions.setFieldError('email', 'Wir konnten keinen Benutzer ermitteln, der deiner Eingabe entspricht.')
                actions.setFieldError('password', 'Wir konnten keinen Benutzer ermitteln, der deiner Eingabe entspricht.')

                this.isSubmitting = false
            })
        }
    }
}
</script>