<template>
	<div v-click-outside="close" >
		<div class="mt-0 relative">
			<button @click="open" type="button" aria-haspopup="listbox" :aria-expanded="expanded" aria-labelledby="listbox-label" class="relative cursor-pointer w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-1 focus:ring-orange-500 focus:border-orange-500 sm:text-sm">
				<span v-if="selectedOption" class="flex items-center">
					<slot name="button" v-bind="{selectedOption, options}">
						{{selectedOption}}
					</slot>
				</span>
				<span v-else class="flex items-center" >
					<slot name="placeholder">
						<span class="ml-3 block truncate text-gray-500">
							{{placeholder}}
						</span>
					</slot>
				</span>
				<span class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
					<!-- Heroicon name: solid/selector -->
					<svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
						<path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
					</svg>
				</span>
			</button>

			<transition
				leave-active-class="transition ease-in duration-100"
				leave-from-class="opacity-100"
				leave-to-class="opacity-0"
			>
				<div v-if="expanded" class="absolute z-20 cursor-pointer mt-1 w-full rounded-md bg-white shadow-lg">
					<ul tabindex="-1"
						role="listbox"
						aria-labelledby="listbox-label"
						class="max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
						<!--
						  Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

						  Highlighted: "text-white bg-orange-600", Not Highlighted: "text-gray-900"
						-->
						<li v-for="(option, index) in options" :id="`listbox-item-${identifier}-${index}`" @click="select(option)"
							role="option" class=" focus:text-white focus:bg-orange-400 hover:text-white hover:bg-orange-400 text-gray-900 select-none relative py-2 pl-3 pr-9">

							<div class="flex items-center">
								<slot name="item" v-bind="{option, index, isSelected: isSelected(option) }">
									{{option}}
								</slot>
							</div>

							<!--
							  Checkmark, only display for selected option.

							  Highlighted: "text-white", Not Highlighted: "text-orange-600"
							-->
							<span v-if="isSelected(option)" class="absolute inset-y-0 right-0 flex items-center pr-4">
								<!-- Heroicon name: solid/check -->
								<svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
								</svg>
							</span>
						</li>

						<!-- More items... -->
					</ul>
				</div>
			</transition>
		</div>
	</div>

</template>

<script>
	export default {
		name: "FormSelect",
		emits: ['open', 'close', 'select', 'change', 'update:selected'],
		props: {
			options: {type: Array, default: []},
			selected: {type: Array, default: []},
			placeholder: {type: String, default: 'Keine Option ausgewählt'}
		},
		setup(){
			let identifier = '';
			for(let i=0; i<16; i++){
				identifier += (Math.floor(Math.random() * 16)).toString(16)
			}

			return {identifier}
		},
		data: () => ({
			expanded: false,
		}),
		methods: {
			open(){
				this.expanded = true
				this.$emit('open')
			},
			close(){
				this.expanded = false
				this.$emit('close')
			},
			select(option){
				this.$emit('update:selected', [option])
				this.$emit('select', option)
				this.$emit('change', {selected: [option]})
				this.close()
			},
			isSelected(option){
				return this.selected.indexOf(option) >= 0
			}
		},
		computed: {
			selectedOption(){
				return this.selected[0] || null
			}
		}

	}
</script>

<style scoped>

</style>