import axios from "axios";
// import {api} from "../js/helper/api";
import {CONTEXT_DEALER} from "./context";
import {dealer} from "../js/helper/api";

export const DEALERS_LOADED='DEALERS_LOADED'
export const DEALERS_CURRENT='DEALERS_CURRENT'
export const DEALERS_BRANCH_UPDATE='DEALERS_BRANCH_UPDATE'
export const DEALERS_COMPANY_UPDATE='DEALERS_COMPANY_UPDATE'


export default {
	state: {
		dealers: [],
		branches: {}
	},
	getters: {
		currentDealer: (state, getters) => {
			if(getters.isDealer) {
				return state.dealers[getters.contextId] || null
			}
			return null
		},
		currentDealerId: (state, getters) => getters.currentDealer?.id || null,
		dealerCompany: (state, getters) => getters.currentDealer?.company,
		dealers: state => state.dealers
	},
	mutations: {
		[DEALERS_LOADED]: (state, dealers) => {
			const indexedDealers = {};
			for(var i in dealers){
				indexedDealers[dealers[i].id] = dealers[i]
			}

			state.dealers = indexedDealers
		},
		[DEALERS_COMPANY_UPDATE]: (state, {company, dealer}) => {
			state.dealers[dealer].company = {
				...state.dealers[dealer].company,
				...company
			}
		}

	},
	actions: {
		[DEALERS_COMPANY_UPDATE]: ({state, getters, commit}, data) => {
			return new Promise((resolve, reject) => {
				dealer.patch(`company/${getters.company.id}`, data).then(res => {
					commit(DEALERS_COMPANY_UPDATE, {company:data, dealer:getters.currentDealerId})

					resolve(res.data)
				}).catch(err => reject(err))
			})
		}
	}

}