<template>
	<div v-if="showSelect" class="relative text-left">
        <div>
            <div v-click-outside="close" class="inline-block">
                <button @click="isOpen = !isOpen"
                        type="button"
                        class="text-gray-800 inline-flex flex-initial w-full rounded-md py-2 bg-white text-md font-medium focus:outline-none "
                        id="branch-select"
                        aria-haspopup="true"
                        aria-expanded="true">

                    {{selectedBranch.street_address}}, {{selectedBranch.city}}
                    <svg class="-mr-1 ml-2 h-5 w-5 text-orange-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </button>
            </div>
        </div>

		<!--
		  Dropdown panel, show/hide based on dropdown state.

		  Entering: "transition ease-out duration-100"
			From: "transform opacity-0 scale-95"
			To: "transform opacity-100 scale-100"
		  Leaving: "transition ease-in duration-75"
			From: "transform opacity-100 scale-100"
			To: "transform opacity-0 scale-95"
		-->
		<transition
			enter-active-class="transition ease-out duration-100"
			enter-from-class="transform opacity-0 scale-95"
			enter-to-class="transform opacity-100 scale-100"
			leave-active-class="transition ease-in duration-75 transform"
			leave-from-class="transform opacity-100 scale-100"
			leave-to-class="transform opacity-0 scale-95"
		>
			<div v-show="isOpen" class="z-20 cursor-pointer origin-top-left absolute left-0 mt-2 w-100 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
				<div class="py-1 flex flex-col" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
					<template v-for="branch in branches" :key="branch.id">
						<span @click="selectBranch(branch)" class="block px-4 py-2 text-sm hover:bg-gray-100 " role="menuitem">
							{{branch.street_address}}, {{branch.city}}
						</span>
					</template>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>

	import {mapGetters, mapActions} from 'vuex'
	import {BRANCHES_SELECT} from "../../store/branches";

	export default {
		name: "BranchSelect",
		data: () => ({
			isOpen: false
		}),
		methods: {
			close(){
				this.isOpen = false
			},
			...mapActions({
				selectBranch: BRANCHES_SELECT
			})
		},
		computed: {
			...mapGetters([
				'branches',
				'selectedBranch'
			]),
			showSelect(){
				return this.selectedBranch && Object.keys(this.selectedBranch).length && this.branches.length
			}
		}
	}
</script>

<style scoped>

</style>