<template>
    <div class="relative bg-white">
        <div class="min-h-screen md:min-h-0 h-full md:h-auto w-full py-6 bg-gray-100">
            <div class="mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <div>
                  <a href="#" class="float-left">
                    <img class="h-8 w-auto sm:h-7" src="@/assets/images/logo.svg" alt="">
                  </a>
                </div>
                
                <div class="flex justify-end md:ml-12">
                    <span class="text-base font-medium text-gray-500 hover:text-gray-900">
                        <router-link :to="{name: 'Landingpage-Local'}" >Lokale Händler</router-link>
                    </span>
                    <span class="ml-8 text-base font-medium text-gray-500 hover:text-gray-900">
                        <router-link :to="{name: 'Landingpage-Online'}" >Onlineshops</router-link>
                    </span>
                    <span class="ml-8 text-base font-medium text-gray-500 hover:text-gray-900">
                        <router-link :to="{name: 'Login'}" >Login</router-link>
                    </span>
                </div>
            </div>



            <router-view v-slot="{ Component }">
                <div v-show="!!isLoading">
                    <loading-spinner/>
                </div>
                <transition
                    v-show="!isLoading"
                    enter-active-class="ease-in duration-150"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="ease-in duration-150"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <component :is="Component"/>
                </transition>
            </router-view>

        </div>
        <div class="hidden md:block bg-white py-8 px-48 flex mt-auto flex-row w-full ">
            <!--            <span class="justify-self-end text-gray-600">Impressum</span>-->
        </div>
    </div>
</template>

<script>
import LoadingSpinner from "@/components/overlays/LoadingSpinner";
import {mapGetters} from "vuex";


export default {
    name: "LandingpageContainer",
    components: {LoadingSpinner},
    data: () => ({
    }),
    computed: {
        ...mapGetters([
            'isLoading'
        ])
    }
}
</script>

<style scoped>

</style>