<template>
    <div class="flex flex-col rounded-lg overflow-hidden border">
        <div class="flex-shrink-0 bg-gray-100">
            <img class="transform scale-75 h-48 w-full" :src="src" alt="">
        </div>
        <div class="flex-1 bg-white p-3 flex flex-col justify-between">
            <div class="flex-1">
                <span class="block mt-2">
                    <p class="text-2xl font-medium text-gray-800">
                        {{ primaryText }}
                    </p>
                    <p class="display-1 mt-1 text-sm text-gray-500 uppercase">
                        {{ secondaryText }}
                    </p>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InfoImageWidget",
    props: {
        src: {required: true, type:String},
        primaryText: {default: 'Primary', type: String},
        secondaryText: {default: 'Secondary', type: String},
    }
}
</script>

<style scoped>

</style>