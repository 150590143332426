<template>
	<div class="hidden md:flex md:flex-shrink-0">
		<div class="flex flex-col w-72">
			<!-- Sidebar component, swap this element with another sidebar if you like -->
			<div class="flex flex-col flex-grow pt-4 pb-0 bg-gray-50 overflow-y-auto">
				<div class="flex items-center flex-shrink-0 pt-4 px-8">
					<router-link to="/">
						<img class="h-6 w-auto"
							 src="@/assets/images/logo.svg"
							 alt="Workflow">
					</router-link>
				</div>
                <sidebar-nav></sidebar-nav>
			</div>
		</div>
	</div>
</template>
<script>
	import {mapGetters} from "vuex";
    import SidebarNav from "@/components/layout/SidebarNav";

	export default {
		components: {SidebarNav},
		methods: {

		},
		computed: {
			...mapGetters([
				'user',
				'userHasPermission',
				'isDealer',
				'isWebshop',
			])
		}
	}
</script>