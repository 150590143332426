import {api, dealer, su, webshop} from "../js/helper/api";
import {USER_LOGOUT, USER_RESET} from "./user";
import axios from "axios";

export const CONTEXT_DEALER='dealer';
export const CONTEXT_WEBSHOP='webshop';
export const CONTEXT_SU='su';

export const CONTEXT_UPDATE='CONTEXT_UPDATE';
export const CONTEXT_RESET='CONTEXT_RESET';
export const CONTEXT_REQUEST='CONTEXT_REQUEST';
export const CONTEXT_LOADED='CONTEXT_REQUEST';
export const CONTEXT_FORCE_REFRESH='CONTEXT_FORCE_REFRESH';

export const CONTEXT_ACTING_AS='CONTEXT_ACTING_AS'
export const CONTEXT_BACK_TO_SU='CONTEXT_BACK_TO_SU'

export const ACTING_AS = 'Acting-As'

export default {
	state: {
		context: null,
		contextId: null,
		loaded: false,
		actingAs: null
	},
	getters: {
		context: state => state.context,
		contextModel: (state, getters) => {
			if(getters.isDealer) return getters.currentDealer
			if(getters.isWebshop) return getters.currentWebshop
		},
		contextLoaded: state => state.loaded,
		contextId: state => state.contextId,
		isDealer: state => state.context == CONTEXT_DEALER,
		isWebshop: state => state.context == CONTEXT_WEBSHOP,
		isSU: state => state.context == CONTEXT_SU,
		company: (state,getters) => {
			if(getters.isWebshop){
				console.log("webshop")
				return getters.webshopCompany
			} else if(getters.isDealer){
				console.log("webshop")
				return getters.dealerCompany
			}
		},
		actingAs: state => state.actingAs,
		isSuLoggedInAsUser: state => !!state.actingAs
	},
	mutations: {
		[CONTEXT_UPDATE]: (state, props) => {
			console.log('Context Commited', props)
			state.context = props.context
			state.contextId = props.id

			if(props.context == CONTEXT_DEALER){
				dealer.setId(props.id);
			} else if(props.context == CONTEXT_WEBSHOP){
				webshop.setId(props.id);
			} else {
				// superuser
				dealer.setId(null)
				webshop.setId(null)
				su.setId(null)
			}
		},
		[CONTEXT_RESET]: (state) => {
			state.loaded = false
			state.context = null
			state.contextId = null
		},
		[CONTEXT_LOADED]: (state) => {
			state.loaded = true;
		},
		[CONTEXT_ACTING_AS]: (state, iUser) => {
			state.actingAs = iUser
			if(iUser){
				localStorage.setItem(ACTING_AS, iUser)
				axios.defaults.headers.common[ACTING_AS] = iUser
			} else  {
				localStorage.removeItem(ACTING_AS)
				delete axios.defaults.headers.common[ACTING_AS]
			}
		}
	},
	actions: {
		[CONTEXT_REQUEST]: ({commit}) => {
			return new Promise((resolve, reject) => {
				api.get('context').then(res => {
					commit(CONTEXT_UPDATE, res.data)

					commit(CONTEXT_LOADED)
					resolve()
				}).then((err) => {reject(err)})
			});
		},
		[CONTEXT_FORCE_REFRESH]: ({commit, dispatch}) => {
			return new Promise((resolve, reject) => {
				commit(CONTEXT_RESET)
				commit(USER_RESET)

				resolve()
			})
		},
		[CONTEXT_ACTING_AS]: ({dispatch, commit, getters}, iUser) => {
			return new Promise((resolve, reject) => {
				if(getters.isSU){
					commit(CONTEXT_ACTING_AS, iUser)
					dispatch(CONTEXT_FORCE_REFRESH).then(res => {
						resolve(res)
					}).catch(err => reject(err))
				} else {
					throw new Error("User is kein Superuser")
				}
			})
		},
		[CONTEXT_BACK_TO_SU]: ({dispatch, commit}) => {
			return new Promise((resolve, reject) => {
				commit(CONTEXT_ACTING_AS, null)
				dispatch(CONTEXT_FORCE_REFRESH).then(res => {
					resolve(res)
				}).catch(err => reject(err))
			})
		}
	}
}