<template>
    <transition
        enter-active-class="transition ease-out duration-500"
        enter-from-class="opacity-0 -translate-y-1"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in duration-500"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 -translate-y-1"
    >
        <slot></slot>
    </transition>
</template>

<script>
export default {
    name: "TransitionSlideDown"
}
</script>

<style scoped>

</style>