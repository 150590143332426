<template>
    <div class="flex flex-col mt-6">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="overflow-hidden border border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <tr>
                                <th v-for="headline in headers" scope="col"
                                    class="px-3 py-3 text-left text-sm font-medium text-gray-700 uppercase tracking-wider">
                                    {{ headline }}
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="dataset in data" >
                                <template v-for="(value, index) in dataset">
                                    <slot :name="`col-${index}`" v-bind="{headers, data, value, dataset, classes}">
                                        <td :class="classes.td" >
                                            {{value}}
                                        </td>
                                    </slot>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TableStatsExtended",
    props: {
        headers: {type:Array, required: true},
        data: {type:Array, required: true},
        classTd: {type: String, default: 'px-3 py-4 whitespace-nowrap text-lg text-gray-800'}
    },
    mounted() {
        for(let dataset of this.data){
            if(Object.keys(dataset).length != this.headers.length){
                throw new Error("Anzahl der Header und der Spalten der Datensätze stimmt nicht überein")
            }
        }
    },
    computed: {
        classes() {
            return {
                td: this.classTd
            }
        }
    }
}
</script>

<style scoped>

</style>