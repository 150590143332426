<template>
	<div class="flex-1 flex flex-col justify-between">
		<vee-form @submit="onSubmit" v-slot="{errors, resetForm}">
			<div class="divide-y divide-gray-200">
				<div class="space-y-6 pt-6 pb-5">
					<div class="mb-10">
						<label class="block text-lg font-medium">
							Direkt-Eingabe
						</label>
						<div class="mt-1">
							<vee-field
									as="textarea"
									name="ean"
									:rules="eanValid"
									:validateOnChange="false"
									:class="{'border-red-500': errors.ean}"
									rows="8"
									placeholder="Eine EAN pro Zeile"
									class="outline-none p-2 resize-none border block w-full shadow-sm sm:text-sm focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"></vee-field>
							<vee-error-message class="mt-2 text-sm text-red-600"
											   name="ean"></vee-error-message>
						</div>
					</div>
					<div class="mb-10">
						<label class="block text-lg font-medium text-gray-800 mt-0">
							Datei-Upload
						</label>
                        <span @click="downloadExampleCsv" class="text-gray-500 underline text-sm -mt-1 mb-5 block cursor-pointer">Beispiel-Datei herunterladen</span>
						<div @drop.prevent="addFile"
							 @dragover.prevent="dragOver=true"
							 @dragexit.prevent="dragOver=false"
							 @dragleave.prevent="dragOver=false"
							 class="mt-2 border-2 border-gray-300 border-dashed rounded-md"
							 :class="{'border-orange-400': dragOver}"
							 ref="dropzone">

							<div class="space-y-1 text-center flex flex-col justify-center px-6 pt-5 pb-6">
								<svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
									<path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
										  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
								<div class="flex justify-center text-sm text-gray-600">
									<label class="relative cursor-pointer bg-white rounded-md font-medium text-orange-600 hover:text-orange-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-500">
										<span>Datei hochladen</span>
										<vee-field
											type="file"
                                            accept=".csv"
											multiple
											ref="csv"
											name="csv"
											@change="addInputFile"
											class="sr-only"
										/>
									</label>
									<p class="pl-1">oder per drag and drop</p>
								</div>
								<p class="text-xs text-gray-500">
									CSV bis max. 10MB
								</p>
							</div>
							<div v-if="files.length" class="flex-row border-gray-300 border-t-2 border-dashed">
								<template v-for="file in files">
									<div class="p-2 mx-2">
										{{file.name}}
										<a @click.prevent="removeFile(file)" class="cursor-pointer">
											<svg class="inline h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
											</svg>
										</a>
									</div>
								</template>
							</div>
						</div>
					</div>
					<div class="mt-10 text-right">
                        <button-save type="primary" :loading="isSubmitting"></button-save>
					</div>
				</div>
			</div>
		</vee-form>
	</div>
</template>

<script>
	import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
    import {BRANCHES_PRODUCTS_ADD, BRANCHES_PRODUCTS_DOWNLOAD_EXAMPLE} from "../../../store/branches";
	import {error, success} from "../../../js/helper/modals";
    import {mapActions} from "vuex";
    import ButtonSave from "@/components/buttons/ButtonSave";


	export default {
		name: "Add",
		components: {ButtonSave, ButtonPrimary},
		emits: ['added'],
		data: () => ({
			files: [],
            isSubmitting: false,
			dragOver: false,
		}),
		methods: {
		    ...mapActions({
                downloadExampleCsv: BRANCHES_PRODUCTS_DOWNLOAD_EXAMPLE
            }),
			eanValid(value) {
				if(value){
					let arrEan = value.split("\n")
					for (let ean of arrEan) {
						if(ean.length && ean.length != 13 && ean.length != 8) {
							return "EANs müssen eine Länge von 8 bzw. 13 Zahlen aufweisen"
						}
					}
				}

				return true
			},
			isEanSet(values){
				return !(typeof values.ean == 'undefined' || !values.ean.length)
			},
			isCsvSet(values){
				return this.files.length
			},
			onSubmit(values, {resetForm, setFieldError}){
				if(!this.isEanSet(values) && !this.isCsvSet(values)){
					setFieldError('ean', 'Pflichtfeld! Mindestens eins (Direkt-Eingabe / Datei-Upload) muss genutzt werden')
					return false
				}

				let formData = new FormData()
				if(this.isCsvSet(values)) {
					for(let file of this.files){
						formData.append('file[]', file)
					}
				}

				if(this.isEanSet(values)){
					formData.append('ean', values.ean)
				}

				this.isSubmitting = true
				this.$store.dispatch(BRANCHES_PRODUCTS_ADD, formData ).then((res) => {
					success(null, "Produkte erfolgreich angelegt")
					resetForm()
					this.files = []
					this.$emit('added');
				}).catch(err => {
					error(null, "Beim Anlegen der Produkte trat ein Fehler auf")
				}).finally(() => {
				    this.isSubmitting = false
                });
			},
			addInputFile(e){
				([...e.target.files]).forEach(f => {
					this.files.push(f);
				});

				e.target.value = ""
			},
			addFile(e){
				this.dragOver = false
				let droppedFiles = e.dataTransfer.files;
				if(!droppedFiles) return;
				this.files.push(...droppedFiles)
				// this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
			},
			removeFile(file){
				this.files = [...this.files].filter(f => {
					return f != file;
				});
			},
		}
	}
</script>

<style scoped>

</style>