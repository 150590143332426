<template>
	<div class="flex-grow flex w-100">
		<div class="flex-grow">
            <h2 class="text-xl font-medium leading-7 sm:text-xl sm:truncate">
                Meine Filialen
            </h2>
		</div>
		<div class="flex-grow-0">
			<div class="flex flex-column">
				<button-primary v-if="userHasPermission('branches.edit-all')" @click="add=true">
					<svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" stroke="none">
						<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
					</svg>
					Filiale
				</button-primary>
			</div>
		</div>


		<slide-over v-model="add"
					title="Filiale anlegen"
					v-slot="{close}"
		>
			<Edit key="branchAdd" @save="close"></Edit>
		</slide-over>

	</div>
</template>

<script>
    import SlideOver from "../../../components/overlays/SlideOver";
	import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
    import Edit from "./Edit";
    import {mapGetters} from "vuex";

	export default {
		name: "Header",
        components: {Edit, SlideOver, ButtonPrimary},
        data: () => ({
            add: false
        }),
        computed: {
		    ...mapGetters([
		        'userHasPermission'
            ])
        }
	}
</script>

<style scoped>

</style>