<template>
    <router-view v-slot="{ Component }">
        <transition
            enter-active-class="ease-in duration-150 delay-150"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="ease-in duration-150"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <component :is="Component"/>
        </transition>
    </router-view>

    <modals />
</template>


<script>
import SidebarDesktop from './components/layout/SidebarDesktop'
import SidebarMobile from './components/layout/SidebarMobile'
import Header from "./components/layout/Header";
import axios from 'axios'
import {AUTH_LOGOUT} from "./store/auth";
import {mapGetters} from "vuex";
import Modals from "@/components/layout/Modals";

export default {
    name: 'App',
    components: {
        Modals,
        Header,
        SidebarMobile,
        SidebarDesktop
    },
    created() {


        // axios.interceptors.response.use(function(res){
        // 	return res
        // }, function(err) {
        // 	console.log(err.response);
        // 	if (err.response.status === 400 || err.response.status === 401) {
        // 		// if you ever get an unauthorized, logout the user
        // 		that.$store.dispatch(AUTH_LOGOUT)
        // 		// you can also redirect to /login if needed !
        // 		that.$router.push({name: 'Login'})
        // 	}
        //
        // 	return Promise.reject(err)
        // })
    },
    computed: {
        ...mapGetters([
            'isInitialized',
            'isAuthenticated',
            'isDomLoaded'

        ]),
        isLoading() {
            return !this.isDomLoaded
        }
    }
}


</script>


