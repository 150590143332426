<template>
    <div @drop.prevent="addFile"
         @dragover.prevent="dragOver=true"
         @dragexit.prevent="dragOver=false"
         @dragleave.prevent="dragOver=false"
         class="mt-2 border-2 border-gray-300 border-dashed rounded-md"
         :class="{'border-orange-400': dragOver}"
         ref="dropzone">


        <div class="space-y-1 text-center flex flex-col justify-center px-6 pt-5 pb-6">
            <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="flex justify-center text-sm text-gray-600">
                <label class="relative cursor-pointer bg-white rounded-md font-medium text-orange-600 hover:text-orange-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-500">
                    <span>{{textUpload}}</span>
                    <input id="file-upload" @change="addInputFile" v-bind="$attrs" type="file" class="sr-only" />
                </label>
                <p class="pl-1">oder per drag and drop</p>
            </div>
            <p class="text-xs text-gray-500">
                {{textFileType}}
            </p>
        </div>
        <div v-if="files.length" class="flex-row border-gray-300 border-t-2 border-dashed">
            <slot name="upload-preview" v-bind="{files}">
                <template v-for="file in files">
                    <div class="p-2 mx-2">
                        {{file.name}}
                        <a @click.prevent="removeFile(file)" class="cursor-pointer">
                            <svg class="inline h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </a>
                    </div>
                </template>
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "FileDropper",
    emits: ['update:files'],
    props: {
        files: {type: Array, required: false, default: []},
        textFileType: {type: String, default: 'PNG, JPG, GIF bis zu 10MB'},
        textUpload: {type: String, default: 'Eine Datei hochladen'},
    },
    data: () => ({
        dragOver: false,
    }),
    methods: {
        addInputFile(e){
            this.$emit('update:files', [...this.files, ...e.target.files])

            e.target.value = ""
        },
        addFile(e){
            this.dragOver = false
            let droppedFiles = e.dataTransfer.files;
            if(!droppedFiles) return;

            this.$emit('update:files', [...this.files, ...droppedFiles])
            // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
        },
        removeFile(file){
            this.$emit('update:files', [...this.files].filter(f => {
                return f != file;
            }))
        },
    }
}
</script>

<style scoped>

</style>