import {dealer} from "../js/helper/api";

export const TEAM_REQUEST='TEAM_REQUEST'
export const TEAM_USER_UPDATE_BRANCH='TEAM_USER_UPDATE_BRANCH'
export const TEAM_USER_REMOVE='TEAM_USER_REMOVE'


export default {
	state: {
		users: [],
		loaded: false,
	},
	getters: {
		teamusers: state => state.users,
		teamLoaded: state => state.loaded
	},
	mutations: {
		[TEAM_REQUEST]: (state, users) => {
			state.users = users
			state.loaded = true
		},
		[TEAM_USER_REMOVE]: (state, user) => {
			state.users = state.users.filter(function(el){
				return !(el.id == user.id)
			})
		}
	},
	actions: {
		[TEAM_REQUEST]: ({state, commit}) => {
			return new Promise((resolve, reject) => {
				dealer.get('user').then(res => {
					commit(TEAM_REQUEST, res.data)

					resolve(res.data)
				}).catch(err => reject(err))
			})
		},
		[TEAM_USER_UPDATE_BRANCH]: ({state, commit}, props) => {
			return new Promise((resolve, reject) => {
				dealer.post(`user/${props.user.id}/branches`, {branch: props.branch.id}).then(res => {
					resolve(res.data)
				}).catch(err => reject(err))
			});
		},
		[TEAM_USER_REMOVE]: async ({state, commit}, props) => {
			return new Promise((resolve, reject) => {
				dealer.delete(`user/${props.user.id}`).then(res => {
					commit(TEAM_USER_REMOVE, props.user)
					resolve()
				}).catch(err => reject(err));
			})
		}
	}
}