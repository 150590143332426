<template>
	<button type="button"
			class="inline-flex items-center px-4 uppercase font-bold py-2 border border-transparent shadow-sm text-sm rounded-md text-white bg-orange-400 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
	>
		<slot>
			<svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
			</svg>
			Speichern
		</slot>
	</button>
</template>

<script>
	export default {
		name: "ButtonPrimary"
	}
</script>

<style scoped>

</style>