<template>
	<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
		<!--                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg divide-y divide-gray-200">-->
		<div class="bg-gray-50 py-2 rounded-md">
			<div class="relative w-full px-3 text-gray-400 focus-within:text-gray-600">
				<div class="absolute inset-y-0 flex items-center pointer-events-none">
					<!-- Heroicon name: solid/search -->
					<svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
						 fill="currentColor" aria-hidden="true">
						<path fill-rule="evenodd"
							  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
							  clip-rule="evenodd"/>
					</svg>
				</div>

				<input v-model.trim="search.temp"
					   class="block w-full h-full pl-8 pr-3 py-2 bg-transparent border-transparent placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
					   :placeholder="searchPlaceholder" type="search" name="search">
			</div>
		</div>
        <template v-if="filteredEntities.length || filtering">
            <table  class="min-w-full">
                <tbody class="relative min-h-0">
                    <tr v-for="(entity, index) in pageEntities" :key="entity.id" class="even:bg-gray-50">
                        <template v-for="column in columnArray">
                            <slot :name="[column]" v-bind="{entity, column, value: entity[column], index, classes}">
                                <td :class="classes.td">
                                    {{entity[column] || ''}}
                                </td>
                            </slot>
                        </template>
                    </tr>
                    <tr v-if="filtering" class="table-overlay absolute w-full bg-white bg-opacity-70">
                        <loading-spinner />
                    </tr>
                </tbody>
            </table>

            <nav class="bg-white mt-4 py-8 flex items-center justify-between border-b border-gray-200" aria-label="Pagination">
                <div class="hidden sm:block">
                    <p class="text-sm text-gray-700">
                        <span class="font-medium text-gray-800">{{formatNumber(from)}}</span>
                        bis
                        <span class="font-medium text-gray-800">{{formatNumber(to)}}</span>
                        von
                        <span class="font-medium text-gray-800">{{formatNumber(filteredEntities.length)}}</span>
                        {{showEntityName}}
                    </p>
                </div>
                <div class="flex-1 flex justify-between sm:justify-end">
                    <button :disabled="isFirstPage" @click="previousPage" class="disabled:opacity-50 disabled:bg-white relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                        Vorherige
                    </button>
                    <button :disabled="isLastPage" @click="nextPage" class="disabled:opacity-50 disabled:bg-white ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                        Nächste
                    </button>
                </div>
            </nav>
        </template>
        <div v-else class="text-gray-800 text-center py-8">
            Die Suche ergab keine Treffer
        </div>
	</div>
</template>

<script>
	import {mapGetters} from "vuex";
    import LoadingSpinner from "@/components/overlays/LoadingSpinner";

	export default {
		name: "TableDefault",
        components: {LoadingSpinner},
        props: {
			rows:{type: [Array], required: true},
			filterField: {type: [String, Array], required: true},
			entityName: {type: String, default: 'Ergebnis'},
			entityNamePlural: {type: String, default: 'Ergebnissen'},
			perPage: {type: Number, default: 10},
			columns: {type: [Object, Array], default: []},
			searchPlaceholder: {type: String, default: 'Suche'},
			searchDelay: {type: Number, default: 500},
			searchDelayThreshold: {type: Number, default: 10000}
		},
		emits: ['update:modelValue'],
		data: () => ({
			search: {
			    model: '',
                temp: '',
                timer: null,
            },
			page: 1,
            classes: {
			    td: 'px-6 py-4 whitespace-nowrap text-base font-medium'
            }
		}),
		methods: {
			nextPage(){
				this.page++
			},
			previousPage(){
				this.page--
			},
            firstPage(){
			    this.page = 1
            },
			prepareFields(){
				let fields = this.filterField

				if(fields instanceof String || typeof fields === 'string'){
					fields = [fields]
				}

				return fields
			}
		},
		computed: {
			filteredEntities(){
				if(!this.search.model.length) {
					return this.rows
				} else {
					let fields = this.prepareFields()
                    return this.rows.filter((row) => {
						for(let field of fields){
							if(row[field].indexOf(this.search.model) > -1){
								return true
							}
						}

						return false
					});
				}
			},
			showEntityName(){
				if(this.filteredEntities.length == 1){
					return this.entityName
				} else {
					return this.entityNamePlural
				}
			},
			from(){
				if(this.pageEntities.length == 0) return 0;
				return this.fromIndex + 1
			},
			to(){
				return this.toIndex + 1
			},
			fromIndex(){
				return (this.page-1) * this.perPage
			},
			toIndex(){
				return Math.min(this.fromIndex + this.perPage, this.filteredEntities.length) - 1
			},
			pageEntities(){
				return this.filteredEntities.slice(this.fromIndex, this.toIndex + 1)
			},
			isLastPage(){
				return this.page == Math.ceil(this.filteredEntities.length / this.perPage)
			},
			isFirstPage(){
				return this.page == 1
			},
			columnArray(){
				if(this.columns instanceof Array){
					return this.columns
				} else if(this.columns instanceof Object){
					return Object.keys(this.columns)
				}

				return []
			},
            filtering(){
			    return !!this.search.timer
            }
		},
		watch: {
            'search.temp': function(newSearch, oldSearch) {
		        if(this.rows.length > this.searchDelayThreshold){
		            clearTimeout(this.search.timer)
		            this.search.timer = window.setTimeout(() => {
                        this.search.model = newSearch
                        this.search.timer = null
                    }, this.searchDelay)
                } else {
                    this.search.model = newSearch
                }
            },
		    'search.model': function(newSearch, oldSearch){
		        this.firstPage()
            },
            rows(newRows, oldRows){
				if(newRows != oldRows) {
					this.search.model = "";
					this.search.temp = "";
				}
			}
		}
	}
</script>

<style scoped>
    .table-overlay{
        position: absolute;
        top: 0px;
        height: 100%;
    }
</style>