<template>
    <button type="button"
            class="inline-flex uppercase font-bold items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-400 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
    >
        <div v-if="loading" style="border-top-color:transparent" class="mr-2 border-solid animate-spin  rounded-full border-white border-2 h-4 w-4"></div>
        <svg v-else class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
        Speichern
    </button>
</template>

<script>
export default {
    props: {
        loading: {default: false}
    },
    name: "ButtonSave"
}
</script>

<style scoped>

</style>