import axios from "axios";


const api = {
	URL: (process.env.VUE_APP_API_URL || '') + '/spa/v1',
	callApi(method, uri, config, data){
		uri = (uri[0] != '/') ? `/${uri}` : uri
		const url = this.URL + uri;

		return axios({method, url, data, ...config})
	},
	get(uri, config={}) {return this.callApi('GET', uri, config)},
	post(uri, data={}, config={}) {return this.callApi('POST', uri, config, data)},
	patch(uri, data={}, config={}) {return this.callApi('PATCH', uri, config, data)},
	delete(uri, config={}) {return this.callApi('DELETE', uri, config)},

	file(uri) {return `${this.URL}/files/${uri}`}
}


var mixinId = {
	id: null,
	setId(id){
		this.id = id
		this.updateUrl()
	},
	updateUrl() {}
}


const dealer = {
	...api,
	...mixinId,
	updateUrl() {
		this.URL = `${api.URL}/dealer/${this.id}`
	}
}



const webshop = {
	...api,
	...mixinId,
	updateUrl() {
		this.URL = `${api.URL}/webshop/${this.id}`
	}
}



const su = {
	...api,
	...mixinId,
	updateUrl() {
		this.URL = `${api.URL}/su`
	}
}

export {dealer, api, webshop, su};