<template>
	<div v-if="figures">
		<default-header></default-header>
		<div class="relative pb-20">
			<div class="relative max-w-9xl mx-auto">
				<div class=" max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                    <info-image-widget
                        :src="require('@/assets/images/onlineshops/kunden/gesamt.svg')"
                        :primary-text="formatNumber(figures.total.year)"
                        :secondary-text="`KUNDEN SEIT ${ figures.first_date }`" />

                    <info-image-widget
                        :src="require('@/assets/images/onlineshops/kunden/woche.svg')"
                        :primary-text="formatNumber(figures.avg_week)"
                        secondary-text="DURCHSCHNITT PRO WOCHE" />

                    <info-image-widget
                        :src="require('@/assets/images/onlineshops/kunden/record.svg')"
                        :primary-text="formatNumber(figures.record.value)"
                        :secondary-text="`REKORD AM ${ figures.record.date }`" />

				</div>
			</div>

			<!-- Tabelle Gesamt -->
            <table-stats v-if="userHasPermission('branches.view-all')" header="Alle Filialen"
                         :month="figures.total.month"
                         :week="figures.total.week"
                         :today="figures.total.today" />

			<!-- Tabelle individuell -->
            <template v-for="branch in branches">
                <template v-if="figures.branch[branch.id]">
                    <table-stats :header="`${branch.city}, ${branch.street_address}`"
                                 :month="figures.branch[branch.id].month"
                                 :week="figures.branch[branch.id].week"
                                 :today="figures.branch[branch.id].today" />
                </template>
            </template>
        </div>
	</div>
</template>

<script>
	import DefaultHeader from "../../../components/layout/DefaultHeader";
    import {dealer} from "@/js/helper/api";
    import {BRANCHES_REQUEST} from "@/store/branches";
    import {mapGetters} from "vuex";
    import {stopLoading} from "@/js/helper/modals";
    import TableStats from "@/components/tables/TableStats";
    import InfoImageWidget from "@/components/general/InfoImageWidget";

	export default {
		name: 'CustomersIndex',
		components: {InfoImageWidget, TableStats, DefaultHeader},
        data: () => ({
            figures: null
        }),
        created(){
		    this.fetchData()
        },
        computed: {
		    ...mapGetters([
		        'branches',
                'userHasPermission'
            ])
        },
        methods: {
		    fetchData(){
		        Promise.all([
		            this.$store.dispatch(BRANCHES_REQUEST),
                    dealer.get('customer/dashboard')
                ]).then(res => {
                    if(res[1].data){
                        this.figures = res[1].data
                    }
                }).finally(() => {
                    stopLoading()
                })
            }
        }
	}
</script>