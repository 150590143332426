<template>

    <vee-field
        :class="errorClass"
        class="focus:ring-orange-500 focus:border-orange-500 relative block w-full rounded-none bg-transparent focus:z-10 sm:text-sm border-gray-300"
    ></vee-field>

</template>

<script>
export default {
    name: "VeeSpacedInput",
    props: {
        errors: {required: true}
    },
    computed: {
        errorClass(){
            return {
                'ring-1 ring-inset ring-red-500 border-red-500 border-collapse': this.errors
            }
        }
    }
}
</script>

<style scoped>

</style>