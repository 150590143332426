<template>
    <div class="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
        <div style="border-top-color:transparent"
             :class="`border-solid animate-spin rounded-full border-${color} border-4 h-12 w-12`"></div>
    </div>
</template>

<script>
export default {
    name: "LoadingSpinner",
    props: {
        color: {default: 'orange-400'}
    }
}
</script>

<style scoped>

</style>