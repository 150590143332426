<template>
    <div>
        <default-header v-slot="{title}">
            <div class="flex-grow flex w-100">
                <div class="flex-grow">
                    <h2 class="text-xl font-medium leading-7 sm:text-xl sm:truncate">
                        {{ title }}
                    </h2>
                </div>
                <div class="flex-grow-0">
                    <div class="flex flex-column">
                        <button-contact />
                    </div>
                </div>
            </div>
        </default-header>
        <image-landing-page :src="require('@/assets/images/onlineshops/integration/entwicklung.svg')">
            <template v-slot:title >
                Dieser Bereich befindet sich im Aufbau
            </template>
            <template v-slot:sub-title>
                Für technische Fragen, klicke oben auf den Button, um uns zu schreiben.
            </template>
        </image-landing-page>
    </div>
</template>

<script>
    import DefaultHeader from "../../../components/layout/DefaultHeader";
    import ImageLandingPage from "@/components/general/ImageLandingPage";
    import ButtonContact from "@/components/buttons/ButtonContact";

    export default {
    	name: 'IntegrationIndex',
        components: {ButtonContact, ImageLandingPage, DefaultHeader}
    }
</script>