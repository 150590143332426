import { createStore } from 'vuex'

import auth from "./auth";
import user from "./user";
import dealers from "./dealers";
import webshops from "./webshops";
import context from './context'
import branches from "./branches";
import modals from "./modals";
import team from "./team";
import sidebar from "./sidebar";
import accounts from "./accounts";


export const LOADING_START='LOADING_START'
export const LOADING_FINISHED='LOADING_FINISHED'
export const INITIALIZED='INITIALIZED'
export const DOM_LOADED='DOM_LOADED'

export const CONFIRM_OPEN='CONFIRM_OPEN';
export const CONFIRM_CLOSE='CONFIRM_CLOSE';

const options = {
	state: {
		initialized: false,
		domLoaded: false,
		loading: false,
	},
	getters: {
		isLoading: state => state.loading,
		isInitialized: state => state.initialized,
		isDomLoaded: state => state.domLoaded,
	},
	mutations: {
		[LOADING_START]: state => state.loading = true,
		[LOADING_FINISHED]: state => state.loading = false,
		[INITIALIZED]: state => state.initialized = true,
		[DOM_LOADED]: state => state.domLoaded = true,
	},
	actions: {
	},
	modules: {
		auth,
		user,
		dealers,
		webshops,
		context,
		branches,
		modals,
		team,
		sidebar,
		accounts
	},

};

const store = createStore(options)

export default store;
