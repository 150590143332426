import store from '../../store/index'
import {AUTH_LOGOUT} from "@/store/auth";

//	Middleware checking if user is logged in
export const authenticated = (to, from, next) => {
	console.log("authenticated")
	if(store.getters.isAuthenticated){
		next();
	} else {
		next({name: 'Login'})
	}
}


//	Middleware checking if user is logged in
export const unauthenticated = (to, from, next) => {
	if(store.getters.isAuthenticated){
		next({name: 'Home'});
	} else {
		next()
	}
}



export const isContextDealer = (to, from, next) => {
	if(store.getters.isDealer){
		next()
	} else {
		store.dispatch(AUTH_LOGOUT).then(() => {
			next({name: 'Login'})
		})
	}
}



export const isContextWebshop = (to, from, next) => {
	if(store.getters.isWebshop){
		next()
	} else {
		store.dispatch(AUTH_LOGOUT).then(() => {
			next({name: 'Login'})
		})
	}
}



export const isContextSU = (to, from, next) => {
	if(store.getters.isSU){
		next()
	} else {
		store.dispatch(AUTH_LOGOUT).then(() => {
			next({name: 'Login'})
		})
	}
}


