<template>
    <div class="h-screen flex overflow-hidden bg-white">
        <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
        <SidebarMobile></SidebarMobile>

        <!-- Static sidebar for desktop -->
        <SidebarDesktop></SidebarDesktop>
        <div class="flex flex-col w-0 flex-1 overflow-hidden">
            <Header></Header>

            <main ref="main" :class="{'overflow-y-auto': !routeAnimation, 'overflow-y-hidden': routeAnimation}" class="flex-1 relative focus:outline-none" tabindex="0">
                <div class="pb-6">
                    <div class="max-w-12xl mx-auto px-4 sm:px-6 md:px-8 pt-4 md:pt-0" >

                        <!-- Replace with your content -->
                        <router-view v-slot="{ Component }">
                            <div v-show="!!isLoading">
                                <loading-spinner />
                            </div>
                            <transition
                                    v-show="!isLoading"
                                    enter-active-class="ease-in duration-150"
                                    enter-from-class="opacity-0"
                                    enter-to-class="opacity-100"
                                    leave-active-class="ease-in duration-150"
                                    leave-from-class="opacity-100"
                                    leave-to-class="opacity-0"
                            >
                                <component :is="Component" />
                            </transition>
                        </router-view>

                        <!-- /End replace -->
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import SidebarDesktop from "./SidebarDesktop";
import Header from "./Header";
import SidebarMobile from "./SidebarMobile";
import {mapGetters} from 'vuex'
import LoadingSpinner from "@/components/overlays/LoadingSpinner";


export default {
		name: 'Container',
		components: {
            LoadingSpinner,
            Header,
            SidebarMobile,
            SidebarDesktop
		},
        data: () => ({
            routeAnimation: false
        }),
        computed: {
		    ...mapGetters([
                'isLoading'
            ])
        },
        watch: {
		    $route(to, from){
		        this.routeAnimation = true
                window.setTimeout(() => {
                    this.routeAnimation = false
                }, 300);

            }
        }
	}
</script>

<style :scoped>

</style>