import axios from "axios";
import {api} from "../js/helper/api";
import {DEALERS_CURRENT, DEALERS_LOADED} from "./dealers";
import {CONTEXT_UPDATE} from "./context";
import {WEBSHOPS_LOADED} from "./webshops";

export const USER_REQUEST = 'USER_REQUEST';
export const USER_LOADED = 'USER_LOADED';
export const USER_ERROR = 'USER_ERROR';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_RESET = 'USER_RESET';

const USER_STATUS_SUPERADMIN = 'superadmin'

export const USER_STATUS_LOADING = 'loading';
export const USER_STATUS_LOADED = 'loaded';
export const USER_STATUS_UNDEFINED = 'undefined';

const userDefault = {
	id: null,
	name: null,
	email: null,
	can: [],
}

export default {
	state: {
		user: userDefault,
		status: USER_STATUS_UNDEFINED
	},
	getters: {
		user: state => state.user,
		userLoading: state => state.status == USER_STATUS_LOADING,
		userLoaded: state => state.status == USER_STATUS_LOADED,
		userIsSuperadmin: state => state.user?.can == USER_STATUS_SUPERADMIN,
		userHasPermission: (state, getters) => permission => {
			return getters.userLoaded && (getters.userIsSuperadmin || (getters.contextLoaded
				&& typeof state.user.can?.[ getters.context ]?.[ getters.contextId ]?.[ permission ] != 'undefined'
				&& state.user.can?.[ getters.context ]?.[ getters.contextId ]?.[ permission ] == true))
		},
	},
	mutations: {
		[ USER_LOADED ]: (state, user) => {
			state.user.name 	= user.name;
			state.user.email 	= user.email;
			state.user.id 		= user.id;
			state.user.can 		= user.can;
			state.status 		= USER_STATUS_LOADED
		},
		[ USER_LOGOUT ]: (state) => {
			state.user = {};
			state.status = USER_STATUS_UNDEFINED
		},
		[USER_REQUEST]: (state) => {
			state.status = USER_STATUS_LOADING
		},
		[USER_UPDATE]: (state, user) => {
			state.user = {
				...state.user,
				name: user.name,
				email: user.email,
			}
		},
		[USER_RESET]: (state) => {
			state.user = userDefault
			state.status = USER_STATUS_UNDEFINED
		}
	},
	actions: {
		[ USER_REQUEST ]: ({getters, commit, dispatch}) => {
			console.log("USER_REQUEST")
			return new Promise((resolve, reject) => {
				api.get('user').then((res) => {
					if(res.data.dealers) {
						commit(DEALERS_LOADED, res.data.dealers)
						delete res.data.dealers;
					}
					if(res.data.webshops){
						commit(WEBSHOPS_LOADED, res.data.webshops)
						delete res.data.webshops
					}

					commit(USER_LOADED, res.data);

					resolve();
				}).catch(err => reject(err));
			})
		},
		[USER_UPDATE]: ({getters,commit}, data) => {
			return new Promise((resolve, reject) => {
				api.patch(`user/${getters.user.id}`, data).then(res => {
					commit(USER_UPDATE, data)
					resolve(res.data)
				}).catch(err => reject(err))
			})
		}
	}

}