<template>
    <div>
        <default-header></default-header>
        <vee-form ref="form" @submit="submit" :validation-schema="schema" v-slot="{handleSubmit}">
            <div class="space-y-12">
                <account-profile v-model="user"/>

                <div class="md:grid md:grid-cols-8 md:gap-6">
                    <div class="col-span-4 text-right">
                        <button-save :loading="submitting" type="submit"></button-save>
                    </div>
                </div>
            </div>
        </vee-form>
    </div>
</template>

<script>
import * as yup from "yup";
import {USER_UPDATE} from "@/store/user";
import {success} from "@/js/helper/modals";
import {mapGetters} from "vuex";
import ButtonSave from "@/components/buttons/ButtonSave";
import AccountProfile from "@/components/account/AccountProfile";
import DefaultHeader from "@/components/layout/DefaultHeader";

export default {
    name: "Index",
    components: {ButtonSave, AccountProfile, DefaultHeader},
    data: () => ({
        user: {},
        submitting: false
    }),
    setup() {
        const schema = yup.object({
            user: yup.object({
                name: yup.string().required().label('Name'),
                email: yup.string().required().email().label('E-Mail'),
                password1: yup.string().test('password1', 'Das Passwort muss mindestens 8 Zeichen lang sein', function (value) {
                    if(!!value) {
                        return yup.string().min(8).isValidSync(value)
                    }

                    return true
                }),
                password2: yup.string().test('user.password2', 'Die Passwörter müssen übereinstimmen', function (value) {
                    return value === this.parent.password1
                }),
            }),
        })

        return {
            schema
        }
    },
    created() {
        this.user = {...this.currentUser, password1: '', password2: ''}
    },
    computed: {
        ...mapGetters({
            currentUser: 'user',
            userHasPermission: 'userHasPermission',
        }),
    },
    methods: {
        submit(data){
            this.submitting = true
            if(!data.user.password1.length){
                delete data.user.password1
                delete data.user.password2
            }

            this.$store.dispatch(USER_UPDATE, data.user).then(res => {
                success(null, 'Der Account wurde erfolgreich aktualisiert')
                this.$refs.form.resetForm({
                    values: {
                        user: this.user
                    }
                })
            }).finally(() => {
                this.submitting = false
            })
        }
    }
}
</script>

<style scoped>

</style>