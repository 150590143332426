import {su} from "@/js/helper/api";

export const ACCOUNTS_REQUEST='ACCOUNTS_REQUEST'
export const ACCOUNTS_EDIT='ACCOUNTS_EDIT'
export const ACCOUNTS_DELETE='ACCOUNTS_DELETE'

export default {
	state: {
		accounts: [],
	},
	getters: {
		accounts: state => state.accounts
	},
	mutations: {
		[ACCOUNTS_EDIT]: (state, account) => {
			state.accounts = state.accounts.map(function(oldAccount){
				if(oldAccount.id == account.id){
					oldAccount = {...oldAccount, ...account}
				}
				return oldAccount
			})
		},
		[ACCOUNTS_REQUEST]: (state, accounts) => {
			state.accounts = accounts
		},
		[ACCOUNTS_DELETE]: (state, account) => {
			state.accounts = state.accounts.filter(acc => {
				return acc.id != account.id
			})
		}
	},
	actions: {
		[ACCOUNTS_REQUEST]: ({commit}) => {
			return new Promise((resolve, reject) => {
				su.get('account').then(res => {
					commit(ACCOUNTS_REQUEST, res.data)

					resolve(res)
				}).catch(err => reject(err))
			})
		},
		[ACCOUNTS_DELETE]: ({commit}, account) => {
			return new Promise((resolve, reject) => {
				su.delete(`account/${account.id}`).then(res => {
					commit(ACCOUNTS_DELETE, account)

					resolve()
				}).catch(err => reject(err))
			})

		}
	}
}