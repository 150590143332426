import {createRouter, createWebHistory} from 'vue-router'
import CustomersIndex from '../views/dealer/customers/Index'
import TeamIndex from '../views/dealer/team/Index'
import BranchesIndex from '../views/dealer/branches/Index'
import ProductsIndex from '../views/dealer/products/Index'
import FaqIndex from '../views/dealer/faq/Index'
import Container from "../components/layout/Container";
import AuthLogin from '../views/auth/Login'
import IntegrationIndex from '../views/ecommerce/integration/Index'
import ProvisionIndex from '../views/ecommerce/provision/Index'

//	Superuser
import DashboardIndex from '../views/su/dashboard/Index'
import SUAccountsIndex from '../views/su/accounts/Index'
import SUAccountIndex from '../views/su/account/Index'

// Landingpages
import OnlineIndex from '../views/landingpages/online/Index'
import LocalIndex from '../views/landingpages/local/Index'


import {
	authenticated,
	isContextDealer,
	isContextSU,
	isContextWebshop,
	unauthenticated
} from './middleware/auth'
import {initialFetch} from "./middleware/user";
import multiguard from 'vue-router-multiguard';
import store, {LOADING_FINISHED, LOADING_START} from '../store/index'
import NotFound from "../views/error/NotFound";
import AccountIndex from '../views/account/Index'
import {startLoading, stopLoading} from "../js/helper/modals";
import LandingpageContainer from "@/views/landingpages/LandingpageContainer";

const routes = [
	{
		path: '/',
		component: LandingpageContainer,
		beforeEnter: unauthenticated,
		redirect: {name: 'Landingpage-Local', replace: true},
		children: [
			{
				path: 'local',
				name: 'Landingpage-Local',
				component: LocalIndex
			}, {
				path: 'online',
				name: 'Landingpage-Online',
				component: OnlineIndex
			}, {
				path: 'login',
				name: 'Login',
				component: AuthLogin,
			},
		]
	}, {
		path: '/',
		name: 'Home',
		component: Container,
		beforeEnter: multiguard([authenticated, initialFetch, isContextDealer]),
		redirect: {name: 'Kunden', replace: true},
		children: [
			{
				path: 'customers',
				name: 'Kunden',
				component: CustomersIndex,
				meta: {
					title: 'Kunden',
					cancelLoadingState: false
				}
			}, {
				path: 'products',
				name: 'Produkte',
				component: ProductsIndex,
				meta: {
					title: "Produkte",
					cancelLoadingState: false
				}
			}, {
				path: 'branches',
				name: 'Filialen',
				component: BranchesIndex,
				meta: {
					title: 'Meine Filialen'
				}
			}, {
				path: 'team',
				name: 'Team',
				component: TeamIndex,
				meta: {
					title: 'Mein Team',
					cancelLoadingState: false
				}
			}, {
				path: 'faq',
				name: 'Hilfe',
				component: FaqIndex,
				meta: {
					title: 'Hilfe'
				}
			}, {
				path: 'account',
				name: 'account-dealer',
				component: AccountIndex,
				meta: {
					title: 'Accountverwaltung'
				}
			}
		]
	}, {
		path: '/ecommerce',
		name: 'E-Commerce',
		component: Container,
		beforeEnter: multiguard([authenticated, initialFetch, isContextWebshop]),
		redirect: {name: 'Provision', replace: true},
		children: [
			{
				path: 'provision',
				name: 'Provision',
				component: ProvisionIndex,
				meta: {
					title: 'Provision',
					cancelLoadingState: false
				}
			}, {
				path: 'integration',
				name: 'Integration',
				component: IntegrationIndex,
				meta: {
					title: 'Integration'
				}
			}, {
				path: 'account',
				name: 'account-webshop',
				component: AccountIndex,
				meta: {
					title: 'Accountverwaltung'
				}
			}

		]
	}, {
		path: '/su',
		name: 'Superuser',
		component: Container,
		beforeEnter: multiguard([authenticated, initialFetch, isContextSU]),
		redirect: {name: 'Dashboard', replace: true},
		children: [
			{
				path: 'dashboard',
				name: 'Dashboard',
				component: DashboardIndex,
				meta: {
					title: 'Dashboard',
					cancelLoadingState: false
				}
			}, {
				path: 'accounts',
				name: 'Accounts',
				component: SUAccountsIndex,
				meta: {
					title: 'Accounts',
					cancelLoadingState: false
				}
			}, {
				path: 'account',
				name: 'account-su',
				component: SUAccountIndex,
				meta: {
					title: 'Accountverwaltung'
				}
			}
		]
	}, {
		path: '/:pathMatch(.*)*',
		name: 'not-found',
		component: NotFound,
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to, from, next) => {
	startLoading()

	next()
})


router.afterEach((to) => {
	if(typeof to.meta.cancelLoadingState == 'undefined' || to.meta.cancelLoadingState == true){
		stopLoading()
	}
})


router.afterEach((to) => {
	let index = document.title.indexOf('-')
	let title = document.title.substr(index >= 0 ? index + 1 : 0)

	let prefix = to.meta?.title ? `${to.meta?.title} - ` : ''
	document.title = `${prefix}${title}`
})


export default router
