<template>
	<div>
        <vee-form ref="form" v-slot="{handleSubmit, errors}" @submit="save" :validation-schema="schema" :initial-values="modelValue" >
            <div class="bg-white">
                <fieldset class="mb-6">
                    <legend class="block text-bold font-medium text-gray-700">Adresse</legend>
                    <div class="mt-1 bg-white -space-y-px">
                        <div>
                            <label for="street_address" class="sr-only">Musterstraße</label>
                            <vee-spaced-input :errors="errors.street_address" type="text" name="street_address" id="street_address" class="rounded-t-lg" placeholder="Straße + Nr.*" />
                        </div>
                        <div class="flex -space-x-px">
                            <div class="w-1/4">
                                <label for="zip" class="sr-only">12345</label>
                                <vee-spaced-input :errors="errors.zip" type="text" name="zip" id="zip" class="rounded-bl-lg" placeholder="PLZ*" />
                            </div>
                            <div class="w-3/4">
                                <label for="city" class="sr-only">Musterort</label>
                                <vee-spaced-input :errors="errors.city" type="text" name="city" id="city" class="rounded-br-lg" placeholder="Ort*" />
                            </div>
                        </div>
<!--                        <div>-->
<!--                            <label for="country" class="sr-only">Musterland</label>-->
<!--                            <vee-spaced-input :errors="errors.country" type="text" name="country" id="country" class="rounded-b-lg" placeholder="Land*" />-->
<!--                        </div>-->
                    </div>
                </fieldset>


                <fieldset class="mb-6">
                    <legend class="block text-bold font-medium text-gray-700">Kontakt</legend>
                    <div class="mt-1 bg-white -space-y-px">
                        <div>
                            <label for="phone" class="sr-only">Muster Tel. Nummer</label>
                            <vee-spaced-input :errors="errors.phone" type="text" name="phone" id="phone" class="rounded-t-md" placeholder="Telefonnummer" />
                        </div>
                        <div>
                            <label for="mail" class="sr-only">Muster Email</label>
                            <vee-spaced-input :errors="errors.mail" type="text" name="mail" id="mail" placeholder="Email Adresse" />
                        </div>
                        <div>
                            <label for="website" class="sr-only">Webseite</label>
                            <vee-spaced-input :errors="errors.website" type="text" name="website" id="website" class="rounded-b-md" placeholder="Homepage" />
                        </div>
                    </div>
                </fieldset>


                <fieldset class="mb-6">
                    <legend class="block text-bold font-medium text-gray-700">Öffnungszeiten</legend>
                    <p class="mt-2 text-sm text-gray-500" id="email-description">Die 3. + 4. Spalte können benutzt werden, falls die Öffnungszeit durch eine Mittagspause getrennt wird.</p>
                    <div class="mt-1 bg-white -space-y-px">
                        <!--Montag-->
                        <div class="flex -space-x-px">
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[1][0]']" type="text" name="opening_hours[1][0]" class="rounded-tl-lg" placeholder="Mo. von" />
                            </div>
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[1][1]']" type="text" name="opening_hours[1][1]" placeholder="Mo. bis" />
                            </div>
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[1][2]']" type="text" name="opening_hours[1][2]" placeholder="Mo. von" />
                            </div>
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[1][3]']" type="text" name="opening_hours[1][3]" class="rounded-tr-lg" placeholder="Mo. bis" />
                            </div>
                        </div>

                        <!--Dienstag-->
                        <div class="flex -space-x-px">
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[2][0]']" type="text" name="opening_hours[2][0]" placeholder="Di. von" />
                            </div>
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[2][1]']" type="text" name="opening_hours[2][1]" placeholder="Di. bis" />
                            </div>
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[2][2]']" type="text" name="opening_hours[2][2]" placeholder="Di. von" />
                            </div>
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[2][3]']" type="text" name="opening_hours[2][3]" placeholder="Di. bis" />
                            </div>
                        </div>

                        <!--Mittwoch-->
                        <div class="flex -space-x-px">
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[3][0]']" type="text" name="opening_hours[3][0]" placeholder="Mi. von" />
                            </div>
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[3][1]']" type="text" name="opening_hours[3][1]" placeholder="Mi. bis" />
                            </div>
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[3][2]']" type="text" name="opening_hours[3][2]" placeholder="Mi. von" />
                            </div>
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[3][3]']" type="text" name="opening_hours[3][3]" placeholder="Mi. bis" />
                            </div>
                        </div>

                        <!--Donnerstag-->
                        <div class="flex -space-x-px">
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[4][0]']" type="text" name="opening_hours[4][0]" placeholder="Do. von" />
                            </div>
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[4][1]']" type="text" name="opening_hours[4][1]" placeholder="Do. bis" />
                            </div>
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[4][2]']" type="text" name="opening_hours[4][2]" placeholder="Do. von" />
                            </div>
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[4][3]']" type="text" name="opening_hours[4][3]" placeholder="Do. bis" />
                            </div>
                        </div>
                        
                        <!--Freitag-->
                        <div class="flex -space-x-px">
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[5][0]']" type="text" name="opening_hours[5][0]" placeholder="Fr. von" />
                            </div>
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[5][1]']" type="text" name="opening_hours[5][1]" placeholder="Fr. bis" />
                            </div>
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[5][2]']" type="text" name="opening_hours[5][2]" placeholder="Fr. von" />
                            </div>
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[5][3]']" type="text" name="opening_hours[5][3]" placeholder="Fr. bis" />
                            </div>
                        </div>
                        
                        <!--Samstag-->
                        <div class="flex -space-x-px">
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[6][0]']" type="text" name="opening_hours[6][0]" placeholder="Sa. von" />
                            </div>
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[6][1]']" type="text" name="opening_hours[6][1]" placeholder="Sa. bis" />
                            </div>
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[6][2]']" type="text" name="opening_hours[6][2]" placeholder="Sa. von" />
                            </div>
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[6][3]']" type="text" name="opening_hours[6][3]" placeholder="Sa. bis" />
                            </div>
                        </div>
                        
                        <!--Sonntag-->
                        <div class="flex -space-x-px">
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[7][0]']" type="text" name="opening_hours[7][0]" class="rounded-bl-lg" placeholder="So. von" />
                            </div>
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[7][1]']" type="text" name="opening_hours[7][1]" placeholder="So. bis" />
                            </div>
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[7][2]']" type="text" name="opening_hours[7][2]" placeholder="So. von" />
                            </div>
                            <div class="w-1/4">
                                <label  class="sr-only"></label>
                                <vee-spaced-input :errors="errors['opening_hours[7][3]']" type="text" name="opening_hours[7][3]" class="rounded-br-lg" placeholder="So. bis" />
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>

            <label class="block text-bold font-medium text-gray-700">
                Filialbild <span v-if="preview" class="text-gray-600 underline text-sm cursor-pointer" @click="removeImage">entfernen</span>
            </label>

            <file-dropper v-if="showDropper"
                          v-model:files="files"
                          @update:files="createImage"
                          name="image"
                          accept="image/*"
            />
            <div v-else >
                <img class="w-full h-75" :src="previewSrc">
            </div>


            <div class="my-4 flex items-center">
                <span v-if="modelValue?.id && userHasPermission('branches.delete')" @click="confirmDelete = true" class="text-gray-600 underline text-sm cursor-pointer" >Filiale Löschen</span>
                <button-save :loading="branchesLoading" class="ml-auto" type="submit" ></button-save>
            </div>
        </vee-form>

        <modal-confirm v-model="confirmDelete"
                       confirm-text="Löschen"
                       title="Filiale löschen?"
                       @confirm="onConfirmDelete">

            Möchten Sie die Filiale wirklich löschen?
        </modal-confirm>
    </div>
</template>

<script>
import ButtonPrimary from "@/components/buttons/ButtonPrimary";
import * as yup from 'yup';
import VeeInput from "@/components/forms/VeeInput";
import VeeSpacedInput from "@/components/forms/VeeSpacedInput";
import {BRANCHES_DELETE, BRANCHES_EDIT} from "@/store/branches";
import {error, success} from "@/js/helper/modals";
import ButtonSave from "@/components/buttons/ButtonSave";
import {mapGetters} from "vuex";
import FileDropper from "@/components/forms/FileDropper";
import {useForm} from "vee-validate";
import ModalConfirm from "@/components/modals/ModalConfirm";


export default {
    name: "Edit",
    components: {FileDropper, ButtonSave, VeeSpacedInput, VeeInput, ButtonPrimary, ModalConfirm},
    emits: ['save', 'delete', 'update:modelValue'],
    props: {
       modelValue: {default: {}}
    },
    setup() {
        const schema = yup.object().shape({
            street_address: yup.string().required().label('Straße'),
            zip: yup.string().required().min(4).max(5).label('PLZ'),
            city: yup.string().required().label('Ort'),
            // country: yup.string().required().label('Land'),

            phone: yup.string().label("Telefonnummer"),
            mail: yup.string().email().label("E-Mail"),
            website: yup.string().matches(/^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/).label("Homepage"),

            opening_hours: yup.array().of(
                yup.array().of(
                    yup.lazy(value => !value ? yup.mixed().nullable() : yup.string().matches(/^[0-9]{2}:[0-9]{2}$/, {
                        message: "Die Uhrzeit muss im Format hh:mm vorliegen",
                        excludeEmptyString: true
                    }))))
        })

        return {
            schema
        }
    },
    data: () => ({
        imageTouched: false,
        files: [],
        preview: null,
        confirmDelete: false
    }),
    created() {
        this.setPreviewImage(this.modelValue)
    },
    computed: {
        ...mapGetters([
            'branchesLoading',
            'userHasPermission'
        ]),
        showDropper(){
            // return !this.files.length && !this.modelValue?.image
            return !this.preview
        },
        previewSrc(){
            return this.preview?.src
        }
    },
    methods: {
        removeImage(){
            this.imageTouched = true
            this.files = []
            this.preview = null
        },
        save(data, actions) {
            var formData = new FormData()

            if(this.imageTouched){
                formData.append('image', this.files[0] || null)
            }

            if(this.modelValue?.id){
                formData.append('id', this.modelValue?.id)
            }

            formData.append('data', JSON.stringify(data))
            this.$store.dispatch(BRANCHES_EDIT, formData).then(res => {
                if(this.modelValue.id){
                    success(null, "Die Filiale wurde erfolgreich bearbeitet")
                } else {
                    success(null, "Die Filiale wurde erfolgreich hinzugefügt")
                }

                actions.resetForm()

                this.$emit('save')
            }).catch(err => {
                error(null, 'Beim Speichern der Filiale trat ein Fehler auf')
            })
        },
        createImage(files){
            this.imageTouched = true

            let file = files[0]
            this.preview = {name: file.name}
            let reader = new FileReader()

            reader.onload = (e) => {
                this.preview.src = e.target.result
            };

            reader.readAsDataURL(file);
        },
        setPreviewImage(branch){
            if(branch?.image_md){
                this.preview = {src: this.fileUri(branch.image_md)}
            } else {
                this.preview = null
            }
        },
        onConfirmDelete(){
            this.$store.dispatch(BRANCHES_DELETE, this.modelValue).then(() => {
                success(null, "Die Filiale wurde erfolgreich gelöscht!")
                this.$emit('delete')
            })
        }
    },
    watch: {
        modelValue(newVal, oldVal){
            this.$refs.form.resetForm({values: newVal})
            this.files = []
            this.setPreviewImage(newVal)
        }
    }
}
</script>

<style scoped>

</style>