import store, {INITIALIZED} from '../../store/index'
import user, {USER_REQUEST, USER_STATUS_LOADING} from "../../store/user";
import {ACTING_AS, CONTEXT_ACTING_AS, CONTEXT_REQUEST, CONTEXT_UPDATE} from "../../store/context";
import axios from "axios";

export const fetchUser = (to, from, next) => {
	if(!store.getters.userLoaded && !store.getters.userLoading) {
		store.dispatch(USER_REQUEST).then(() => {
			next();
		})
	} else {
		next();
	}
}



export const initialFetch = (to, from, next) => {
	if(!store.getters.userLoaded && !store.getters.userLoading && !store.getters.contextLoaded) {
		const actingAs = localStorage.getItem(ACTING_AS)
		if(actingAs){
			store.commit(CONTEXT_ACTING_AS, parseInt(actingAs))
		}

		Promise.all([
			store.dispatch(USER_REQUEST),
			store.dispatch(CONTEXT_REQUEST),
		]).then(() => {
			store.commit(INITIALIZED)
			// @ToDo: evtl. weiterleiten auf E-Commerce
			if(store.getters.isSU && to.path.indexOf('su') < 0) {
				next({name: 'Dashboard'})
			} else if(store.getters.isWebshop && to.path.indexOf('ecommerce') < 0){
				next({name: 'E-Commerce'})
			} else {
				next();
			}
		}).catch(err => {
			console.log(err)
		});
	} else {
		next()
	}
}