<template>
    <div>
        <div>
            <default-header>
                <product-header></product-header>
            </default-header>

            <template v-if="selectedBranchFeedMode">
                <image-landing-page v-if="!feed" :src="require('@/assets/images/lokale/produkte/keine_produkte.svg')" >
                    <template v-slot:title >Es wurde noch kein Feed angelegt.</template>
                    <template v-slot:sub-title v-if="isBranchSelected" >Klicke oben auf den Button, um einen neuen Feed anzulegen.</template>
                    <template v-slot:sub-title v-else >Lege zuerst eine Filiale an, um anschließend Produkte hinzufügen zu können.</template>
                </image-landing-page>

                <div v-else class="flex flex-col">
                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table class="min-w-full divide-y divide-gray-200">
                                    <thead class="bg-gray-50">
                                        <tr>
                                            <th scope="col" colspan="2" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Info
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="bg-white divide-y divide-gray-200">
                                        <tr v-for="row in feedTableData">
                                            <td class="px-6 py-4 w-1/3 whitespace-nowrap font-medium text-gray-900">
                                                {{ row.label }}
                                            </td>
                                            <td class="px-6 py-4 w-2/3 whitespace-nowrap text-gray-700">
                                                {{ row.value }}
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <span v-if="feed" @click="confirmDeleteFeed=true" class="underline float-right mt-2 font-normal text-sm text-gray-500 cursor-pointer ml-auto">Feed entfernen</span>

            </template>




            <template v-if="!selectedBranchFeedMode">
                <image-landing-page v-if="!products.length" :src="require('@/assets/images/lokale/produkte/keine_produkte.svg')" >
                    <template v-slot:title >Es wurden noch keine Produkte angelegt.</template>
                    <template v-slot:sub-title v-if="isBranchSelected " >Klicke oben auf den Button, um welche anzulegen.</template>
                    <template v-slot:sub-title v-else >Lege zuerst eine Filiale an, um anschließend Produkte hinzufügen zu können.</template>
                </image-landing-page>

                <div v-else class="flex flex-col">
                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <table-default
                            v-if="products"
                            :rows="products"
                            search-placeholder="EAN suchen"
                            filter-field="ean"
                            :columns="['ean', 'action']"
                        >
                            <template v-slot:action="{entity, index}">
                                <td class="px-6 py-4 whitespace-nowrap text-right text-base font-medium">
                                    <a v-if="canEdit" @click="deleteableProduct=entity; confirmDelete=true"
                                       class="text-gray-500 hover:text-orange-900 cursor-pointer">Löschen</a>
                                </td>
                            </template>
                        </table-default>
                    </div>

                    <div class="mt-8 flex-row flex items-center">
                        <button-secondary :loading="branchesLoading" title="Gesamte Liste" @click="downloadProductList">
                            <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                            </svg>
                        </button-secondary>
                        <span v-if="canEdit" @click="confirmDeleteAll=true" class="underline font-normal text-sm text-gray-500 cursor-pointer ml-auto">Gesamte Liste löschen</span>
                    </div>


                </div>
            </template>

        </div>

        <modal-confirm v-model="confirmDelete"
                       confirm-text="Löschen"
                       title="Produkt löschen?"
                       @confirm="onConfirmDelete">

            Möchten Sie das Produkt ({{deleteableProduct?.ean}}) wirklich löschen?
        </modal-confirm>

        <modal-confirm v-model="confirmDeleteAll"
                       confirm-text="Löschen"
                       title="Produkte löschen?"
                       @confirm="onConfirmDeleteAll">

            Möchtest du wirklich alle Produkte dieser Niederlassung löschen?
        </modal-confirm>

        <modal-confirm v-model="confirmDeleteFeed"
                       confirm-text="Löschen"
                       title="Feed löschen?"
                       @confirm="onConfirmDeleteFeed">

            Möchtest du diesen Feed wirklich löschen?
        </modal-confirm>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import {
        BRANCHES_FEED_DELETE,
        BRANCHES_PRODUCTS_DELETE,
        BRANCHES_PRODUCTS_DELETE_ALL,
        BRANCHES_PRODUCTS_DOWNLOAD,
        BRANCHES_REQUEST,
        BRANCHES_SELECT
    } from "../../../store/branches";
    import ModalConfirm from "../../../components/modals/ModalConfirm";
    import DefaultHeader from "../../../components/layout/DefaultHeader";
    import ProductHeader from "./Header";
    import {error, startLoading, stopLoading, success} from "../../../js/helper/modals";
    import TableDefault from "../../../components/tables/TableDefault";
    import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
    import ButtonSecondary from "../../../components/buttons/ButtonSecondary";
    import {dealer} from "../../../js/helper/api";
    import ImageLandingPage from "@/components/general/ImageLandingPage";
    import TableStatsExtended from "@/components/tables/TableStatsExtended";
    import moment from 'moment'


    export default {
		name: 'ProductsIndex',
        components: {
            TableStatsExtended,
            ButtonSecondary, ButtonPrimary, TableDefault, ProductHeader, DefaultHeader, ModalConfirm, ImageLandingPage},
        data: () => ({
            confirmDelete: false,
            confirmDeleteAll: false,
            confirmDeleteFeed: false,
            deleteableProduct: {},
		}),
		created() {
		    this.$store.dispatch(BRANCHES_REQUEST).then(async (branches) => {
		        if(branches.length) {
                    await this.$store.dispatch(BRANCHES_SELECT, branches[0])
                }

                stopLoading()
            })
		},
        computed: {
            ...mapGetters({
                products: 'selectedBranchProducts',
                userHasPermission: 'userHasPermission',
                branchesLoading: 'branchesLoading',
                isBranchSelected: 'isBranchSelected',
                selectedBranchFeedMode: 'selectedBranchFeedMode',
                selectedBranch: 'selectedBranch'
            }),
            canEdit(){
                return this.userHasPermission('products.edit')
            },
            feed(){
                return this.selectedBranch.feed ?? false
            },
            feedTableData(){
                if(this.feed) {
                    return [
                        {label: 'Feed-URL', value: this.feed.url},
                        {label: 'Importierte EANs', value: this.formatNumber(this.feed.imported_count) ?? '-'},
                        {label: 'Letzter Import', value: this.feed.imported_at ? moment(this.feed.imported_at, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss [Uhr], D. MMMM YYYY') : '-'},
                        {label: 'Automatischer Abruf', value: `Jeden Tag um ${this.feed.time} Uhr`},
                    ]
                }
            }
        },
		methods: {
            ...mapActions({
                deleteProduct: BRANCHES_PRODUCTS_DELETE,
                deleteProductAll: BRANCHES_PRODUCTS_DELETE_ALL,
            }),
            onConfirmDelete(){
                this.deleteProduct(this.deleteableProduct).then((res) => {
                    success("Löschen erfolgreich", "Du hast erfolgreich ein Produkt gelöscht.")
                }).catch(err => {
                    error('Fehler beim Löschen', "Beim Löschen des Produkts trat ein Fehler auf")
                })
            },
            onConfirmDeleteAll(){
                this.deleteProductAll().then((res) => {
                    success("Löschen erfolgreich", "Du hast erfolgreich alle Produkte dieser Filiale gelöscht.")
                }).catch(err => {
                    error('Fehler beim Löschen', "Beim Löschen der Produkte trat ein Fehler auf")
                })
            },
            onConfirmDeleteFeed(){
                startLoading()
                this.$store.dispatch(BRANCHES_FEED_DELETE, this.feed).then(res => {
                    stopLoading()
                    success("Löschen erfolgreich", "Der Feed wurde erfolgreich gelöscht")
                })
            },
            downloadProductList(){
                startLoading()
                this.$store.dispatch(BRANCHES_PRODUCTS_DOWNLOAD).then(() => {
                    stopLoading()
                })
            }
		},


	}
</script>