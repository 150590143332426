<template>
	<div class="z-20 fixed inset-0 test flex flex-col ml-auto  px-4 py-6 pointer-events-none sm:p-6 sm:items-end sm:justify-start">
		<notification-basic v-model="modalSuccess.show"
							:title="modalSuccess.title"
							:text="modalSuccess.text"
							:close-delay="5000"/>

		<notification-basic v-model="modalError.show"
							title-default="Error"
							text-default="Die Aktion konnte nicht ausgeführt werden. Es trat ein Fehler auf"
							:title="modalError.title"
							:text="modalError.text"
							:close-delay="5000" >
			<template v-slot:icon >
				<svg class="h-6 w-6 text-red-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
				</svg>
			</template>
		</notification-basic>
	</div>
</template>

<script>
	import NotificationBasic from "../overlays/NotificationBasic";
	import {mapGetters} from "vuex";
	export default {
		name: "Modals",
		components: {NotificationBasic},
		computed: {
			...mapGetters([
				'modalSuccess',
				'modalError'
			])
		}
	}
</script>

<style scoped>

</style>